"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      width: "100%",
      "background-color": "#FBFBFD"
    },
    attrs: {
      id: "app"
    }
  }, [_c(_vm.layout, {
    tag: "component"
  }, [_c("router-view")], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;