"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "bigScreen"
    }
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "header-by"
  }, [_vm._v("Design by HuaYe")]), _vm._v(" "), _c("div", {
    staticClass: "header-title"
  }, [_vm._v("TICA 空调发泡板绿色智能生产线")]), _vm._v(" "), _c("div", {
    staticClass: "header-time"
  }, [_vm._v(_vm._s(_vm.currentTime))])]), _vm._v(" "), _c("div", {
    staticClass: "main"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "left_zj"
  }, [_c("dv-border-box-10", [_c("div", {
    staticClass: "left_zj_text"
  }, [_c("h2", [_vm._v("主机信息")])]), _vm._v(" "), _c("div", {
    staticClass: "left_zj_info"
  }, [_c("dv-scroll-board", {
    attrs: {
      config: _vm.config
    }
  })], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "left_sf"
  }, [_c("dv-border-box-10", [_c("div", {
    staticClass: "left_sf_text"
  }, [_c("h2", [_vm._v("送风系统")])]), _vm._v(" "), _c("div", {
    staticClass: "left_sf_info"
  }, [_c("dv-capsule-chart", {
    staticStyle: {
      width: "90%",
      height: "100%"
    },
    attrs: {
      config: _vm.sfconfig
    }
  })], 1)])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "right_wd"
  }, [_c("dv-border-box-10", [_c("div", {
    staticClass: "right_wd_text"
  }, [_c("h2", [_vm._v("房间平均温度")])]), _vm._v(" "), _c("div", {
    staticClass: "right_wd_info"
  }, [_c("dv-water-level-pond", {
    staticStyle: {
      width: "200px",
      height: "200px"
    },
    attrs: {
      config: _vm.configWd
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "right_wd_info_box"
  }, [_c("div", {
    staticClass: "right_wd_info_but",
    on: {
      click: _vm.stop
    }
  }, [_vm._v("静态展示")]), _vm._v(" "), _c("div", {
    staticClass: "right_wd_info_but",
    on: {
      click: _vm.start
    }
  }, [_vm._v("动态展示")])])], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "right_rhs"
  }, [_c("dv-border-box-10", [_c("div", {
    staticClass: "right_rhs_text"
  }, [_c("h2", [_vm._v("热回收系统")])]), _vm._v(" "), _c("div", {
    staticClass: "right_rhs_info"
  }, [_c("div", {
    staticClass: "right_rhs_info_text"
  }, [_c("h2", [_vm._v("热水箱温度")])]), _vm._v(" "), _c("div", {
    staticClass: "right_rhs_info_img"
  }, [_c("dv-water-level-pond", {
    staticStyle: {
      width: "200px",
      height: "200px"
    },
    attrs: {
      config: _vm.configRsxWd
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "right_rhs_info_zt"
  }, [_c("span", [_vm._v("热水泵循环状态："), _c("span", {
    staticClass: "state"
  }, [_vm._v(_vm._s(this.rsbyxzt))])])])])])], 1)])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;