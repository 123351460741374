"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _projectSelect = _interopRequireDefault(require("@/components/projectSelect"));
var _home = require("@/api/home");
var _default2 = exports.default = {
  components: {
    Select: _projectSelect.default
  },
  props: {
    themeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    notification: {
      type: Object,
      default: function _default() {
        return {
          rows: [],
          total: ''
        };
      }
    }
  },
  data: function data() {
    return {
      visible: false,
      projectInfo: {},
      projectList: [],
      time: "时间读取中...",
      temperature: [],
      pageIndex: 1,
      domHeight: ''
    };
  },
  watch: {
    themeData: function themeData(val) {
      var _this = this;
      this.temperature = [];
      val.map(function (e, index) {
        for (var key in val[index]) {
          _this.temperature.push(val[index][key][0]);
        }
      });
    }
  },
  created: function created() {
    //console.log(this.notification.rows)
    if (JSON.parse(sessionStorage.getItem("projectInfo"))) {
      this.projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));
    }
    if (JSON.parse(sessionStorage.getItem("projectList"))) {
      this.projectList = JSON.parse(sessionStorage.getItem("projectList"));
    }
  },
  mounted: function mounted() {
    setInterval(this.getDate, 1000);
    this.domHeight = this.$refs.myintroduce.offsetHeight;
  },
  computed: {
    domH: function domH() {
      if (this.domHeight > 48) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 滑动出发
    handleScroll: function handleScroll(e) {
      var el = e.target;
      //判断是否到达div容器底部
      if (el.scrollTop + el.clientHeight >= el.scrollHeight - 10) {
        //控制页数
        this.pageIndex = this.pageIndex + 1;
        //调用后台接口
        if (this.notification.total > this.pageIndex * 10) {
          this.$parent.getNextDataList(this.pageIndex);
        }
      }
    },
    getDate: function getDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var One = date.getDay();
      var timeString = date.toLocaleTimeString("chinese", {
        hour12: false
      });
      var weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
      var week = weeks[One];
      this.time = year + "-" + month + "-" + day + "   " + week + "   " + timeString;
    },
    alarm: function alarm() {
      this.$router.push({
        path: "/report-download?activeName=six"
      });
      this.resetSetItem("watchStorage", 4);
    },
    // 批量处理消息
    batchUpdateProjectAlarmState: function batchUpdateProjectAlarmState(val) {
      var _this2 = this;
      var par = {
        ids: []
      };
      if (val) {
        par.ids.push(val);
      } else {
        this.notification.rows.forEach(function (item) {
          par.ids.push(item.id);
        });
      }
      (0, _home.batchUpdateProjectAlarmState)(par).then(function (res) {
        _this2.$parent.getNextDataList(1);
      });
    }
  }
};