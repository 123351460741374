"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultParams = defaultParams;
exports.delProjectPlan = delProjectPlan;
exports.energyDefaultParams = energyDefaultParams;
exports.getCoolGlfb = getCoolGlfb;
exports.getCoolNhfb = getCoolNhfb;
exports.getCoolNhgl = getCoolNhgl;
exports.getDataQuery = getDataQuery;
exports.getDataQueryExport = getDataQueryExport;
exports.getDataStatistics = getDataStatistics;
exports.getEnergyConsumptionQuery = getEnergyConsumptionQuery;
exports.getEnergyConsumptionQueryExport = getEnergyConsumptionQueryExport;
exports.getEnergyConsumptionSummary = getEnergyConsumptionSummary;
exports.getEnergyConsumptionSummaryExport = getEnergyConsumptionSummaryExport;
exports.getHistoricalDatExport = getHistoricalDatExport;
exports.getProjectConsumptionPercentage = getProjectConsumptionPercentage;
exports.getProjectEnergySystem = getProjectEnergySystem;
exports.getProjectEnergySystemDevice = getProjectEnergySystemDevice;
exports.getProjectFieldInfo = getProjectFieldInfo;
exports.getProjectFieldInfoEnergy = getProjectFieldInfoEnergy;
exports.getProjectPlan = getProjectPlan;
exports.getProjectSystem = getProjectSystem;
exports.getProjectSystemDevice = getProjectSystemDevice;
exports.insertOrUpdateProjectPlan = insertOrUpdateProjectPlan;
exports.projectAllFieldInfoByEnergySystem = projectAllFieldInfoByEnergySystem;
exports.projectSystemAllChildrenList = projectSystemAllChildrenList;
exports.projectSystemAllList = projectSystemAllList;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /*
 * @Author: your name
 * @Date: 2020-12-21 11:37:08
 * @LastEditTime: 2021-12-22 13:58:07
 * @LastEditors: zlt
 * @Description: In User Settings Edit
 * @FilePath: \yilong\src\api\dataQuery.js
 */
function getEnergyConsumptionSummary(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/consumptionSummary/energyConsumptionSummaryReport",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getEnergyConsumptionSummaryExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/consumptionSummary/energyConsumptionSummaryExport",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getProjectSystem(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectSystem/projectSystemList",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getProjectSystemDevice(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectSystemDevice/projectSystemDeviceListByArray",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getProjectFieldInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectFieldInfoBySystemDeviceIds",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getProjectFieldInfoEnergy(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectFieldInfoByEnergyAndSystemDeviceIds",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getDataStatistics(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHistoricalData/historicalDataList",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getHistoricalDatExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHistoricalData/historicalDatExport",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getEnergyConsumptionQuery(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/energyConsumptionQuery/energyConsumptionQueryList",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getEnergyConsumptionQueryExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/energyConsumptionQuery/energyConsumptionQueryExport",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getDataQuery(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/dataStatistics/dataStatisticsList",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getDataQueryExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/dataStatistics/dataStatisticsExport",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getProjectEnergySystem(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectFieldInfoByEnergySystem",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getProjectEnergySystemDevice(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectFieldInfoBySystemDevice",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 根据项目id，时间类型，时间查询耗能(projectId 项目id,timeType 1:月份，2:年,dayTime 月yyyy-MM 年yyyy）
function getProjectConsumptionPercentage(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectConsumptionPercentage/projectConsumptionPercentage",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
// 蓄冷月报表、年报表 获取能耗分布
function getCoolNhfb(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolNhfb",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
// 蓄冷月报表、年报表 获取供冷分布
function getCoolGlfb(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolGlfb",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getCoolNhgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolNhgl",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getProjectPlan(params) {
  return (0, _request.default)({
    url: "/projectPlan/getProjectPlan",
    method: "get",
    params: _objectSpread({}, params)
  });
}
function delProjectPlan(params) {
  return (0, _request.default)({
    url: "/projectPlan/delProjectPlan",
    method: "get",
    params: _objectSpread({}, params)
  });
}
function insertOrUpdateProjectPlan(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectPlan/insertOrUpdateProjectPlan",
    method: "POST",
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

//历史数据报表查询默认参数
function defaultParams(params) {
  return (0, _request.default)({
    url: "/projectHistoricalData/defaultParams",
    method: "get",
    params: _objectSpread({}, params)
  });
}

//能耗查询默认参数
function energyDefaultParams(params) {
  return (0, _request.default)({
    url: "/energyConsumptionQuery/defaultParams",
    method: "get",
    params: _objectSpread({}, params)
  });
}
//查询所有树状结构 历史数据
function projectSystemAllList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectSystem/projectSystemAllList",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
//查询所有树状结构  能耗查询
function projectAllFieldInfoByEnergySystem(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectAllFieldInfoByEnergySystem",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
//查询所有树状结构 所有子类数据
function projectSystemAllChildrenList(params) {
  return (0, _request.default)({
    url: "/projectSystem/projectSystemAllChildrenList",
    method: "get",
    params: _objectSpread({}, params)
  });
}