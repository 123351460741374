"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-right"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("headr")], 1), _vm._v(" "), _c("div", {
    staticClass: "tab-box"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.menusList, function (item) {
    return _c("el-tab-pane", {
      attrs: {
        label: item.name,
        name: "tag" + item.id
      }
    }, [_vm.activeName == "tag" + item.id && item.name == "日报表" ? _c("day") : _vm._e(), _vm._v(" "), _vm.activeName == "tag" + item.id && item.name == "月报表" ? _c("month") : _vm._e(), _vm._v(" "), _vm.activeName == "tag" + item.id && item.name == "年报表" ? _c("year") : _vm._e(), _vm._v(" "), _vm.activeName == "tag" + item.id && item.name == "设备运行日志" ? _c("runType") : _vm._e(), _vm._v(" "), _vm.activeName == "tag" + item.id && item.name == "运行诊断" ? [_vm.type == 1 ? _c("diagnosis", {
      on: {
        eidtType: _vm.eidtType
      }
    }) : _vm._e(), _vm._v(" "), _vm.type == 2 ? _c("diagnosisHistory", {
      on: {
        eidtType: _vm.eidtType
      }
    }) : _vm._e()] : _vm._e(), _vm._v(" "), _vm.activeName == "tag" + item.id && item.name == "报警" ? _c("alarm") : _vm._e(), _vm._v(" "), _vm.activeName == "tag" + item.id && item.name == "节能量" ? _c("energy") : _vm._e()], 2);
  }), 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;