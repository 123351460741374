"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "factory-container"
  }, [_c("Header"), _vm._v(" "), _c("router-view", {
    key: _vm.key
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;