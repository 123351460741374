"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _camera = _interopRequireDefault(require("./camera"));
var _renderer = _interopRequireDefault(require("./renderer"));
// 更新摄像头
_camera.default.activeCamera.aspect = window.innerWidth / window.innerHeight;
//   更新摄像机的投影矩阵
_camera.default.activeCamera.updateProjectionMatrix();

// 监听屏幕大小改变的变化，设置渲染的尺寸
window.addEventListener("resize", function () {
  //   console.log("resize");
  // 更新摄像头
  _camera.default.activeCamera.aspect = window.innerWidth / window.innerHeight;
  //   更新摄像机的投影矩阵
  _camera.default.activeCamera.updateProjectionMatrix();

  //   更新渲染器
  _renderer.default.renderer.setSize(window.innerWidth, window.innerHeight);
  //   设置渲染器的像素比例
  _renderer.default.renderer.setPixelRatio(window.devicePixelRatio);

  //   更新css3d渲染器
  _renderer.default.css3drender.setSize(window.innerWidth, window.innerHeight);
});