"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _projectInfo = require("@/api/projectInfo");
var _home = require("@/api/home");
var _default = exports.default = {
  data: function data() {
    var _this = this;
    return {
      params: {
        rows: 10,
        page: 1,
        //		页码	query	true	integer(int32)	
        alarmType: '',
        //		报警类型（报警等级）：1提醒2预警3报警	query	false	integer(int32)	
        endDate: '',
        //		结束始时间	query	false	string(date-time)	
        everconfirmed: '',
        //		是否确认 0.未确认 1.已确认 默认0	query	false	integer(int32)	
        projectId: this.$store.state.project.projectInfo.id,
        //		项目id	query	false	integer(int64)	
        id: this.$store.state.project.projectInfo.id,
        startDate: '' //		开始时间	query	false	string(date-time)
      },
      dataTimes: '',
      startDate: '',
      endDate: '',
      startDatePicker: {
        //禁用的日期
        disabledDate: function disabledDate(time) {
          //如果已经已知结束日期，就把结束日期之后的日期禁用
          if (_this.endDate) {
            return time.getTime() > new Date(_this.endDate).getTime();
          } else {
            //否则 就把当前日期之后的日期禁用掉
            return time.getTime() > Date.now();
          }
        }
      },
      endDatePicker: {
        disabledDate: function disabledDate(time) {
          //如果已知开始日期，就把开始日期之前的日期禁用掉
          if (_this.startDate) {
            return time.getTime() < new Date(_this.startDate).getTime();
          }
        }
      },
      chartCount: {
        dyaCount: 0,
        monthCount: 0,
        yearCount: 0
      },
      projectAlarmCount: {
        dayData: [],
        monthData: [],
        yearData: []
      },
      projectAlarmLevel: {
        dayData: [],
        monthData: [],
        yearData: []
      },
      projectEliminate: {
        dayData: [],
        monthData: [],
        yearData: []
      },
      list: [],
      count: [],
      value: '',
      value2: '',
      value3: '',
      value4: '',
      alarmTypes: [{
        value: '1',
        label: '提醒'
      }, {
        value: '2',
        label: '预警'
      }, {
        value: '3',
        label: '告警'
      }],
      everconfirmeds: [{
        value: '0',
        label: '未确认'
      }, {
        value: '1',
        label: '已确认'
      }],
      tableData: [],
      total: 0,
      type: 1,
      type1: 1,
      type2: 1,
      eliminateList: [],
      gjChartCount: {},
      expireTimeOPtion: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的 
        }
      },
      colorType: '',
      echartfc: '',
      // 图表颜色
      echartYLine: "",
      variableHeight: null,
      zoom: '',
      zoomrAtio: ''
    };
  },
  watch: {
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;
      if (this.colorType == 'theme_jyh') {
        this.echartfc = '#fff';
      } else if (this.colorType == 'theme_ytl') {
        this.echartfc = '#333';
      } else {
        this.echartfc = '#333';
      }
      this.areaEcharts();
      this.typeEcharts();
      this.eliminateEcharts();
    }
  },
  mounted: function mounted() {
    this.areaEcharts();
    this.typeEcharts();
    this.eliminateEcharts();
    this.getHeight();
  },
  created: function created() {
    this.colorType = this.$store.state.project.colorType;
    if (this.colorType == 'theme_jyh') {
      this.echartfc = '#fff';
    } else if (this.colorType == 'theme_ytl') {
      this.echartfc = '#333';
    } else {
      this.echartfc = '#333';
    }
    this.getProjectAlarmCount();
    this.getProjectAlarmStatistics();
    this.getProjectAlarmLevel();
    this.getProjectEliminate();
    this.getProjectAlarm();
  },
  methods: {
    getHeight: function getHeight() {
      var height = window.screen.height * window.devicePixelRatio;
      if (height >= 2160) {
        this.variableHeight = 415;
      } else if (height >= 1800) {
        this.variableHeight = 425;
      } else if (height >= 1600) {
        this.variableHeight = 515;
      } else if (height >= 1440) {
        this.variableHeight = 395;
      } else if (height >= 1000) {
        this.variableHeight = 298;
      } else if (height >= 800) {
        this.variableHeight = 560;
      } else if (height >= 700) {
        this.variableHeight = 255;
      } else {
        this.variableHeight = 200;
      }
    },
    //重置
    reset: function reset() {
      var ts = this;
      ts.startDate = '';
      ts.endDate = '';
      ts.params.rows = 5;
      ts.params.page = 1;
      ts.params.alarmType = '';
      ts.params.everconfirmed = '';
      ts.tableData = [];
      ts.total = 0;
      ts.seach();
    },
    // 报警数量年月日选择
    checkDate: function checkDate(type) {
      this.type = type;
      var list = [];
      this.count = [];
      this.list = [];
      if (type == 1) {
        //天
        list = this.projectAlarmCount.dayData;
      }
      if (type == 2) {
        //月
        list = this.projectAlarmCount.monthData;
      }
      if (type == 3) {
        //年
        list = this.projectAlarmCount.yearData;
      }
      //数据处理，echart需要格式
      for (var i = 0; i < list.length; i++) {
        this.list.push(list[i].time);
        this.count.push(list[i].count);
      }
      //重新生产统计图
      this.areaEcharts();
    },
    //报警等级
    getProjectAlarmLevel: function getProjectAlarmLevel() {
      var ts = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _projectInfo.getProjectAlarmLevel)(par).then(function (res) {
        // 
        if (res.code == 200) {
          ts.projectAlarmLevel = res.data;
          ts.type1 = 1; // 设置默认获取日的统计数据
          ts.checkDate1(1); // 调用类型选择，进行echart统计
        } else {
          ts.$message.error(res.msg);
        }
      });
    },
    //报警消除
    getProjectEliminate: function getProjectEliminate() {
      var ts = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _projectInfo.getProjectEliminate)(par).then(function (res) {
        if (res.code == 200) {
          ts.projectEliminate = res.data;
          ts.type2 = 1; // 设置默认获取日的统计数据
          ts.checkDate2(1); // 调用类型选择，进行echart统计
        } else {
          ts.$message.error(res.msg);
        }
      });
    },
    //报警等级年月日选择
    checkDate1: function checkDate1(type1) {
      this.type1 = type1;
      if (type1 == 1) {
        //天
        this.gjChartCount = this.projectAlarmLevel.dayData;
      }
      if (type1 == 2) {
        //月
        this.gjChartCount = this.projectAlarmLevel.monthData;
      }
      if (type1 == 3) {
        //年
        this.gjChartCount = this.projectAlarmLevel.yearData;
      }
      //重新生产统计图
      this.typeEcharts();
    },
    //报警消除年月日选择
    checkDate2: function checkDate2(type2) {
      this.type2 = type2;
      if (type2 == 1) {
        //天
        this.eliminateList = this.projectEliminate.dayData;
      }
      if (type2 == 2) {
        //月
        this.eliminateList = this.projectEliminate.monthData;
      }
      if (type2 == 3) {
        //年
        this.eliminateList = this.projectEliminate.yearData;
      }
      //重新生产统计图
      this.eliminateEcharts();
    },
    //报警统计
    getProjectAlarmStatistics: function getProjectAlarmStatistics() {
      var ts = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _projectInfo.getProjectAlarmStatistics)(par).then(function (res) {
        if (res.code == 200) {
          ts.chartCount = res.data;
        }
      });
    },
    //报警表格
    getProjectAlarm: function getProjectAlarm() {
      var ts = this;
      ts.params.startDate = ts.startDate ? ts.startDate + ' 00:00:00' : '';
      ts.params.endDate = ts.endDate ? ts.endDate + ' 23:59:59' : '';
      (0, _projectInfo.getProjectAlarm)(ts.params).then(function (res) {
        ts.tableData = res.rows;
        ts.total = res.total;
      });
    },
    //报警数量
    getProjectAlarmCount: function getProjectAlarmCount() {
      var ts = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _projectInfo.getProjectAlarmCount)(par).then(function (res) {
        // 
        if (res.code == 200) {
          ts.projectAlarmCount = res.data;
          ts.type = 1; // 设置默认获取日的统计数据
          ts.checkDate(1); // 调用类型选择，进行echart统计
        } else {
          ts.$message.error(res.msg);
        }
      });
    },
    //报警统计图表
    doughnutEcharts: function doughnutEcharts() {
      var doughnutEcharts = this.$echarts.init(document.getElementById("doughnutEcharts"));
      var option = {
        tooltip: {
          trigger: 'item',
          borderWidth: 0
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        color: ["#3B7FE2", "#91cc76", "#fac858", "#ed6666", "#73c0de", "#3ba272", "#fc8352", "#9a61b4", "#ecc49f"],
        series: [{
          // name: 'Access From',
          type: 'pie',
          radius: ['46%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [{
            value: 1048,
            name: '当月'
          }
          // { value: 735, name: 'Direct' },
          // { value: 580, name: 'Email' },
          ]
        }]
      };
      doughnutEcharts.setOption(option);
    },
    //报警数量图表
    // areaEcharts() {
    //     let ts = this;
    //     const areaEcharts = this.$echarts.init(
    //         document.getElementById("areaEcharts")
    //     );
    //     const option = {
    //         grid: {
    //             left: "5%",
    //             right: "5%",
    //             top: "13%",
    //             bottom: "5%",
    //             containLabel: true,
    //         },
    //         tooltip: {
    //             trigger: 'axis',
    //             borderWidth: 0,
    //             axisPointer: {
    //                 type: 'cross',
    //                 label: {
    //                     backgroundColor: '#6a7985'
    //                 }
    //             }
    //         },
    //         xAxis: {
    //             type: 'category',
    //             boundaryGap: false,
    //             show: false,
    //             data: ts.list.length > 0 ? ts.list : ['', '', '', '', '', '', ''],//['2022-04', '2022-05', '2022-06', '2022-07', '2022-08', '2022-09', '2022-10']
    //         },
    //         yAxis: {
    //             type: 'value'
    //         },
    //         radar: [
    //             {
    //                 // center: ['50%', '50%'],
    //                 radius: '80%',
    //             }
    //         ],
    //         color: [
    //             "#34AA44",
    //         ],
    //         series: [
    //             {
    //                 name: '报警数量',
    //                 data: ts.count.length > 0 ? ts.count : [0, 0, 0, 0, 0, 0, 0],// [820, 932, 901, 934, 1290, 1330, 1320],
    //                 type: 'line',
    //                 areaStyle: {}
    //             }
    //         ]
    //     };
    //     areaEcharts.setOption(option);
    // },
    //报警数量图表
    areaEcharts: function areaEcharts() {
      var ts = this;
      var areaEcharts = this.$echarts.init(document.getElementById("areaEcharts"));
      var option = {
        grid: {
          left: "5%",
          right: "5%",
          top: "13%",
          bottom: "3%",
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          borderWidth: 0,
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          },
          valueFormatter: function valueFormatter(value) {
            return value;
          }
        },
        legend: {
          data: ['数据']
        },
        xAxis: [{
          type: 'category',
          data: ts.list.length > 0 ? ts.list : ['', '', '', '', '', '', ''],
          axisPointer: {
            type: 'shadow'
          }
        }],
        yAxis: [{
          type: 'value',
          name: ' ',
          axisLabel: {
            formatter: '{value}'
          },
          splitLine: {
            lineStyle: {
              color: this.echartYLine
              //type:'dashed'虚线
            }
          }
        }, {
          type: 'value',
          name: ' ',
          axisLabel: {
            formatter: '{value}'
          },
          splitLine: {
            lineStyle: {
              color: this.echartYLine
              //type:'dashed'虚线
            }
          }
        }],
        series: [{
          name: ' ',
          type: 'bar',
          barWidth: '14%',
          tooltip: {
            show: false
          },
          data: ts.count.length > 0 ? ts.count : [0, 0, 0, 0, 0, 0, 0]
        }, {
          name: '',
          type: 'line',
          yAxisIndex: 1,
          data: ts.count.length > 0 ? ts.count : [0, 0, 0, 0, 0, 0, 0]
        }]
      };
      areaEcharts.setOption(option);
    },
    //报警等级图表
    typeEcharts: function typeEcharts() {
      var ts = this;
      // 获取 DOM 元素
      var domElement = document.getElementById("typeEcharts");

      // 判断是否已有实例并销毁
      if (domElement._echartsInstance) {
        domElement._echartsInstance.dispose(); // 销毁已有实例
      }

      // 初始化新的 ECharts 实例
      var typeEcharts = ts.$echarts.init(domElement);

      // 清空之前的图表内容
      typeEcharts.clear();
      var option = {
        grid: {
          left: "5%",
          right: "5%",
          top: "13%",
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          borderWidth: 0
        },
        legend: {
          bottom: '5%',
          left: 'center',
          textStyle: {
            color: this.echartfc
          }
        },
        color: ["#EC6666", "#FACF55", "#34AA44"],
        // 轴线的样式
        axisLine: {
          lineStyle: {
            color: '#273169'
          }
        },
        series: [{
          // name: '类型',
          type: 'pie',
          radius: ['46%', '70%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '16'
              // fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [{
            value: ts.gjChartCount.alarmCount,
            name: '告警'
          }, {
            value: ts.gjChartCount.warnCount,
            name: '提醒'
          }, {
            value: ts.gjChartCount.earlyWarningCount,
            name: '预警'
          }]
        }]
      };
      typeEcharts.setOption(option);
    },
    //报警消除图表
    eliminateEcharts: function eliminateEcharts() {
      var ts = this;
      var eliminateEcharts = this.$echarts.init(document.getElementById("eliminateEcharts"));
      var num = 0;
      num = Math.floor((ts.eliminateList.alreadyEliminate + ts.eliminateList.notEliminate) * 100) / 100;
      var zb = ts.eliminateList.alreadyEliminate == '0.00' ? '0' : (ts.eliminateList.alreadyEliminate / num * 100).toFixed(2);
      var zb1 = ts.eliminateList.notEliminate == '0.00' ? '0' : (ts.eliminateList.notEliminate / num * 100).toFixed(2);
      var option = {
        tooltip: {
          trigger: 'item',
          borderWidth: 0,
          formatter: function formatter(params) {
            return "".concat(params.marker).concat(params.name, ", \u5360\u6BD4:").concat(params.data.zb, "%");
          }
        },
        legend: {
          bottom: '7%',
          left: 'center',
          textStyle: {
            color: this.echartfc
          }
        },
        color: ["#1665D8", "#EC6666"
        // "#34AA44",
        ],
        series: [{
          // name: 'Access From',
          type: 'pie',
          radius: ['46%', '70%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '16'
              // fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [{
            value: ts.eliminateList.alreadyEliminate ? ts.eliminateList.alreadyEliminate : 0,
            name: "\u5DF2\u6D88\u9664".concat(ts.eliminateList.alreadyEliminate ? ts.eliminateList.alreadyEliminate : 0),
            zb: zb
          }, {
            value: ts.eliminateList.notEliminate ? ts.eliminateList.notEliminate : 0,
            name: "\u672A\u6D88\u9664".concat(ts.eliminateList.notEliminate ? ts.eliminateList.notEliminate : 0),
            zb: zb1
          }]
        }]
      };
      eliminateEcharts.setOption(option);
    },
    // 搜索
    seach: function seach() {
      // 判断时间非空处理
      if (this.endDate && !this.startDate) {
        this.$message.warning("请选择开始日期");
      } else if (this.startDate && !this.endDate) {
        this.$message.warning("请选择结束日期");
      } else {
        this.params.page = 1;
        this.getProjectAlarm();
      }
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(val) {
      this.params.page = val;
      this.getProjectAlarm();
    },
    // 批量处理消息
    batchUpdateProjectAlarmState: function batchUpdateProjectAlarmState(val) {
      var ts = this;
      var par = {
        ids: []
      };
      if (val) {
        par.ids.push(val);
      }
      (0, _home.batchUpdateProjectAlarmState)(par).then(function (res) {
        // 
        ts.getProjectAlarm();
      });
    }
  }
};