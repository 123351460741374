"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var tokens = {
  admin: {
    token: 'admin-token'
  },
  editor: {
    token: 'editor-token'
  }
};
var users = {
  'admin-token': {
    roles: ['admin'],
    introduction: 'I am a super administrator',
    avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    name: 'Super Admin'
  },
  'editor-token': {
    roles: ['editor'],
    introduction: 'I am an editor',
    avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    name: 'Normal Editor'
  }
};
var _default = exports.default = [
// user login
{
  url: '/user/login',
  type: 'post',
  response: function response(config) {
    var username = config.body.username;
    var token = tokens[username];

    // mock error
    if (!token) {
      return {
        code: 60204,
        message: 'Account and password are incorrect.'
      };
    }
    return {
      code: 20000,
      data: token
    };
  }
},
// get user info
{
  url: '/user/info\.*',
  type: 'get',
  response: function response(config) {
    var token = config.query.token;
    var info = users[token];

    // mock error
    if (!info) {
      return {
        code: 50008,
        message: 'Login failed, unable to get user details.'
      };
    }
    return {
      code: 20000,
      data: info
    };
  }
},
// user logout
{
  url: '/user/logout',
  type: 'post',
  response: function response(_) {
    return {
      code: 20000,
      data: 'success'
    };
  }
}];