"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOrderHandleInfo = getOrderHandleInfo;
exports.getOrderInfo = getOrderInfo;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /*
 * @Author: dengriguang
 * @since: 2020-08-25 16:39:25
 * @lastTime: 2020-09-29 10:07:43
 * @LastAuthor: Do not edit
 * @FilePath: \toncentsoft-et\src\api\order.js
 * @Description: order Api
 */
/**
 * @description: 根据工单id查询工单信息
 * @param {type}
 * @return {type}
 */
function getOrderInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderByWorOrderId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

/**
 * @description: 根据工单id查询工单处理信息
 * @param {type}
 * @return {type}
 */
function getOrderHandleInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderHandlerByWorkOrderId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}