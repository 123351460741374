"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCurrMenus = getCurrMenus;
exports.getImg = getImg;
exports.getLogin = getLogin;
exports.getProjectQuestionCount = getProjectQuestionCount;
exports.getProjectQuestionInfo = getProjectQuestionInfo;
exports.getRefreshToken = getRefreshToken;
exports.postUploadFileByQuestionInfo = postUploadFileByQuestionInfo;
exports.relevanceProjectOrder = relevanceProjectOrder;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /*
 * @Author: your name
 * @Date: 2020-12-02 17:56:12
 * @LastEditTime: 2021-12-07 17:06:53
 * @LastEditors: zlt
 * @Description: In User Settings Edit
 * @FilePath: \yilong\src\api\user.js
 */
/**
 * @description: 登录
 * @param {type} encrypt_type 0 无加密 | 1 加密
 * @param {type} Object username 用户名
 * @param {type} Object password 密码
 * @return {type} Object token
 * @return {type} Object username 用户名
 * @return {type} Object telephone 手机号
 * @return {type} Object name 姓名
 * @return {type} Object needChangePaw 是否需要修改密码
 * @return {type} Object roleName 角色名称
 * @return {type} Object roleIdentity 角色标识
 */
function getLogin(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/login",
    method: "post",
    params: {
      encryptType: encryptType
    },
    data: params
  });
}

// 获取登录图片
function getImg() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/landingImage/energyLandingImageInfo",
    method: "get",
    params: {
      encryptType: encryptType
    }
  });
}
// 获取系统的菜单列表 projectType 0常规 1蓄冷
function getCurrMenus(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/users/getFrontMenu",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
// 刷新Token
function getRefreshToken(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/token/refresh",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 反馈
function getProjectQuestionInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectQuestionInfo/insertProjectQuestionInfo",
    method: "post",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 反馈上传图片
function postUploadFileByQuestionInfo(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectQuestionInfo/uploadFileByQuestionInfo",
    method: "post",
    params: {
      encryptType: encryptType
    },
    data: data
  });
}
function getProjectQuestionCount() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectQuestionInfo/questionCount",
    method: "get",
    params: {
      encryptType: encryptType
    }
  });
}

// 用户关联的项目信息,工单标识信息
function relevanceProjectOrder(userId) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectIdOrWorkOrderIdentListByUserId/".concat(userId),
    method: "get",
    params: {
      encryptType: encryptType
    }
  });
}