"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "head-box-cool"
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "headr"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-right": "80px"
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.projectName))]), _vm._v(" "), _vm.projectList !== undefined && _vm.projectList != null && _vm.projectList.length > 1 ? _c("Select", {
    staticStyle: {
      display: "inline"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("div", [_c("span", [_vm._v("\n          日供冷量:\n          "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.headerData.dayCool))]), _vm._v("\n          kWh\n        ")])]), _vm._v(" "), _c("div", [_c("span", [_vm._v("\n          日耗电量:\n          "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.headerData.dayElect))]), _vm._v("\n          kWh\n        ")])]), _vm._v(" "), _c("div", [_c("span", [_vm._v("\n          当日冷价:\n          "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.headerData.dayCop))]), _vm._v("\n          元/kWh\n        ")])]), _vm._v(" "), _c("div", [_c("span", [_vm._v("\n          冷冻水温度:\n          "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.headerData.temperatureDifference))]), _vm._v("\n          °C\n        ")])]), _vm._v(" "), _vm.imgUrl ? _c("div", {
    staticClass: "logo-box"
  }, [_c("img", {
    attrs: {
      src: _vm.imgUrl,
      alt: ""
    }
  })]) : _vm._e(), _vm._v(" "), _c("div")])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;