"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lineChart = _interopRequireDefault(require("./components/lineChart"));
var _calendar = _interopRequireDefault(require("./components/calendar"));
var _dataAnalysis = require("@/api/dataAnalysis");
var _dayjs = _interopRequireDefault(require("dayjs"));
// import headr from "@/components/head";
var _default = exports.default = {
  components: {
    // headr,
    lineChart: _lineChart.default,
    calendar: _calendar.default
  },
  data: function data() {
    return {
      activeName: 'first0',
      isShow: true,
      monthData: "",
      dayData: (0, _dayjs.default)().format("YYYY-MM-DD"),
      calendar: (0, _dayjs.default)().format("YYYY-MM"),
      k: 0,
      consumptionCalendarData: [],
      pathData: [],
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      chartData: [],
      backupData: [{
        name: "关键参数",
        chartData: []
      }, {
        name: "冷冻泵输送系数",
        chartData: []
      }, {
        name: "冷却泵输送系数",
        chartData: []
      }, {
        name: "冷却塔系统能效",
        chartData: []
      }],
      // 所有的个数
      nameTips: [{
        name: "关键参数",
        value: 1,
        showTip: true,
        tip1: "影响系统运行的主要关键参数",
        //瞬时的提示
        tip2: "影响系统运行的主要关键参数" //日数据的提示
      }, {
        name: "冷冻泵输送系数",
        value: 2,
        showTip: true,
        tip1: "机房总供冷负荷/机房冷冻水泵总功率，冷冻水供回水温差越大，冷冻水输送系数越高，冷冻水系统效率越高。",
        tip2: "当日机房总供冷量/机房冷冻水泵总耗电量，冷冻水供回水平均温差越大，冷冻水输送系数越高，冷冻水系统效率越高。"
      }, {
        name: "冷却泵输送系数",
        value: 3,
        showTip: true,
        tip1: "机房总散热负荷/机房冷却水泵总功率，冷却水系统供回水温差越大，冷却水输送系数越高，冷却水系统效率越高。",
        tip2: "当日机房总散热量/机房冷却水泵总耗电量，冷却水系统供回水平均温差越大，冷却水输送系数越高，冷却水系统效率越高。"
      }, {
        name: "冷却塔系统能效",
        value: 4,
        showTip: true,
        tip1: "机房总散热负荷/机房冷却塔总功率，冷却塔系统能效越高，冷却塔系统效率越高。",
        tip2: "当日机房总散热量/机房冷却塔总耗电量，冷却塔系统能效越高，冷却塔系统效率越高。"
      }],
      // 最终后台传了几个  重写了一遍  showTip tip1 tip2与dom同时渲染是有异步
      tabList: []
    };
  },
  created: function created() {
    var arr = JSON.parse(window.sessionStorage.getItem("menuAuths")) || [];
    this.pathData = arr.map(function (e) {
      return e.path;
    });
    if (this.pathData.indexOf("home-page") !== 0) {
      // this.$message({
      //   type: 'info',
      //   message: '无权限查看'
      // })
      this.$router.push({
        path: "/403"
      });
      return;
    }
    this.getHistoryAnalysisType();
    this.getEnergyConsumptionCalendarData();
    this.getDayHistoryAnalysisData();
  },
  methods: {
    // 获取tab栏表头
    getHistoryAnalysisType: function getHistoryAnalysisType() {
      var _this = this;
      var params = {
        projectIde: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.historyAnalysisType)(params).then(function (res) {
        res.map(function (item) {
          _this.nameTips.map(function (m) {
            if (item == m.value) {
              _this.tabList.push(m);
            }
          });
        });
      });
    },
    getDayHistoryAnalysisData: function getDayHistoryAnalysisData() {
      var _this2 = this;
      this.chartData = [];
      var params = {
        timeDate: this.dayData,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.getDayHistoryAnalysis)(params).then(function (res) {
        //  
        if (JSON.stringify(res.data) === "{}") {
          _this2.chartData = [{
            name: "关键参数",
            chartData: []
          }, {
            name: "冷冻泵输送系数",
            chartData: []
          }, {
            name: "冷却泵输送系数",
            chartData: []
          }, {
            name: "冷却塔系统能效",
            chartData: []
          }];
        } else {
          var name = "";
          res.map(function (e, i) {
            e.map(function (m) {
              if (m.analysisType == 1) {
                name = "关键参数";
              } else if (m.analysisType == 2) {
                name = "冷冻泵输送系数";
              } else if (m.analysisType == 3) {
                name = "冷却泵输送系数";
              } else {
                name = "冷却塔系统能效";
              }
            });
            _this2.chartData.push({
              name: name,
              chartData: e
            });
          });
        }
      });
    },
    tagCheck: function tagCheck(e) {
      this.activeName = 'first' + e;
    },
    //图表切换
    handleClick: function handleClick(tab, event) {
      this.k = tab.index;
      this.$refs.carousel.setActiveItem(tab.index);
    },
    handleShow: function handleShow(val) {
      this.isShow = val;
      if (val) {
        //瞬时
        this.dayData = (0, _dayjs.default)().format("YYYY-MM-DD");
        this.getDayHistoryAnalysisData();
      } else {
        this.monthData = (0, _dayjs.default)().format("YYYY-MM");
        this.getMonthHistoryAnalysisData();
      }
    },
    handleDay: function handleDay(val) {
      this.getDayHistoryAnalysisData();
    },
    handleMonth: function handleMonth(val) {
      this.getMonthHistoryAnalysisData();
    },
    handelCalendar: function handelCalendar() {
      this.getEnergyConsumptionCalendarData();
    },
    getMonthHistoryAnalysisData: function getMonthHistoryAnalysisData() {
      var _this3 = this;
      var params = {
        timeDate: this.monthData,
        projectId: this.$store.state.project.projectInfo.id
      };
      this.chartData = [];
      (0, _dataAnalysis.getMonthHistoryAnalysis)(params).then(function (res) {
        if (JSON.stringify(res.data) === "{}") {
          _this3.chartData = [{
            name: "关键参数",
            chartData: [],
            showTip: false,
            tip: ""
          }, {
            name: "冷冻泵输送系数",
            chartData: []
          }, {
            name: "冷却泵输送系数",
            chartData: []
          }, {
            name: "冷却塔系统能效",
            chartData: []
          }];
        } else {
          var name = "";
          res.map(function (e, i) {
            e.map(function (m) {
              if (m.analysisType == 1) {
                name = "关键参数";
              } else if (m.analysisType == 2) {
                name = "冷冻泵输送系数";
              } else if (m.analysisType == 3) {
                name = "冷却泵输送系数";
              } else {
                name = "冷却塔系统能效";
              }
            });
            _this3.chartData.push({
              name: name,
              chartData: e
            });
          });
        }
      });
    },
    getEnergyConsumptionCalendarData: function getEnergyConsumptionCalendarData() {
      var _this4 = this;
      var params = {
        timeDate: this.calendar,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.getEnergyConsumptionCalendar)(params).then(function (res) {
        _this4.consumptionCalendarData = res;
      });
    }
  }
};