"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _head = _interopRequireDefault(require("@/components/head"));
var _collect = _interopRequireDefault(require("./components/collect"));
var _historical = _interopRequireDefault(require("./components/historical"));
var _energy = _interopRequireDefault(require("./components/energy"));
var _pie = _interopRequireDefault(require("./components/pie"));
var _dataAnalyse = _interopRequireDefault(require("../data-analyse"));
var _default = exports.default = {
  components: {
    headr: _head.default,
    collect: _collect.default,
    historical: _historical.default,
    energy: _energy.default,
    pie: _pie.default,
    analyse: _dataAnalyse.default
  },
  data: function data() {
    return {
      activeName: "",
      firstInit: true,
      menusList: []
    };
  },
  computed: {},
  destroyed: function destroyed() {
    sessionStorage.removeItem("firstInit");
  },
  created: function created() {
    // 初始化重置样式
    if (!sessionStorage.getItem("firstInit")) {
      sessionStorage.setItem("firstInit", true);
    }

    // 获取动态菜单
    var menus = JSON.parse(sessionStorage.getItem('umenuauths'));
    var list = [];
    if (menus.length > 0) {
      menus.forEach(function (item) {
        if (item.name == '查询') {
          item.children.forEach(function (ite) {
            if (ite.type === 0) {
              list.push(ite);
            }
          });
        }
      });
      this.menusList = list.sort(this.sortBy('priority'));
      if (sessionStorage.getItem('activeDataQueryName')) {
        this.activeName = sessionStorage.getItem('activeDataQueryName');
      } else {
        this.activeName = 'tag' + this.menusList[0].id;
      }
    }
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      // 点击切换tab栏发生的事件
      sessionStorage.setItem('activeDataQueryName', this.activeName);
    },
    // 排序
    sortBy: function sortBy(field) {
      //根据传过来的字段进行排序
      return function (x, y) {
        return x[field] - y[field];
      };
    }
  }
};