"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchUpdateProjectAlarmState = batchUpdateProjectAlarmState;
exports.getCoemColdPrice = getCoemColdPrice;
exports.getColdPrice = getColdPrice;
exports.getCop = getCop;
exports.getEfficiencyRise = getEfficiencyRise;
exports.getEnergy = getEnergy;
exports.getEnergyConsumption = getEnergyConsumption;
exports.getLogoImg = getLogoImg;
exports.getProject = getProject;
exports.getProjectCoolList = getProjectCoolList;
exports.getProjectEueep = getProjectEueep;
exports.getProjectHeader = getProjectHeader;
exports.getProjectHyperLink = getProjectHyperLink;
exports.getProjectList = getProjectList;
exports.getSkin = getSkin;
exports.getSystemNameByUse = getSystemNameByUse;
exports.getUntreatedProjectAlarm = getUntreatedProjectAlarm;
exports.projectInfoCompanyList = projectInfoCompanyList;
exports.updateSkin = updateSkin;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /*
 * @Author: your name
 * @Date: 2020-12-05 11:43:50
 * @LastEditTime: 2021-11-02 17:02:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yiLong\src\api\home.js
 */
// 获取项目列表
function getProject() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
function projectInfoCompanyList() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoCompanyList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

// 常规项目信息
function getProjectList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHomePageData/projectHomePageDataList",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 蓄冷项目信息
function getProjectCoolList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHomePageData/homePageDataRealCoolStorage",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 获取能效抬头
function getEfficiencyRise(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/efficiencyRise/efficiencyRiseList",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getColdPrice(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHomePageData/homePageDataHistoryColdPrice",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getCop(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHomePageData/homePageDataHistoryCop",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getProjectHyperLink(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var hyperLinkAddressType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return (0, _request.default)({
    url: "/projectHyperLink/projectHyperLinkByProjectId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType,
      hyperLinkAddressType: hyperLinkAddressType
    })
  });
}
function getProjectHeader(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHeader/projectHeaderInfo",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getLogoImg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectLogoImage/projectLogoImageByProjectId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getProjectEueep(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectEfficiency/projectEfficiencyByProjectId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getSystemNameByUse() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/systemName/systemNameByUser",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
function getSkin(id) {
  return (0, _request.default)({
    url: "/users/getSkin",
    method: 'get',
    params: {
      id: id
    }
  });
}
function updateSkin(id, skin) {
  return (0, _request.default)({
    url: "/users/updateSkin",
    method: 'post',
    params: {
      id: id,
      skin: skin
    }
  });
}
function getEnergyConsumption(ids) {
  return (0, _request.default)({
    url: "/coem/getEnergyConsumption",
    method: 'get',
    params: {
      ids: ids
    }
  });
}
function getCoemColdPrice(ids) {
  return (0, _request.default)({
    url: "/coem/getColdPrice",
    method: 'get',
    params: {
      ids: ids
    }
  });
}
function getEnergy(params) {
  return (0, _request.default)({
    url: "/coem/getEnergy",
    method: 'post',
    params: _objectSpread({}, params)
  });
}

//告警通知查询
function getUntreatedProjectAlarm(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectAlarm/getUntreatedProjectAlarm",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//批量修改状态（只传当前数据id集合
function batchUpdateProjectAlarmState(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectAlarm/batchUpdateProjectAlarmState",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}