"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCoolDayWordPreview = getCoolDayWordPreview;
exports.getCoolMonthWordPreview = getCoolMonthWordPreview;
exports.getCoolNhglAndNxgl = getCoolNhglAndNxgl;
exports.getCoolYearReport = getCoolYearReport;
exports.getDayChartData = getDayChartData;
exports.getDayColdPrice = getDayColdPrice;
exports.getDayCop = getDayCop;
exports.getDayProjectCoolStorageReportRegenerate = getDayProjectCoolStorageReportRegenerate;
exports.getDayProjectCoolingReportRegenerate = getDayProjectCoolingReportRegenerate;
exports.getDayReport = getDayReport;
exports.getDayReportList = getDayReportList;
exports.getDayWordPreview = getDayWordPreview;
exports.getFpg = getFpg;
exports.getGl = getGl;
exports.getGlsc = getGlsc;
exports.getMonthColdStorageReportByDateSelect = getMonthColdStorageReportByDateSelect;
exports.getMonthNxgl = getMonthNxgl;
exports.getMonthProjectCoolStorageReportRegenerate = getMonthProjectCoolStorageReportRegenerate;
exports.getMonthProjectCoolingReportRegenerate = getMonthProjectCoolingReportRegenerate;
exports.getMonthReport = getMonthReport;
exports.getMonthWordPreview = getMonthWordPreview;
exports.getMonthWordYearList = getMonthWordYearList;
exports.getNdbg = getNdbg;
exports.getNhgl = getNhgl;
exports.getNhzb = getNhzb;
exports.getNxgl = getNxgl;
exports.getProjectDayReportByProjectIdOrGenerationListDate = getProjectDayReportByProjectIdOrGenerationListDate;
exports.getYearReportList = getYearReportList;
exports.getYearReportZip = getYearReportZip;
exports.monthProjectReportPreviewData = monthProjectReportPreviewData;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /*
 * @Author: your name
 * @Date: 2020-12-31 10:06:03
 * @LastEditTime: 2021-12-28 17:52:23
 * @LastEditors: zlt
 * @Description: In User Settings Edit
 * @FilePath: \yilong\src\api\report.js
 */
function getDayReport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/downloadDayWordReportZip",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getDayReportList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDayWord/projectDayWordMonthList",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getMonthReport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/downloadMonthWordReportZip",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getYearReportList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectYearWord/projectYearWordByProjectId",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getYearReportZip(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/downloadYearWordReportZip",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getMonthWordYearList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectMonthWord/projectMonthWordYearList",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 日报表预览——常规、蓄冷
function getDayWordPreview(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/dayProjectReportData",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getCoolDayWordPreview(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolStorageProjectReport/generateColdStorageDayReport",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 月报表预览——常规、蓄冷
function getMonthWordPreview(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/monthProjectReportData",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function monthProjectReportPreviewData(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/monthProjectReportPreviewData",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getCoolMonthWordPreview(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolStorageProjectReport/monthProjectReportData",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
// 蓄冷月报表
function getMonthColdStorageReportByDateSelect(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolStorageProjectReport/getMonthColdStorageReportByDateSelect",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
// 常规获取日报表补充数据（三期二批新增）
function getDayChartData(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/regularDailyReportSupplementByProjectIdOrDayReportDate",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 常规指定项目，日期生成常规日报
function getDayProjectCoolingReportRegenerate(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReportRegenerate/dayProjectCoolingReportRegenerate",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 根据指定蓄冷项目，日期重新生成日报表
function getDayProjectCoolStorageReportRegenerate(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReportRegenerate/dayProjectCoolStorageReportRegenerate",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 根据指定常规项目，日期重新生成月报表
function getMonthProjectCoolingReportRegenerate(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReportRegenerate/monthProjectCoolingReportRegenerate",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 根据指定蓄冷项目，日期重新生成月报表
function getMonthProjectCoolStorageReportRegenerate(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReportRegenerate/monthProjectCoolStorageReportRegenerate",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 获取时间内天的供冷量、耗电量、日电费
function getProjectDayReportByProjectIdOrGenerationListDate(params) {
  return (0, _request.default)({
    url: "/ProjectMonthReport/getFirstChart",
    method: "get",
    params: _objectSpread({}, params)
  });
}

// 获取蓄冷系统月报表 能耗概览 的供冷量、耗电量、日电费
function getCoolNhglAndNxgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolNhglAndNxgl",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 获取时间内天的峰平谷
function getFpg(params) {
  return (0, _request.default)({
    url: "/projectReport/getFpg",
    method: "get",
    params: _objectSpread({}, params)
  });
}

// 根据条件查询日开机时长数据(供冷时长)
function getGlsc(params) {
  return (0, _request.default)({
    url: "/projectBootTime/getGlsc",
    method: 'get',
    params: _objectSpread({}, params)
  });
}
function getDayCop(params) {
  return (0, _request.default)({
    url: "/projectCopOrColdPrice/getDayCop",
    method: 'get',
    params: _objectSpread({}, params)
  });
}
function getDayColdPrice(params) {
  return (0, _request.default)({
    url: "/projectCopOrColdPrice/getDayColdPrice",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

//能效概览
function getNxgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getNxgl",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//能效概览 月
function getMonthNxgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getMonthNxgl",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//能耗占比
function getNhzb(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getNhzb",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//能耗概览
function getNhgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getNhgl",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//年度报告
function getNdbg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getNdbg",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//蓄冷年度报告
function getCoolYearReport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolYearReport",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//供冷时长
function getGl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getGlsc",
    method: "get",
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}