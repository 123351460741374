"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      msg: '时间读取中...',
      nyr: (0, _dayjs.default)().format("YYYY-MM-DD"),
      sfm: (0, _dayjs.default)().format("HH:mm:ss"),
      week: (0, _dayjs.default)().day(),
      weeks: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
    };
  },
  mounted: function mounted() {
    setInterval(this.getDate, 1000);
  },
  //方法集合
  methods: {
    getDate: function getDate() {
      this.msg = (0, _dayjs.default)().format("YYYY-MM-DD") + ' ' + this.weeks[(0, _dayjs.default)().day()] + ' ' + (0, _dayjs.default)().format("HH:mm:ss");
    }
  }
};