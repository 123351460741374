"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataQuery = require("@/api/dataQuery");
var _auth = require("@/utils/auth");
var _default = exports.default = {
  data: function data() {
    var _this2 = this;
    return {
      allDataNew: [],
      selectShow: false,
      dataNew: [],
      value: "",
      sjjgValue: "五分钟",
      selectDataList: [],
      planName: "",
      planId: null,
      dialogTableVisible: false,
      dialogFormVisible: false,
      downLoading: false,
      loading: false,
      rules: {
        planName: [{
          required: true,
          message: "请输入方案名称",
          trigger: "blur"
        }]
      },
      colors: ["#3e89e7", "#fb8c3b", "#63be65", "#d9c32b", "#e11aed", "#26c9e7", "#25c1a9", "#1665D8", "#dd3710", "#48a221", "#ffac30", "#75deff", "#8656ff", "#98FF25", "#FFF100", "#ee2dff", "#2deeff", "#ffac2a", "#91c7ae", "#749f83", "#ca8622", "#bda29a", "#6e7074", "#546570", "#c4ccd3"],
      userInfo: {},
      // 点击查询的表单项
      form: {
        startHour: "00:00",
        endHour: "23:59",
        startTime: "",
        endTime: "",
        timeType: 1,
        timeIntervalType: 1,
        projectId: this.$store.state.project.projectInfo.id,
        projectFieldIds: []
      },
      queryForm: {
        projectSystemDeviceValue: [],
        projectFieldInfoValue: [],
        projectSystemValue: [],
        isShow: 1,
        timeData: ["00:00", "23:59"],
        dayValue: []
      },
      queryOneForm: {
        projectSystemDeviceValue: [],
        projectFieldInfoValue: [],
        projectSystemValue: []
      },
      projectSystemData: [],
      //系统一级目录
      projectSystemDeviceData: [],
      projectFieldInfoData: [],
      historicalData: [],
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      iscred: 1,
      colorType: "",
      echartfc: "",
      // 图表颜色
      treedata: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      // 第一次进入页面的默认展开项
      defaultChecked: [],
      // 模拟一个接口里面的一个数据来进行遍历
      allTreeList: [],
      startDate: '',
      endDate: '',
      startDatePicker: {
        //禁用的日期
        disabledDate: function disabledDate(time) {
          //如果已经已知结束日期，就把结束日期之后的日期禁用
          if (_this2.endDate) {
            return time.getTime() > new Date(_this2.endDate).getTime();
          } else {
            //否则 就把当前日期之后的日期禁用掉
            return time.getTime() > Date.now();
          }
        }
      },
      endDatePicker: {
        disabledDate: function disabledDate(time) {
          //如果已知开始日期，就把开始日期之前的日期禁用掉
          if (_this2.startDate) {
            return time.getTime() < new Date(_this2.startDate).getTime() - 1 * 24 * 60 * 60 * 1000;
          }
        }
      },
      dateList: [{
        name: '最近三天',
        id: 1
      }, {
        name: '最近一周',
        id: 2
      }, {
        name: '最近一月',
        id: 3
      }],
      allTreeChildrenIds: [],
      variableHeight: '',
      zoom: '',
      zoomrAtio: ''
    };
  },
  mounted: function mounted() {
    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
    this.getHeight();
  },
  watch: {
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;
      if (this.colorType == 'theme_jyh') {
        this.echartfc = '#fff';
      } else if (this.colorType == 'theme_ytl') {
        this.echartfc = '#333';
      } else {
        this.echartfc = '#333';
      }
      this.getLineCharts();
    }
  },
  created: function created() {
    this.colorType = this.$store.state.project.colorType;
    if (this.colorType == 'theme_jyh') {
      this.echartfc = '#fff';
    } else if (this.colorType == 'theme_ytl') {
      this.echartfc = '#333';
    } else {
      this.echartfc = '#333';
    }
    this.defaultParams();
    this.getPlanData();
    // 系统一级目录
    this.getProjectSystemData();
    // 查询所有树状结构所有子类数据
    this.getProjectSystemAllChildrenList();
  },
  methods: {
    getHeight: function getHeight() {
      var height = window.screen.height * window.devicePixelRatio;
      if (height >= 2160) {
        this.variableHeight = 'vh1';
      } else if (height >= 1800) {
        this.variableHeight = 'vh2';
      } else if (height >= 1600) {
        this.variableHeight = 'vh3';
      } else if (height >= 1440) {
        this.variableHeight = 'vh4';
      } else if (height >= 1000) {
        this.variableHeight = '';
      } else if (height >= 800) {
        this.variableHeight = 'vh6';
      } else if (height >= 700) {
        this.variableHeight = 'vh5';
      } else {
        this.variableHeight = '';
      }
    },
    // 点击切换时间
    handelTime: function handelTime(val) {
      this.queryForm.isShow = val;
      this.startDate = '';
      this.endDate = '';
      this.form.startTime = '';
      this.form.endTime = '';
    },
    // 查询所有树状结构所有子类数据
    getProjectSystemAllChildrenList: function getProjectSystemAllChildrenList() {
      var _this3 = this;
      var params = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataQuery.projectSystemAllChildrenList)(params).then(function (res) {
        _this3.allTreeList = res;
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 获取所有子类id
    getAllTreeIds: function getAllTreeIds(val) {
      var _this4 = this;
      val.forEach(function (item) {
        if (item.children && item.children.length) {
          _this4.getAllTreeIds(item.children);
        } else {
          _this4.allTreeChildrenIds.push(item.id);
        }
      });
    },
    // 查询所有树状结构
    getProjectSystemAllList: function getProjectSystemAllList() {
      var _this5 = this;
      var params = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataQuery.projectSystemAllList)(params).then(function (res) {
        // 获取树状结构
        _this5.getAllTreeIds(res);
        localStorage.setItem('allTreeChildrenIds', _this5.allTreeChildrenIds);
        _this5.treedata = res;
        _this5.sortTreeData(_this5.treedata);
        // 如果第一次进来就用接口默认的 如果他自己选了就用缓存的 
        if (localStorage.getItem('hisAllCheckedIds')) {
          // 先获取缓存里面的数据
          _this5.defaultChecked = localStorage.getItem('hisAllCheckedIds').split(',');
          // 还要判断是否用了别的项目的缓存
          if (_this5.defaultChecked.some(function (item) {
            return _this5.allTreeChildrenIds.includes(item * 1);
          })) {
            _this5.$nextTick(function () {
              _this5.$refs.tree.setCheckedKeys([]);
              _this5.defaultChecked = localStorage.getItem('hisAllCheckedIds').split(',');
            });
          } else {
            _this5.defaultChecked = _this5.form.projectFieldIds;
            localStorage.setItem('hisAllCheckedIds', _this5.defaultChecked);
          }
        } else {
          _this5.defaultChecked = _this5.form.projectFieldIds;
        }
        _this5.getDataStatisticsData();
      }).catch(function (err) {
        console.log(err);
      });
    },
    sortTreeData: function sortTreeData(arr) {
      var _this6 = this;
      if (arr && arr.length && arr != undefined) {
        //排序当前数据
        this.sortByNationality(arr);
        //递归排序子目录
        arr.forEach(function (item) {
          if (item.children && item.children.length) {
            _this6.sortTreeData(item.children);
          }
        });
      }
      return;
    },
    sortByNationality: function sortByNationality(arr) {
      // 首先将数组按照字典序进行排序  
      arr.sort(function (a, b) {
        var aName = a.name.toUpperCase();
        var bName = b.name.toUpperCase();
        return aName.localeCompare(bName, "zh");
      });
      return arr;
    },
    // 监听他选中的项
    handleCheckChange: function handleCheckChange(data, checked, indeterminate) {
      this.form.projectFieldIds = this.$refs.tree.getCheckedKeys();
      // 选中id当做缓存
      localStorage.setItem('hisAllCheckedIds', this.form.projectFieldIds);
    },
    //历史数据报表查询默认参数
    defaultParams: function defaultParams() {
      var ts = this;
      ts.iscred = 2;
      var par = {
        projectId: ts.$store.state.project.projectInfo.id
      };
      (0, _dataQuery.defaultParams)(par).then(function (res) {
        ts.form.startHour = res.startHour;
        ts.form.endHour = res.endHour;
        ts.form.startTime = res.startTime;
        ts.form.endTime = res.endTime;
        ts.form.timeType = res.timeType;
        ts.form.timeIntervalType = res.timeIntervalType;
        if (res.projectFieldIds && res.projectFieldIds.length > 0) {
          res.projectFieldIds.forEach(function (item) {
            ts.form.projectFieldIds.push(item);
          });
        }
        // 查询所有历史数据树状列表
        ts.getProjectSystemAllList();
        ts.queryOneForm.projectSystemValue.push(res.projectSystemDeviceId);
        ts.queryOneForm.projectSystemDeviceValue.push(res.equipmentId);
        ts.queryOneForm.projectFieldInfoValue = res.projectFieldIds;
        if (res.startTime && res.endTime) {
          ts.queryForm.dayValue.push(res.startTime);
          ts.queryForm.dayValue.push(res.endTime);
        }
        ts.queryForm.isShow = res.timeType;
        ts.sjjgValue = [];
        if (res.timeIntervalType === 0) {
          ts.sjjgValue.push("原始");
        }
        if (res.timeIntervalType === 1) {
          ts.sjjgValue.push("五分钟");
        }
        if (res.timeIntervalType === 2) {
          ts.sjjgValue.push("一小时");
        }
        // this.getProjectSystemDeviceData(res.projectSystemDeviceId);
        // this.getProjectFieldInfoData(res.equipmentId);
        // echarts图表
      });
    },
    getClassOptionTitle: function getClassOptionTitle(val) {
      var _this7 = this;
      this.allDataNew = [];
      val.energyProjectPlanFieldList.forEach(function (item) {
        _this7.allDataNew.push({
          data: item.fieldName
        });
      });
      this.selectShow = true;
    },
    // 选择无得情况下要执行的操作
    selectChangeValueNone: function selectChangeValueNone() {
      this.queryOneForm.projectSystemValue = [];
      this.queryOneForm.projectSystemDeviceValue = [];
      this.form.projectFieldIds = [];
      this.queryForm.projectFieldInfoValue = [];
      this.queryForm.projectSystemValue = [];
      this.queryForm.projectSystemDeviceValue = [];
      this.form.projectFieldIds = [];
      this.queryOneForm.projectFieldInfoValue = [];
      this.planId = null;
      this.$refs.tree.setCheckedKeys([]);
      this.getProjectSystemData();
      sessionStorage.setItem("queryForm", JSON.stringify(this.queryForm));
    },
    // 点击了方案要执行的操作
    selectChangeValue: function selectChangeValue(val) {
      var _this8 = this;
      this.queryForm.projectSystemValue = [];
      this.queryForm.projectSystemDeviceValue = [];
      this.queryForm.projectFieldInfoValue = [];
      val.energyProjectPlanSystemList.forEach(function (item) {
        _this8.queryForm.projectSystemValue.push(item.systemId);
      });
      val.energyProjectPlanDeviceList.forEach(function (item) {
        _this8.queryForm.projectSystemDeviceValue.push(item.deviceId);
      });
      val.energyProjectPlanFieldList.forEach(function (item) {
        _this8.queryForm.projectFieldInfoValue.push(item.fieldId);
        _this8.planId = item.planId;
      });
      this.form.projectFieldIds = this.queryForm.projectFieldInfoValue;
      // 点击切换下拉，先清空再重新赋值
      this.$refs.tree.setCheckedKeys([]);
      this.defaultChecked = this.form.projectFieldIds;
      sessionStorage.setItem("queryForm", JSON.stringify(this.queryForm));
      // this.getProjectSystemData();
      // this.getProjectSystemDeviceData(this.queryForm.projectSystemValue);
      // this.getProjectFieldInfoData(this.queryForm.projectSystemDeviceValue);
      // this.allParamsSort();
    },
    // 下拉选项，传值 可以不要默认是会双向数据绑定的
    selectChangeKey: function selectChangeKey(name) {
      this.planName = name;
    },
    //整体排序
    allParamsSort: function allParamsSort() {
      this.projectSystemData = this.paramsSort(this.projectSystemData, "projectSystemName");
      this.projectSystemDeviceData = this.paramsSort(this.projectSystemDeviceData, "systemDeviceName");
      this.projectFieldInfoData = this.paramsSort(this.projectFieldInfoData, "fieldMeaning");
    },
    // 中文排序
    paramsSort: function paramsSort(data, params) {
      var _this9 = this;
      var defaultSort = data.sort(function (a, b) {
        return a[params].localeCompare(b[params], "zh");
      });
      //选中的置顶
      var firstSort = [];
      var lastSort = [];
      defaultSort.forEach(function (item) {
        if (params == "projectSystemName") {
          _this9.queryOneForm.projectSystemValue.forEach(function (data) {
            if (item.id == data) {
              firstSort.push(item);
            }
          });
        }
        if (params == "systemDeviceName") {
          _this9.queryOneForm.projectSystemDeviceValue.forEach(function (data) {
            if (item.id == data) {
              firstSort.push(item);
            }
          });
        }
        if (params == "fieldMeaning") {
          _this9.queryOneForm.projectFieldInfoValue.forEach(function (data) {
            if (item.id == data) {
              firstSort.push(item);
            }
          });
        }
      });
      defaultSort.forEach(function (item) {
        if (!firstSort.includes(item)) {
          lastSort.push(item);
        }
      });
      lastSort.forEach(function (data) {
        firstSort.push(data);
      });
      return firstSort;
    },
    // 导出
    handleDown: function handleDown() {
      this.downLoading = true;
      if (this.form.projectFieldIds.length === 0) {
        this.$message.warning("请选择字段名称");
      } else {
        this.getHistoricalDatExportData();
      }
    },
    // 切换时间
    handleStartTime: function handleStartTime(val) {
      this.queryForm.isShow = 0;
      this.form.startTime = val;
    },
    handleEndTime: function handleEndTime(val) {
      this.queryForm.isShow = 0;
      this.form.endTime = val;
    },
    getProjectFieldInfoData: function getProjectFieldInfoData(val) {
      var _this10 = this;
      var params = {
        systemDeviceIds: val
      };
      (0, _dataQuery.getProjectFieldInfo)(params).then(function (res) {
        _this10.projectFieldInfoData = _this10.paramsSort(res, "fieldMeaning");
        var arr = [];
        var _loop = function _loop(i) {
          _this10.projectFieldInfoData.map(function (e) {
            if (_this10.form.projectFieldIds[i] === e.id) {
              arr.push(e.id);
            }
          });
        };
        for (var i = 0; i < _this10.form.projectFieldIds.length; i++) {
          _loop(i);
        }
        if (_this10.iscred != 2) {
          _this10.form.projectFieldIds = arr;
          _this10.queryForm.projectFieldInfoValue = arr;
          _this10.queryOneForm.projectFieldInfoValue = arr;
        }
      });
    },
    // 切换系统 查找设备
    getProjectSystemDeviceData: function getProjectSystemDeviceData(val) {
      var _this11 = this;
      var params = {
        projectSystemIds: val
      };
      var projectSystemDeviceValueOld = this.queryOneForm.projectSystemDeviceValue;
      (0, _dataQuery.getProjectSystemDevice)(params).then(function (res) {
        _this11.projectSystemDeviceData = _this11.paramsSort(res, "systemDeviceName");
        var arr = [];
        var _loop2 = function _loop2(i) {
          _this11.projectSystemDeviceData.map(function (e) {
            if (_this11.queryForm.projectSystemDeviceValue[i] === e.id) {
              arr.push(e.id);
            }
          });
        };
        for (var i = 0; i < _this11.queryForm.projectSystemDeviceValue.length; i++) {
          _loop2(i);
        }
        if (_this11.iscred != 2) {
          _this11.queryForm.projectSystemDeviceValue = arr;
          _this11.queryOneForm.projectSystemDeviceValue = arr;
          if (_this11.queryForm.projectSystemDeviceValue.length !== 0) {
            _this11.getProjectFieldInfoData(_this11.queryForm.projectSystemDeviceValue);
          } else {
            _this11.form.projectFieldIds = [];
            _this11.queryForm.projectFieldInfoValue = [];
            _this11.queryOneForm.projectFieldInfoValue = [];
          }
        }
      });
      if (this.iscred != 2) {
        this.queryOneForm.projectSystemDeviceValue = [];
        projectSystemDeviceValueOld.forEach(function (data) {
          _this11.projectSystemDeviceData.forEach(function (newData) {
            if (newData.id == data) {
              _this11.queryOneForm.projectSystemDeviceValue.push(data);
            }
          });
        });
      }
    },
    // 获取子类id this.defaultChecked获取的是所有的选中，只需要选中的子类
    getChildrenId: function getChildrenId(arr) {
      var _this12 = this;
      var tempChildrenId = [];
      // 先判断是否第一次进来，第一次进来没有缓存
      if (localStorage.getItem('hisAllCheckedIds')) {
        if (localStorage.getItem('allTreeChildrenIds')) {
          this.allTreeChildrenIds = localStorage.getItem('allTreeChildrenIds').split(',');
        }
        arr.forEach(function (item) {
          if (_this12.allTreeChildrenIds.includes(item)) {
            tempChildrenId.push(item);
          }
        });
      } else {
        tempChildrenId = arr;
      }
      return tempChildrenId;
    },
    getDataStatisticsData: function getDataStatisticsData() {
      var _this13 = this;
      this.loading = true;
      this.form.timeType = this.queryForm.isShow;
      if (localStorage.getItem('hisAllCheckedIds')) {
        this.form.projectFieldIds = localStorage.getItem('hisAllCheckedIds').split(',');
      }
      this.form.projectFieldIds = this.getChildrenId(this.form.projectFieldIds);
      (0, _dataQuery.getDataStatistics)(this.form).then(function (res) {
        _this13.historicalData = res || [];
        _this13.getLineCharts();
        _this13.loading = false;
      }).catch(function (err) {
        _this13.historicalData = [];
        _this13.loading = false;
      });
    },
    // 获取系统数据
    getProjectSystemData: function getProjectSystemData() {
      var _this14 = this;
      var params = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataQuery.getProjectSystem)(params).then(function (res) {
        _this14.projectSystemData = _this14.paramsSort(res, "projectSystemName");
      });
    },
    // 下载报表
    getHistoricalDatExportData: function getHistoricalDatExportData() {
      var _this15 = this;
      this.downLoading = true;
      this.form.timeType = this.queryForm.isShow;
      (0, _dataQuery.getHistoricalDatExport)(this.form).then(function (res) {
        _this15.downLoading = false;
        var link = document.createElement("a");
        link.href = res;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function () {
        _this15.downLoading = false;
      });
    },
    // 点击查询按钮
    handlQuery: function handlQuery() {
      if (this.form.projectFieldIds.length === 0) {
        this.$message.warning("请选择字段名称");
      } else if (this.queryForm.isShow == 0) {
        if (!this.startDate) {
          this.$message.warning("请选择开始日期");
        } else if (!this.endDate) {
          this.$message.warning("请选择结束日期");
        } else {
          this.getDataStatisticsData();
        }
      } else {
        this.getDataStatisticsData();
      }
    },
    // 获取图表数据
    getLineCharts: function getLineCharts() {
      var _this16 = this;
      var _this = this;
      var lineCharts = this.$echarts.init(document.getElementById("chart-line"));
      lineCharts.clear();
      var historicalData = [];
      _this.historicalData.map(function (e, i) {
        historicalData.push({
          type: "line",
          name: e.fieldMeaning,
          symbol: "circle",
          // barWidth: 10,
          dimensions: [e.company // 如果此维度不想给出定义，则使用 null 即可
          ],
          showSymbol: false,
          data: e.data
        });
      });
      if (this.form.timeIntervalType != 0) {
        var option = {
          tooltip: {
            confine: true,
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,1)",
            extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#000000",
            textStyle: {
              fontSize: 14,
              lineHeight: 30
            },
            padding: 15,
            formatter: function formatter(params) {
              var str = "";
              str = params[0].name;
              params.map(function (el) {
                str = str + "<br/><span class=\"color-circle\" style=\"background:".concat(el.color, ";\"></span> ").concat(el.seriesName, " : ").concat(el.value[1], "  ").concat(el.dimensionNames[0] === "x" ? "" : el.dimensionNames[0]);
              });
              return str;
            }
          },
          color: this.colors,
          toolbox: {
            show: true,
            itemSize: 18,
            top: "6%",
            right: "7%",
            feature: {
              /* line是折线图，bar是柱形图*/
              magicType: {
                //动态类型切换
                show: true,
                //是否显示该工具
                type: ['line', 'bar'],
                //启用的动态类型
                title: {
                  line: '切换为折线图',
                  bar: '切换为柱状图'
                }
              },
              myTool1: {
                show: false,
                title: "下载报表",
                icon: "image://https://s4.aconvert.com/convert/p3r68-cdx67/agdum-o8zxo.svg",
                onclick: function onclick() {
                  _this.getHistoricalDatExportData();
                }
              }
            },
            iconStyle: {}
          },
          legend: {
            icon: "circle",
            top: "1%",
            bottom: "1%",
            width: '85%',
            type: "scroll",
            textStyle: {
              // label: { show: true },
              color: this.echartfc
            }
          },
          grid: {
            left: "3%",
            right: "3%",
            top: "10%",
            bottom: "10%",
            containLabel: true
          },
          dataZoom: [{
            type: "slider",
            height: 20,
            handleIcon: "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "100%",
            handleStyle: {
              borderColor: "#D2D0FF",
              shadowBlur: 4,
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowColor: "#e5e5e5"
            },
            show: true,
            // flase直接隐藏图形
            xAxisIndex: [0],
            left: "4%",
            // 滚动条靠左侧的百分比
            bottom: 15,
            start: 0,
            // 滚动条的起始位置
            end: 100 // 滚动条的截止位置（按比例分割你的柱状图x轴长度）,
            // startValue: 10, // 数据窗口范围的起始数值
            // endValue: 100
          }],
          xAxis: {
            type: "category",
            boundaryGap: true,
            splitLine: {
              show: false
            },
            // 去除网格线
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: function formatter(params) {
                if (params) {
                  var newParamsName = "";
                  var paramsNameNumber = params.length;
                  var provideNumber = 10; // 一行显示几个字
                  var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                  if (paramsNameNumber > provideNumber) {
                    for (var p = 0; p < rowNumber; p++) {
                      var tempStr = "";
                      var start = p * provideNumber;
                      var end = start + provideNumber;
                      if (p === rowNumber - 1) {
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        tempStr = params.substring(start, end) + "\n";
                      }
                      newParamsName += tempStr;
                    }
                  } else {
                    newParamsName = params;
                  }
                  return newParamsName;
                }
              },
              textStyle: {
                color: "#999999" // 文字颜色
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#cfcfcf",
                width: 1,
                type: "solid"
              }
            }
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#cfcfcf",
                width: 1,
                type: "solid"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#999999" // 文字颜色
              }
            }
          },
          series: historicalData
        };
        lineCharts.setOption(option);
      } else {
        var _option = {
          tooltip: {
            confine: true,
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,1)",
            extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#333333",
            textStyle: {
              fontSize: 14,
              lineHeight: 30
            },
            padding: 15,
            formatter: function formatter(params) {
              if (params) {
                var str = "";
                str = params[0].name;
                str += params[0].data[0];
                params.map(function (el) {
                  str = str + "<br/><span class=\"color-circle\" style=\"background:".concat(el.color, ";\"></span> ").concat(el.seriesName, " : ").concat(el.value[1], "  ").concat(el.dimensionNames[0] === "x" ? "" : el.dimensionNames[0]);
                });
                return str;
              }
            }
          },
          color: this.colors,
          toolbox: {
            show: true,
            itemSize: 18,
            top: "6%",
            right: "7%",
            feature: {
              /* line是折线图，bar是柱形图*/
              magicType: {
                //动态类型切换
                show: true,
                //是否显示该工具
                type: ['line', 'bar'],
                //启用的动态类型
                title: {
                  line: '切换为折线图',
                  bar: '切换为柱状图'
                }
              },
              myTool1: {
                show: false,
                title: "下载报表",
                icon: "image://https://s4.aconvert.com/convert/p3r68-cdx67/agdum-o8zxo.svg",
                onclick: function onclick() {
                  _this.getHistoricalDatExportData();
                }
              }
            },
            iconStyle: {}
          },
          legend: {
            icon: "circle",
            top: "1%",
            bottom: "1%",
            width: '85%',
            type: "scroll",
            textStyle: {
              color: this.echartfc
            }
          },
          grid: {
            left: "3%",
            right: "3%",
            top: "10%",
            bottom: "10%",
            containLabel: true
          },
          dataZoom: [{
            type: "slider",
            height: 20,
            handleIcon: "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "100%",
            handleStyle: {
              borderColor: "#D2D0FF",
              shadowBlur: 4,
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowColor: "#e5e5e5"
            },
            show: true,
            // flase直接隐藏图形
            xAxisIndex: [0],
            left: "4%",
            // 滚动条靠左侧的百分比
            bottom: 15,
            start: 0,
            // 滚动条的起始位置
            end: 100 // 滚动条的截止位置（按比例分割你的柱状图x轴长度）,
            // startValue: 10, // 数据窗口范围的起始数值
            // endValue: 100
          }],
          xAxis: {
            type: "time",
            boundaryGap: true,
            show: true,
            splitLine: {
              show: false
            },
            // 去除网格线
            axisTick: {
              show: true
            },
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: function formatter(params) {
                if (params) {
                  var date = new Date(params);
                  var y = date.getFullYear();
                  var MM = date.getMonth() + 1;
                  MM = MM < 10 ? "0" + MM : MM;
                  var d = date.getDate();
                  d = d < 10 ? "0" + d : d;
                  var h = date.getHours();
                  h = h < 10 ? "0" + h : h;
                  var m = date.getMinutes();
                  m = m < 10 ? "0" + m : m;
                  var s = date.getSeconds();
                  s = s < 10 ? "0" + s : s;
                  return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
                }
              },
              textStyle: {
                color: "#bbbbbb" // 文字颜色
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#cfcfcf",
                width: 1,
                type: "solid"
              }
            }
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#cfcfcf",
                width: 1,
                type: "solid"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#bbbbbb" // 文字颜色
              }
            }
          },
          series: historicalData
        };
        lineCharts.setOption(_option);
      }
      this.$nextTick(function () {
        _this16.zoom = document.body.style.zoom;
        _this16.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    },
    // 保存选中的方案
    checkInserData: function checkInserData() {
      var _this17 = this;
      this.dataNew = [];
      this.dialogFormVisible = true;
      // 方法一
      this.form.projectFieldIds.forEach(function (data) {
        _this17.allTreeList.forEach(function (item) {
          if (data == item.id) {
            _this17.dataNew.push({
              fieldId: item.id,
              fieldMeaning: item.name
            });
          }
        });
      });
      // 方法二
      // this.form.projectFieldIds.forEach((data) => {
      //   this.treedata.forEach((item) => {
      //     item.children.forEach((m)=>{
      //       m.children.forEach((v)=>{
      //           if (data == v.id) {
      //             this.dataNew.push({
      //               fieldId: v.id,
      //               fieldMeaning: v.name,
      //             });
      //           }
      //         })
      //     }) 
      //   });
      // });
      // 方法三
      // this.recurrenceTreeData(this.treedata);
      // this.form.projectFieldIds.forEach((data) => {
      //   this.allTreeList.forEach((item) => {
      //     if (data == item.id) {
      //       this.dataNew.push({
      //         fieldId: item.id,
      //         fieldMeaning: item.name,
      //       });
      //     }
      //   });
      // });
    },
    // 封装一个递归的方法  获取到所有树状的结构
    recurrenceTreeData: function recurrenceTreeData(datas) {
      var _this18 = this;
      datas.forEach(function (item) {
        if (item.children && item.children.length) {
          _this18.recurrenceTreeData(item.children);
        } else {
          _this18.allTreeList.push({
            id: item.id,
            name: item.name
          });
        }
      });
    },
    getPlanData: function getPlanData() {
      var _this19 = this;
      var params = {
        userId: (0, _auth.getUserId)(),
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataQuery.getProjectPlan)(params).then(function (res) {
        _this19.selectDataList = res;
      });
    },
    // 点击查询列表删除按钮
    delPlanValue: function delPlanValue(val) {
      var _this20 = this;
      this.$confirm('此操作将永久删除该收藏方案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this20.$message({
          type: 'success',
          message: '删除成功!'
        });
        var params = {
          id: val
        };
        (0, _dataQuery.delProjectPlan)(params).then(function (res) {
          // 删除成功 重新掉查询方案的接口
          _this20.getPlanData();
        });
      }).catch(function () {
        _this20.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 保存方案
    insertOrUpdateProjectPlanData: function insertOrUpdateProjectPlanData() {
      var _this21 = this;
      if (this.planName == "") {
        this.$message({
          message: "方案名不能为空！",
          type: "warning"
        });
        return;
      }
      var energyProjectPlanFieldListData = [];
      var energyProjectPlanSystemListData = [];
      var energyProjectPlanDeviceListData = [];
      this.queryForm.projectSystemValue.forEach(function (v, i) {
        _this21.projectSystemData.forEach(function (data1) {
          if (v == data1.id) {
            var str = {
              systemId: data1.id,
              systemName: data1.projectSystemName
            };
            energyProjectPlanSystemListData.push(str);
          }
        });
      });
      this.queryOneForm.projectSystemDeviceValue.forEach(function (v, i) {
        _this21.projectSystemDeviceData.forEach(function (data1) {
          if (v == "all") {
            var str = {
              deviceId: data1.id,
              deviceName: data1.systemDeviceName
            };
            energyProjectPlanDeviceListData.push(str);
          }
          if (v == data1.id) {
            var _str = {
              deviceId: data1.id,
              deviceName: data1.systemDeviceName
            };
            energyProjectPlanDeviceListData.push(_str);
          }
        });
      });
      this.dataNew.forEach(function (v, i) {
        var str = {
          fieldId: v.fieldId,
          fieldName: v.fieldMeaning
        };
        energyProjectPlanFieldListData.push(str);
      });
      if (this.planId != null) {
        var params = {
          id: this.planId,
          userId: (0, _auth.getUserId)(),
          projectId: this.$store.state.project.projectInfo.id,
          name: this.planName,
          energyProjectPlanFieldList: energyProjectPlanFieldListData,
          energyProjectPlanSystemList: energyProjectPlanSystemListData,
          energyProjectPlanDeviceList: energyProjectPlanDeviceListData
        };
        this.$confirm("查询已存在，是否更新?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          (0, _dataQuery.insertOrUpdateProjectPlan)(params).then(function (res) {
            _this21.$message({
              type: "success",
              message: "更新成功!"
            });
            _this21.getPlanData();
            _this21.value = _this21.planName;
            _this21.dialogFormVisible = false;
          });
        }).catch(function () {
          _this21.$message({
            type: "info",
            message: "已取消"
          });
        });
      } else {
        var _params = {
          userId: (0, _auth.getUserId)(),
          projectId: this.$store.state.project.projectInfo.id,
          name: this.planName,
          energyProjectPlanFieldList: energyProjectPlanFieldListData,
          energyProjectPlanSystemList: energyProjectPlanSystemListData,
          energyProjectPlanDeviceList: energyProjectPlanDeviceListData
        };
        (0, _dataQuery.insertOrUpdateProjectPlan)(_params).then(function (res) {
          _this21.$message({
            type: "success",
            message: "新增成功!"
          });
          _this21.getPlanData();
          _this21.dialogFormVisible = false;
        });
      }
    }
  }
};