"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _middleUtil = _interopRequireDefault(require("@/utils/middleUtil.js"));
var _head = _interopRequireDefault(require("@/components/head"));
var _calendar = _interopRequireDefault(require("./calendar/calendar"));
var _dataAnalysis = require("@/api/dataAnalysis");
var _runType = require("@/api/runType");
var _dayjs = _interopRequireDefault(require("dayjs"));
var _default = exports.default = {
  components: {
    headr: _head.default,
    calendar: _calendar.default
  },
  data: function data() {
    return {
      loading: true,
      currentRow: null,
      tableData: [],
      historyTableData: [],
      isShow: true,
      monthData: "",
      dayData: (0, _dayjs.default)().format("YYYY-MM-DD"),
      calendar: (0, _dayjs.default)().format("YYYY-MM"),
      chartData: [],
      zj: [],
      ldb: [],
      lqb: [],
      lqt: [],
      thisIndex: [],
      consumptionCalendarData: [],
      pathData: [],
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      expireTimeOPtion: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的 
        }
      },
      variableHeight: null
    };
  },
  computed: {},
  created: function created() {
    // 要做判断处理，是否为蓄冷页面
    // const arr = JSON.parse(window.sessionStorage.getItem("menuAuths")) || [];
    // this.pathData = arr.map((e) => {
    //   return e.path;
    // });
    // if (this.pathData.indexOf("home-page") !== 0) {
    //   this.$router.push({ path: "/403" });
    //   return;
    // }

    this.fetchData();
  },
  methods: {
    getHeight: function getHeight() {
      var height = window.screen.height * window.devicePixelRatio;
      if (height >= 2160) {
        this.variableHeight = 830;
      } else if (height >= 1800) {
        this.variableHeight = 840;
      } else if (height >= 1600) {
        this.variableHeight = 925;
      } else if (height >= 1440) {
        this.variableHeight = 805;
      } else if (height >= 1000) {
        this.variableHeight = 720;
      } else if (height >= 800) {
        this.variableHeight = 970;
      } else if (height >= 700) {
        this.variableHeight = 670;
      } else {
        this.variableHeight = 600;
      }
    },
    rowClass: function rowClass(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (this.thisIndex.includes(rowIndex)) {
        return "success-row";
      }
      return "";
    },
    fetchData: function fetchData(time) {
      var _this2 = this;
      if (time == null) {
        time = (0, _dayjs.default)().format("YYYY-MM-DD");
      } else {
        time = (0, _dayjs.default)(time).format("YYYY-MM-DD");
      }
      this.loading = true;
      (0, _runType.getRunType)(this.$store.state.project.projectInfo.id, time).then(function (res) {
        _this2.historyTableData = [];
        _this2.tableData = res;
        _this2.loading = false;
        _this2.historyTableData = _this2.tableData[_this2.tableData.length - 1].runTypeHistoryDTOS;
        _this2.$refs.singleTable.setCurrentRow(_this2.tableData[_this2.tableData.length - 1]);
        //选中
        _this2.thisIndex = [];
        for (var i = 0; i < _this2.tableData.length; i++) {
          if (_this2.tableData[i].batchId != null && _this2.tableData[i].batchId != "" && _this2.tableData[i].batchId === _this2.tableData[_this2.tableData.length - 1].batchId) {
            _this2.thisIndex.push(i);
          }
        }
        _this2.zj = [];
        _this2.ldb = [], _this2.lqb = [], _this2.lqt = [], _this2.historyTableData.forEach(function (data) {
          if (data.runTypeSortType == 0) {
            _this2.zj.push(data);
          } else if (data.runTypeSortType == 1) {
            _this2.ldb.push(data);
          } else if (data.runTypeSortType == 2) {
            _this2.lqb.push(data);
          } else if (data.runTypeSortType == 3) {
            _this2.lqt.push(data);
          }
        });
      });
    },
    changeHistory: function changeHistory(event) {
      var _this3 = this;
      this.zj = [];
      this.ldb = [], this.lqb = [], this.lqt = [], this.historyTableData = event.runTypeHistoryDTOS;
      //选中
      this.thisIndex = [];
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].batchId != null && this.tableData[i].batchId != "" && this.tableData[i].batchId === event.batchId) {
          this.thisIndex.push(i);
        }
      }
      this.historyTableData.forEach(function (data) {
        if (data.runTypeSortType == 0) {
          _this3.zj.push(data);
        } else if (data.runTypeSortType == 1) {
          _this3.ldb.push(data);
        } else if (data.runTypeSortType == 2) {
          _this3.lqb.push(data);
        } else if (data.runTypeSortType == 3) {
          _this3.lqt.push(data);
        }
      });
    },
    getEnergyConsumptionCalendarData: function getEnergyConsumptionCalendarData() {
      var _this4 = this;
      var params = {
        timeDate: this.calendar,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.getEnergyConsumptionCalendar)(params).then(function (res) {
        _this4.consumptionCalendarData = res;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;
    _middleUtil.default.$on("fetchData", function (id, time) {
      _this.fetchData(id, time);
    });
    this.getHeight();
  }
};