"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    show: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      popShow: false,
      positionStyle: '',
      type: 0
    };
  },
  watch: {
    data: function data() {
      this.presentStyle();
      this.practicalStyle();
    }
  },
  created: function created() {},
  methods: {
    leave: function leave() {
      this.popShow = false;
    },
    enter: function enter(val) {
      this.type = val;
      this.popShow = true;
    },
    mouseOver: function mouseOver(event) {
      var x = event.pageX;
      var y = event.pageY;
      this.positionStyle = {
        top: y - 50 + 'px',
        left: x + 'px'
      };
    },
    presentStyle: function presentStyle() {
      if (this.data[1] > this.data[0]) {
        return 'width:80%';
      } else {
        var str = this.data[1] / this.data[0] * 80;
        return "width:".concat(str, "%");
      }
    },
    practicalStyle: function practicalStyle() {
      if (this.data[0] > this.data[1]) {
        return 'width:80%';
      } else {
        var str = this.data[0] / this.data[1] * 80;
        return "width:".concat(str, "%");
      }
    }
  }
};