"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _home = require("@/api/home");
var _default2 = exports.default = {
  props: {
    facilityData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      efficiencyRiseList: [],
      facilityList: [],
      equipmentData: []
    };
  },
  watch: {
    facilityData: function facilityData(val) {
      this.facilityList = val.map(function (item) {
        return [].concat((0, _toConsumableArray2.default)(Object.keys(item)), (0, _toConsumableArray2.default)(Object.values.apply(Object, (0, _toConsumableArray2.default)(Object.values(item)))));
      });
    }
  },
  created: function created() {
    this.getProjectEueepData();
    this.getEfficiencyRiseData();
  },
  methods: {
    // 获取能效数据
    getProjectEueepData: function getProjectEueepData() {
      var _this = this;
      var params = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _home.getProjectEueep)(params).then(function (res) {
        _this.equipmentData = res;
      });
    },
    // 获取能效抬头
    getEfficiencyRiseData: function getEfficiencyRiseData() {
      var _this2 = this;
      var params = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _home.getEfficiencyRise)(params).then(function (res) {
        //console.log(res)
        _this2.efficiencyRiseList = res;
      });
    }
  }
};