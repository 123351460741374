"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-right"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("headr")], 1), _vm._v(" "), _c("div", {
    staticClass: "right-box",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("el-row", {
    staticClass: "el-row",
    attrs: {
      gutter: 24,
      type: "flex"
    }
  }, _vm._l(this.skinList, function (o, index) {
    return _c("el-col", {
      key: index,
      staticClass: "el-col",
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      key: index,
      staticClass: "el-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", [_vm._v(_vm._s(o.name))])]), _vm._v(" "), _c("div", {
      staticClass: "skin",
      staticStyle: {
        cursor: "pointer"
      },
      style: {
        background: o.color
      },
      on: {
        click: function click($event) {
          return _vm.checkSkin(o.color);
        }
      }
    }), _vm._v(" "), _c("div", [_c("div", {
      staticClass: "bottom clearfix"
    }, [_c("el-button", {
      staticClass: "button",
      attrs: {
        type: "text"
      },
      on: {
        click: function click($event) {
          return _vm.updateSkinData(o.skin);
        }
      }
    }, [_vm._v("使用它")])], 1)])])], 1);
  }), 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;