"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _home = require("@/api/home");
var _auth = require("@/utils/auth");
// import Cookies from 'js-cookie'
//在app.vue或者其它全局的文件中引入函数
// import DevicePixelRatio from "@/utils/zoom";
var _default = exports.default = {
  name: "App",
  computed: {
    // 注入所属layout 第一次进来可能取不到值默认使用常规的
    layout: function layout() {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout + "-layout";
      } else {
        return "layout";
      }
    }
  },
  created: function created() {
    if (window.screen.availWidth < 1600) {
      document.body.style.minWidth = 0;
    }
    this.getSystemNameByUseData();
    // new DevicePixelRatio().init();
  },
  mounted: function mounted() {
    /* 区分关闭和刷新，关闭退出登录 start*/
    window.onload = function () {
      if (!window.sessionStorage["ISlogin"]) {
        // Cookies.remove('Admin-Token')
        // location.reload() // 不能省，强制跳到登陆页
      } else {
        window.sessionStorage.removeItem("ISlogin");
      }
    };
    window.onunload = function () {
      window.sessionStorage["ISlogin"] = true;
    };
    window.onbeforeunload = function () {
      window.sessionStorage["ISlogin"] = true;
    };
  },
  methods: {
    // 获取系统名称
    getSystemNameByUseData: function getSystemNameByUseData() {
      (0, _home.getSystemNameByUse)().then(function (res) {
        // 使用动态的
        // document.title = res ? res.systemName : "机房管理系统";
        // 使用默认的
        document.title = "机房管理系统";
      });
    }
  }
};