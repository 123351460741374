"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _projectInfo = require("@/api/projectInfo");
var _home = require("@/api/home");
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      params: {
        rows: 10,
        page: 1,
        alarmType: "",
        //报警类型（报警等级）：1提醒2预警3报警
        endDate: "",
        //结束始时间
        everconfirmed: "",
        //是否确认 0.未确认 1.已确认 默认0
        projectId: this.$store.state.project.projectInfo.id,
        id: this.$store.state.project.projectInfo.id,
        startDate: "" //开始时间
      },
      total: 0,
      tableData: [],
      timeId: null
    };
  },
  created: function created() {
    this.getProjectAlarm();
  },
  watch: {
    total: function total(val) {
      if (val > 0) {
        this.startScroll(); //轮播
      }
    }
  },
  //方法集合
  methods: {
    //报警表格
    getProjectAlarm: function getProjectAlarm() {
      var ts = this;
      ts.params.startDate = ts.startDate ? ts.startDate + " 00:00:00" : "";
      ts.params.endDate = ts.endDate ? ts.endDate + " 23:59:59" : "";
      (0, _projectInfo.getProjectAlarm)(ts.params).then(function (res) {
        ts.tableData = res.rows;
        ts.total = res.total;
      });
    },
    batchUpdateProjectAlarmState: function batchUpdateProjectAlarmState(val) {
      var ts = this;
      var par = {
        ids: []
      };
      if (val) {
        par.ids.push(val);
      }
      (0, _home.batchUpdateProjectAlarmState)(par).then(function (res) {
        ts.getProjectAlarm();
      });
    },
    // 轮播
    startScroll: function startScroll() {
      var _this = this;
      var table = this.$refs.table;
      var divData = table.bodyWrapper;
      this.timeId = setInterval(function () {
        divData.scrollTop += 1; // 增加滚动步进值
        if (divData.clientHeight + divData.scrollTop >= divData.scrollHeight - 1) {
          clearInterval(_this.timeId);
          var currentScrollTop = divData.scrollTop;
          var scrollDuration = 500; // 滚动动画持续时间（毫秒）
          var startTime = performance.now();
          var _scrollAnimation = function scrollAnimation() {
            var currentTime = performance.now();
            var progress = Math.min((currentTime - startTime) / scrollDuration, 1);
            divData.scrollTop = currentScrollTop - currentScrollTop * progress; // 平滑递减至0

            if (progress < 1) {
              requestAnimationFrame(_scrollAnimation);
            } else {
              divData.scrollTop = 0; // 完成动画后重置为0
              _this.startScroll(); // 重新开始滚动
            }
          };
          _scrollAnimation();
        }
      }, 100); // 调整滚动间隔
    },
    mouseEnter: function mouseEnter() {
      //鼠标移入清除定时器（暂停轮播）
      clearInterval(this.timeId);
    },
    mouseLeave: function mouseLeave() {
      //鼠标移出继续执行轮播
      this.startScroll();
    }
  },
  destroyed: function destroyed() {
    clearInterval(this.timeId);
  }
};