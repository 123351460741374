"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-right active"
  }, [_c("div", {
    staticClass: "content-box"
  }, [_c("div", {
    staticClass: "item-box border-box"
  }, [_c("theme", {
    attrs: {
      "theme-data": _vm.homeData[0]
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "item-box border-box"
  }, [_c("energy", {
    attrs: {
      "energy-data": _vm.homeData[1]
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "item-box"
  }, [_c("chart", {
    attrs: {
      "chart-data": _vm.homeData[2]
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "item-box border-box"
  }, [_c("system", {
    attrs: {
      "system-data": _vm.homeData[3],
      computer1: _vm.homeData[6],
      computer2: _vm.homeData[7]
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "item-box border-box"
  }, [_c("facility", {
    attrs: {
      "facility-data": _vm.homeData[4]
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "item-box"
  }, [_c("pie", {
    attrs: {
      "pie-data": _vm.homeData[5]
    }
  })], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;