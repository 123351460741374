"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    // eslint-disable-next-line vue/require-default-prop
    resultTitle: String
  },
  data: function data() {
    return {
      resultModel: {
        desc: "",
        uploadFiles: []
      }
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};