"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: "YearRangePicker",
  // 接收父组件传入的数据
  props: {
    yearRange: {
      type: Array,
      required: true
    },
    is_disabled: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data: function data() {
    return {
      startYear: "",
      endYear: ""
      // startDatePicker: this.beginDate(),
      // endDatePicker: this.processDate(),
    };
  },
  mounted: function mounted() {
    // 初始化自身变量
    this.startYear = String(this.yearRange[0]);
    this.endYear = String(this.yearRange[1]);
  },
  methods: {
    changeStartYear: function changeStartYear(val) {
      // 开始年份大于结束年份，调换
      if (Number(this.startYear) > Number(this.endYear)) {
        this.startYear = this.endYear;
        this.endYear = val;
      }
      // 将改动传回父组件
      this.$emit("yearChanged", [Number(this.startYear), Number(this.endYear)]);
    },
    changeEndYear: function changeEndYear(val) {
      if (Number(this.startYear) > Number(this.endYear)) {
        this.endYear = this.startYear;
        this.startYear = val;
      }
      this.$emit("yearChanged", [Number(this.startYear), Number(this.endYear)]);
    },
    // 提出开始时间必须小于提出结束时间
    beginDate: function beginDate() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.startYear !== '') {
            var fixedTime = new Date(time);
            return fixedTime.getFullYear() > self.startYear;
          }
        }
      };
    },
    // 提出结束时间必须大于提出开始时间
    processDate: function processDate() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          var fixedTime = new Date(time);
          return fixedTime.getFullYear() < self.startYear;
        }
      };
    }
  }
};