"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var THREE = _interopRequireWildcard(require("three"));
var _camera = _interopRequireDefault(require("./camera"));
var _renderer = _interopRequireDefault(require("./renderer"));
var _controls = _interopRequireDefault(require("./controls"));
var _scene = _interopRequireDefault(require("./scene"));
var _createMesh = require("@/three/createMesh");
var _gsap = _interopRequireDefault(require("gsap"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var clock = new THREE.Clock();
function animate(t) {
  var time = clock.getDelta();
  // 调用旋转函数，旋转相机而非模型

  _controls.default.controls.update(time);
  (0, _createMesh.updateMesh)(time);
  requestAnimationFrame(animate);
  // 使用渲染器渲染相机看这个场景的内容渲染出来
  _renderer.default.renderer.render(_scene.default, _camera.default.activeCamera);
  _renderer.default.css3drender.render(_scene.default, _camera.default.activeCamera);
}
var _default = exports.default = animate;