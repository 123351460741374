"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/*
 * @Author: your name
 * @Date: 2020-11-24 15:52:23
 * @LastEditTime: 2021-06-09 10:24:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yiLong\src\store\getters.js
 */
var getters = {
  token: function token(state) {
    return state.user.token;
  },
  isCold: function isCold(state) {
    return state.project.isCold;
  },
  type: function type(state) {
    return state.project.type;
  },
  colorType: function colorType(state) {
    return state.project.colorType;
  },
  projectInfo: function projectInfo(state) {
    return state.project.projectInfo;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  }
};
var _default = exports.default = getters;