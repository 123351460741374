"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "tabs"
  }, _vm._l(_vm.tablist, function (item, index) {
    return _c("div", {
      staticClass: "parallelogram",
      class: _vm.currentIndex == index ? "isActive" : "",
      on: {
        key: index,
        click: function click($event) {
          return _vm.go(index);
        }
      }
    }, [_c("span", [_vm._v(_vm._s(item.name))])]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;