"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataAnalysis = require("@/api/dataAnalysis");
var _dayjs = _interopRequireDefault(require("dayjs"));
var _default2 = exports.default = {
  props: {
    systemData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    computer: {
      type: Array,
      default: function _default() {
        return [{
          computerRoomValue: 0
        }];
      }
    }
  },
  data: function data() {
    return {
      dayData: (0, _dayjs.default)().format("YYYY-MM-DD"),
      // 所有数据
      chartData: [],
      // 只要第一层数据就行
      firstChartData: [],
      color: "",
      colors: ["#3e89e7", "#fb8c3b", "#63be65", "#d9c32b"],
      echartfc: '#fff' // 图表颜色
    };
  },
  mounted: function mounted() {
    this.getDayHistoryAnalysisData();
  },
  methods: {
    getDayHistoryAnalysisData: function getDayHistoryAnalysisData() {
      var _this = this;
      this.chartData = [];
      this.firstChartData = [];
      var params = {
        timeDate: this.dayData,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.getDayHistoryAnalysis)(params).then(function (res) {
        //  
        if (JSON.stringify(res.data) === "{}") {
          _this.chartData = [{
            name: "关键参数",
            chartData: []
          }, {
            name: "冷冻泵输送系数",
            chartData: []
          }, {
            name: "冷却泵输送系数",
            chartData: []
          }, {
            name: "冷却塔系统能效",
            chartData: []
          }];
          _this.firstChartData = [];
        } else {
          var name = "";
          res.map(function (e, i) {
            e.map(function (m) {
              if (m.analysisType == 1) {
                name = "关键参数";
              } else if (m.analysisType == 2) {
                name = "冷冻泵输送系数";
              } else if (m.analysisType == 3) {
                name = "冷却泵输送系数";
              } else {
                name = "冷却塔系统能效";
              }
            });
            _this.chartData.push({
              name: name,
              chartData: e
            });
          });
          _this.firstChartData = _this.chartData[0].chartData;
          _this.getLineChart();
        }
      });
    },
    getLineChart: function getLineChart() {
      var lineChart = this.$echarts.init(document.getElementById('realLineEcharts'));
      var seriesData = [];
      lineChart.clear();
      this.firstChartData.map(function (el, i) {
        seriesData.push({
          type: "line",
          name: el.fieldMeaning,
          data: el.data,
          symbol: "circle",
          showSymbol: false,
          dimensions: [el.company // 如果此维度不想给出定义，则使用 null 即可
          ],
          smooth: true
          // 折线图堆叠
          // smooth: true,
          // stack: 'Total',
          // areaStyle: {},
          // emphasis: {
          //   focus: 'series'
          // },
        });
      });
      var option = {
        tooltip: {
          confine: true,
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,1)",
          extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#000000",
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          },
          padding: 15,
          formatter: function formatter(params) {
            var str = "";
            str = params[0].name;
            params.map(function (el) {
              str = str + "<br/><span class=\"color-circle\" style=\"background:".concat(el.color, ";\"></span> ").concat(el.seriesName, " :&nbsp;&nbsp;").concat(el.value[1], "  ").concat(el.dimensionNames[0] === "x" ? "" : el.dimensionNames[0]);
            });
            return str;
          }
        },
        color: this.colors,
        // legend: {
        //   icon: "circle",
        //   textStyle: {
        //     color: this.echartfc,
        //   }
        // },
        grid: {
          left: "3%",
          right: "7%",
          bottom: "6%",
          top: "8%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          name: "(h)",
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 1,
              type: "solid"
            }
          },
          boundaryGap: false,
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: function formatter(params) {
              var date = new Date(params);
              var hours = date.getHours().toString().padStart(2, '0');
              var minutes = date.getMinutes().toString().padStart(2, '0');
              var seconds = date.getSeconds().toString().padStart(2, '0');
              return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
              // var newParamsName = "";
              // var paramsNameNumber = params.length;
              // var provideNumber = 10; // 一行显示几个字
              // var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              // if (paramsNameNumber > provideNumber) {
              //   for (var p = 0; p < rowNumber; p++) {
              //     var tempStr = "";
              //     var start = p * provideNumber;
              //     var end = start + provideNumber;
              //     if (p === rowNumber - 1) {
              //       tempStr = params.substring(start, paramsNameNumber);
              //     } else {
              //       tempStr = params.substring(start, end) + "\n";
              //     }
              //     newParamsName += tempStr;
              //   }
              // } else {
              //   newParamsName = params;
              // }
              // return newParamsName;
            },
            textStyle: {
              color: "#fff" // 文字颜色
            }
          }
        },
        yAxis: {
          type: "value",
          color: "red",
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 1,
              type: "solid"
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff" // 文字颜色
            }
          }
        },
        label: {
          color: this.echartfc
        },
        series: seriesData
      };
      lineChart.setOption(option);
    }
  }
};