"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "sceneRef",
    staticClass: "scene",
    attrs: {
      id: "scene"
    }
  }, [_vm.loading ? _c("dv-loading", {
    staticClass: "custom-loading"
  }, [_vm._v("正在加载模型，请稍后... ")]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;