"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "theme-box"
  }, [_c("div", {
    staticClass: "content-box"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("系统实时参数")]), _vm._v(" "), _c("div", {
    staticClass: "specific-box"
  }, [_c("div", {
    attrs: {
      id: "realLineEcharts"
    }
  })])])]);
}];
render._withStripped = true;