"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "theme-box"
  }, [_c("div", {
    staticClass: "content-box"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("系统实时参数")]), _vm._v(" "), _c("div", {
    staticClass: "specific-box"
  }, [_c("div", {
    staticClass: "item-box"
  }, [_vm.getComputerValue > 0 && this.colorType && this.colorType == "theme_default" ? _c("img", {
    staticClass: "img",
    attrs: {
      src: require("../../../assets/svg/bai.gif"),
      alt: ""
    }
  }) : _vm.getComputerValue > 0 && this.colorType && this.colorType == "theme_jyh" ? _c("img", {
    staticClass: "img",
    attrs: {
      src: require("../../../assets/svg/hei.gif"),
      alt: ""
    }
  }) : _vm.getComputerValue > 0 && this.colorType && this.colorType == "theme_ytl" ? _c("img", {
    staticClass: "img",
    attrs: {
      src: require("../../../assets/svg/aoc1.gif"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "img",
    attrs: {
      src: require("../../../assets/icon/off.png"),
      alt: ""
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "sub-title",
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_vm._v("主机开关状态")])]), _vm._v(" "), _vm._l(_vm.parmsData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item-box"
    }, [_c("div", {
      staticClass: "t-mun"
    }, [_vm._v(_vm._s(item.value[0])), _c("span", {
      staticClass: "symbol"
    }, [_vm._v(_vm._s(item.value[1]))])]), _vm._v(" "), _c("div", {
      staticClass: "sub-title"
    }, [_vm._v(_vm._s(item.name) + " ")])]);
  })], 2)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;