"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteProjectInfo = deleteProjectInfo;
exports.energySavedHistoryCarbonEmission = energySavedHistoryCarbonEmission;
exports.energySavedHistoryData = energySavedHistoryData;
exports.energySavedHistoryStatistics = energySavedHistoryStatistics;
exports.energySavedHistoryTrend = energySavedHistoryTrend;
exports.getAPPLoginImg = getAPPLoginImg;
exports.getColdPriceInfo = getColdPriceInfo;
exports.getConsumptionBatch = getConsumptionBatch;
exports.getElectrovalence = getElectrovalence;
exports.getLogoImg = getLogoImg;
exports.getManualProjectRunDiagnosticData = getManualProjectRunDiagnosticData;
exports.getManualProjectRunDiagnosticDataExport = getManualProjectRunDiagnosticDataExport;
exports.getManualProjectRunDiagnosticHistoryDataExport = getManualProjectRunDiagnosticHistoryDataExport;
exports.getPCLoginImg = getPCLoginImg;
exports.getProjectAlarm = getProjectAlarm;
exports.getProjectAlarmCount = getProjectAlarmCount;
exports.getProjectAlarmLevel = getProjectAlarmLevel;
exports.getProjectAlarmStatistics = getProjectAlarmStatistics;
exports.getProjectEliminate = getProjectEliminate;
exports.getProjectInfo = getProjectInfo;
exports.getProjectRegionList = getProjectRegionList;
exports.getProjectRunDiagnosticHistory = getProjectRunDiagnosticHistory;
exports.getSystemNameByUse = getSystemNameByUse;
exports.getUserProject = getUserProject;
exports.postColdPriceBatch = postColdPriceBatch;
exports.postConsumptionBatch = postConsumptionBatch;
exports.postElectrovalence = postElectrovalence;
exports.postLoginImg = postLoginImg;
exports.postLogoImg = postLogoImg;
exports.postProjectInfo = postProjectInfo;
exports.putLoginImg = putLoginImg;
exports.putProjectInfo = putProjectInfo;
exports.putSystemNameByUse = putSystemNameByUse;
exports.relevanceProject = relevanceProject;
exports.relevanceProjectOrder = relevanceProjectOrder;
exports.removeLogoImg = removeLogoImg;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /*
 * @Author: your name
 * @Date: 2020-12-05 13:45:49
 * @LastEditTime: 2021-11-18 14:30:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yl-admin\src\api\projectInfo.js
 */
function getProjectInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectInfo/projectInfoPage",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function postProjectInfo(params, data) {
  var encryptType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return (0, _request.default)({
    url: "/projectInfo/insertProjectInfo",
    method: 'post',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    }),
    data: data
  });
}
function putProjectInfo(params, data) {
  var encryptType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return (0, _request.default)({
    url: "/projectInfo/updateProjectInfo",
    method: 'put',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    }),
    data: data
  });
}
function deleteProjectInfo(id) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectInfo/deleteProjectInfo/".concat(id),
    method: 'delete',
    params: {
      encryptType: encryptType
    }
  });
}

// 设置关联项目
function relevanceProject(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/insertProjectInfo",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

// 用户关联的项目信息,工单标识信息
function relevanceProjectOrder(userId) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectIdOrWorkOrderIdentListByUserId/".concat(userId),
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
function getPCLoginImg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/landingImage/energyLandingImageInfo",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
function getAPPLoginImg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/landingImage/appEnergyLandingImageInfo",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
function postLoginImg(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/landingImage/energyLandingImageInfo",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}
function putLoginImg(params, data) {
  var encryptType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return (0, _request.default)({
    url: "/landingImage/updateEnergyLandingImage",
    method: 'put',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    }),
    data: data
  });
}
function getUserProject(userId) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoListByUserId/".concat(userId),
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
function getProjectRegionList() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectRegion/projectRegionList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
function postElectrovalence(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectElectFee/insertEnergyProjectElectFeeBatch",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}
function getElectrovalence(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectElectFee/energyProjectElectFeeList",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 能耗区间新增--常规/蓄冷
function postConsumptionBatch(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectConsumption/insertProjectConsumptionBatch",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}
function postColdPriceBatch(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectColdPrice/insertProjectColdPriceBatch",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

// 能耗区间获取--常规/蓄冷
function getConsumptionBatch(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectConsumption/projectConsumptionList",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getColdPriceInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectColdPrice/projectColdPriceInfo",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//logo维护

function getLogoImg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectLogoImage/projectLogoImageByProjectId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function postLogoImg(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectLogoImage/insertProjectLogoImage",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}
function removeLogoImg(id) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectLogoImage/deleteProjectLogoImage/".concat(id),
    method: 'delete',
    params: {
      encryptType: encryptType
    }
  });
}
function getSystemNameByUse() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/systemName/systemNameByUser",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
function putSystemNameByUse(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/systemName/updateSystemName",
    method: 'put',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

//告警表格展示
function getProjectAlarm(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectAlarm/getProjectAlarm",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//告警数量
function getProjectAlarmCount(params) {
  return (0, _request.default)({
    url: "/projectAlarm/getProjectAlarmCount",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

//告警统计
function getProjectAlarmStatistics(params) {
  return (0, _request.default)({
    url: "/projectAlarm/getProjectAlarmStatistics",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

//告警等级
function getProjectAlarmLevel(params) {
  return (0, _request.default)({
    url: "/projectAlarm/getProjectAlarmLevel",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

//告警消除
function getProjectEliminate(params) {
  return (0, _request.default)({
    url: "/projectAlarm/getProjectEliminate",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

//运行诊断查询（前台根据时间查询）
function getProjectRunDiagnosticHistory(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectRunDiagnostic/getProjectRunDiagnosticHistory",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//运行诊断历史下载
function getManualProjectRunDiagnosticHistoryDataExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectRunDiagnostic/getManualProjectRunDiagnosticHistoryDataExport",
    method: 'get',
    responseType: 'blob',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//手动运行诊断
function getManualProjectRunDiagnosticData(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectRunDiagnostic/getManualProjectRunDiagnosticData",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//手动运行诊断下载
function getManualProjectRunDiagnosticDataExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectRunDiagnostic/getManualProjectRunDiagnosticDataExport",
    method: 'get',
    responseType: 'blob',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

//节能量统计+累计减少排放量
function energySavedHistoryStatistics(params) {
  return (0, _request.default)({
    url: "/energySavedHistory/energySavedHistoryStatistics",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

//减少二氧化碳排放
function energySavedHistoryCarbonEmission(params) {
  return (0, _request.default)({
    url: "/energySavedHistory/energySavedHistoryCarbonEmission",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

//节能量趋势
function energySavedHistoryTrend(params) {
  return (0, _request.default)({
    url: "/energySavedHistory/energySavedHistoryTrend",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

//节能量数据
function energySavedHistoryData(params) {
  return (0, _request.default)({
    url: "/energySavedHistory/energySavedHistoryData",
    method: 'get',
    params: _objectSpread({}, params)
  });
}