"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delDateDeviceManager = delDateDeviceManager;
exports.downloadTemplate = downloadTemplate;
exports.getDeviceManager = getDeviceManager;
exports.importData = importData;
exports.insertOrUpdateDeviceManager = insertOrUpdateDeviceManager;
exports.updateStatus = updateStatus;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// 设备管理模板查询
function getDeviceManager(params) {
  return (0, _request.default)({
    url: "/deviceManager/getDeviceManager",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

// 设备管理模板下载
function downloadTemplate(params) {
  return (0, _request.default)({
    url: "/deviceManager/downloadTemplate",
    method: 'get',
    responseType: 'blob',
    params: _objectSpread({}, params)
  });
}

// 设备管理新增修改
function insertOrUpdateDeviceManager(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/deviceManager/insertOrUpdateDeviceManager",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: params
  });
}

// 设备管理模板查询批量导入
function importData(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/deviceManager/importData",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: params
  });
}

//设备管理批量删除
function delDateDeviceManager(params) {
  return (0, _request.default)({
    url: "/deviceManager/delDateDeviceManager",
    method: 'delete',
    params: _objectSpread({}, params)
  });
}

// 设备管理修改状态
function updateStatus(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/deviceManager/updateStatus",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: params
  });
}