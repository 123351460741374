"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/*
 * @Author: your name
 * @Date: 2020-12-09 15:35:38
 * @LastEditTime: 2021-06-09 18:15:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yiLong\src\store\modules\project.js
 */
var projectInfo = JSON.parse(sessionStorage.getItem('projectInfo'));
var skin = localStorage.getItem('skin');
var state = {
  isCold: null,
  type: null,
  projectInfo: projectInfo,
  colorType: skin,
  projectType: null //0常规 1蓄冷
};
var mutations = {
  SET_PROJECT_Cold: function SET_PROJECT_Cold(state, isCold) {
    state.isCold = isCold;
  },
  SET_COLOR_TYPE: function SET_COLOR_TYPE(state, type) {
    state.colorType = type;
  },
  SET_PROJECT_INFO: function SET_PROJECT_INFO(state, projectInfo) {
    state.projectInfo = projectInfo;
    sessionStorage.setItem('projectInfo', JSON.stringify(projectInfo));
  },
  SET_PROJECT_TYPE: function SET_PROJECT_TYPE(state, projectType) {
    state.projectType = projectType;
    sessionStorage.setItem('projectType', projectType);
  }
};
var actions = {
  setProjectCold: function setProjectCold(_ref, cold) {
    var commit = _ref.commit;
    commit('SET_PROJECT_Cold', cold);
  },
  setColorType: function setColorType(_ref2, type) {
    var commit = _ref2.commit;
    commit('SET_COLOR_TYPE', type);
  },
  setProject: function setProject(_ref3, projectInfo) {
    var commit = _ref3.commit;
    commit('SET_PROJECT_INFO', projectInfo);
  },
  setProjectType: function setProjectType(_ref4, projectType) {
    var commit = _ref4.commit;
    commit('SET_PROJECT_TYPE', projectType);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};