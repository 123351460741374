"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-right"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("headr")], 1), _vm._v(" "), _c("div", {
    staticClass: "right-box"
  }, [_c("iframe", {
    ref: "iframe",
    attrs: {
      id: "bdIframe",
      src: _vm.srcLink,
      frameborder: "0",
      scrolling: "yes"
    }
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;