"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAnalysisDataByDay = getAnalysisDataByDay;
exports.getAnalysisDataByMonth = getAnalysisDataByMonth;
exports.getColdConsumptionCalendar = getColdConsumptionCalendar;
exports.getColdPrice = getColdPrice;
exports.getConsumptionBatch = getConsumptionBatch;
exports.getDayHistoryAnalysis = getDayHistoryAnalysis;
exports.getEnergyConsumptionCalendar = getEnergyConsumptionCalendar;
exports.getMonthHistoryAnalysis = getMonthHistoryAnalysis;
exports.historyAnalysisType = historyAnalysisType;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /*
 * @Author: your name
 * @Date: 2020-12-18 10:46:10
 * @LastEditTime: 2021-12-03 16:27:43
 * @LastEditors: mxz
 * @Description: In User Settings Edit
 * @FilePath: \yilong\src\api\dataAnalysis.js
 * 
 */
// 日折线图——常规、蓄冷
function getDayHistoryAnalysis(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/dayHistoryAnalysis",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getAnalysisDataByDay(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/analysisDataCoolStorage/analysisDataByDay",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
// 获取数据分析tab栏表头
function historyAnalysisType(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/historyAnalysisType",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 月折线图——常规、蓄冷
function getMonthHistoryAnalysis(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/monthHistoryAnalysis",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getAnalysisDataByMonth(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/analysisDataCoolStorage/monthProjectReportData",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 能效日历——常规、蓄冷
function getEnergyConsumptionCalendar(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/energyConsumptionCalendar",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getColdConsumptionCalendar(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/energyColdConsumptionCalendar",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 能效日历头——常规、蓄冷
function getConsumptionBatch(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectConsumption/projectConsumptionList",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function getColdPrice(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectColdPrice/projectColdPriceInfo",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}