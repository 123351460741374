"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "theme-box"
  }, [_c("div", {
    staticClass: "content-box",
    staticStyle: {
      "padding-bottom": "0px"
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_c("el-popover", {
    attrs: {
      placement: "top-start",
      title: "",
      width: "300",
      trigger: "click",
      content: ""
    }
  }, [_c("div", [_vm._v("\n          系统此刻瞬时运行供冷成本，当前电度电价*制冷机房设备总功率/当前总供冷负荷（电价/COP），实时冷价越低，机房运行状况越好。\n        ")]), _vm._v(" "), _c("div", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("div", {
    staticClass: "tit"
  }, [_c("div", [_vm._v("系统实时冷价")]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-info",
    staticStyle: {
      color: "#1665D8",
      "margin-left": "5px"
    }
  })])])]), _vm._v(" "), _vm.typeDesc[_vm.type] ? _c("el-popover", {
    staticStyle: {},
    attrs: {
      placement: "bottom-start",
      title: "",
      trigger: "click",
      content: ""
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("i", {
    staticClass: "el-icon-info",
    staticStyle: {
      color: "#1665D8"
    }
  }), _vm._v("\n          峰平谷分时电价是指根据电网的负荷变化情况，将每天24小时划分为高峰、平段、低谷等多个时段，对各时段分别制定不同的电价水平。\n        ")]), _vm._v(" "), _c("div", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("span", {
    staticClass: "icon",
    class: _vm.typeClass[_vm.type]
  }, [_vm._v("\n            " + _vm._s(_vm.typeDesc[_vm.type]) + "\n          ")])])]) : _vm._e()], 1), _vm._v(" "), _vm.status == -1 ? _c("div", {
    staticClass: "offline",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("img", {
    staticClass: "img",
    attrs: {
      src: require("../../../assets/offline.png"),
      alt: "",
      height: "100",
      width: "100"
    }
  })]) : _c("div", {
    staticClass: "number"
  }, [_vm._v("\n      " + _vm._s(_vm.number) + "\n    ")]), _vm._v(" "), _c("div", {
    staticClass: "con"
  }, [_vm._v("\n      元/kWh\n    ")]), _vm._v(" "), _c("div", {
    staticClass: "cont"
  }, [_vm._v(_vm._s(_vm.projectInfo.energyEfficiency))])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;