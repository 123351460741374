"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-right"
  }, [_c("div", {
    staticClass: "header"
  }), _vm._v(" "), _c("div", {
    staticClass: "error-box"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "error-desc"
  }, [_vm._v("啊哦~ 你没有权限访问该页面哦")]), _vm._v(" "), _c("div", {
    staticClass: "error-handle"
  }, [_c("router-link", {
    attrs: {
      to: "/"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "large"
    }
  }, [_vm._v("返回首页")])], 1), _vm._v(" "), _c("el-button", {
    staticClass: "error-btn",
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回上一页")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "error-code"
  }, [_vm._v("4"), _c("span", [_vm._v("0")]), _vm._v("3")]);
}];
render._withStripped = true;