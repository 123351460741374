"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "day-report"
  }, [_c("div", {
    staticClass: "calendar"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "day-header"
  }, [_c("div", {
    staticClass: "date"
  }, [_c("el-date-picker", {
    attrs: {
      type: "month",
      placeholder: "选择月",
      clearable: false,
      "value-format": "yyyy-MM",
      format: "yyyy-MM",
      "picker-options": _vm.pickerOptions0
    },
    on: {
      change: _vm.handleTime
    },
    model: {
      value: _vm.monthData,
      callback: function callback($$v) {
        _vm.monthData = $$v;
      },
      expression: "monthData"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "checkAll"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.handleCheckAll
    }
  }, [_vm._v(_vm._s(_vm.isShow ? "全选" : "反选"))])], 1), _vm._v(" "), _c("div", {
    staticClass: "date dateSel",
    staticStyle: {
      "margin-right": "25px"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      "collapse-tags": "",
      placeholder: "多选"
    },
    model: {
      value: _vm.calendarData,
      callback: function callback($$v) {
        _vm.calendarData = $$v;
      },
      expression: "calendarData"
    }
  }, _vm._l(_vm.haveData, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.$isBtnAuth("report:batchDown") && _vm.userInfo.reportDownload == 0 ? _c("el-button", {
    staticClass: "download-box",
    attrs: {
      plain: "",
      size: "small"
    },
    on: {
      click: _vm.handleBatchDown
    }
  }, [_vm._v("批量导出")]) : _vm._e()], 1), _vm._v(" "), _c("section", {
    staticClass: "left-main"
  }, [_c("div", {
    staticClass: "left-item"
  }, [_vm._m(0), _vm._v(" "), _c("el-calendar", {
    scopedSlots: _vm._u([{
      key: "dateCell",
      fn: function fn(_ref) {
        var date = _ref.date,
          data = _ref.data;
        return [data.type === "current-month" ? _c("div", {
          staticClass: "calendar-box",
          class: _vm.haveData.indexOf(data.day) != -1 ? "" : "font-active"
        }, [_c("div", {
          staticClass: "cirlce-box",
          class: [data.day === _vm.dayActive ? "active" : "", data.day === _vm.currentDay && _vm.monthData == _vm.dayjs().format("YYYY-MM") ? "currentActive" : "", _vm.calendarData.indexOf(data.day) != -1 ? "check" : ""],
          on: {
            click: function click($event) {
              _vm.handlDay(date, data, _vm.haveData.indexOf(data.day) != -1);
            }
          }
        }, [_vm._v("\n                  " + _vm._s(data.day.split("-").slice(2).join("-")) + "\n                ")])]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "left-item"
  }, [_c("div", {
    staticClass: "line-chart"
  }, [_c("div", {
    style: "position: absolute;width: 100%;height: 100%;zoom:".concat(_vm.zoomrAtio, ";transform:scale(").concat(_vm.zoom, ");transform-origin:0px 0px;"),
    attrs: {
      id: "hdl"
    }
  })]), _vm._v(" "), _c("span", {
    staticClass: "line-title"
  }, [_vm._v("耗电量（kWh） "), _c("span", [_vm._v("总：" + _vm._s(_vm.previewData.powerConsumption))])])]), _vm._v(" "), _c("div", {
    staticClass: "left-item"
  }, [_c("div", {
    staticClass: "line-chart"
  }, [_c("div", {
    style: "position: absolute;width: 100%;height: 100%;zoom:".concat(_vm.zoomrAtio, ";transform:scale(").concat(_vm.zoom, ");transform-origin:0px 0px;"),
    attrs: {
      id: "glfb"
    }
  })])]), _vm._v(" "), _c("div", {
    staticClass: "left-item"
  }, [_c("div", {
    staticClass: "check_btn"
  }, _vm._l(_vm.powerType, function (item, index) {
    return _c("span", {
      key: index,
      class: _vm.barType == index + 1 ? "sel_btn" : "",
      on: {
        click: function click($event) {
          return _vm.handelPowerType(index + 1);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "line-chart"
  }, [_c("div", {
    style: "position: absolute;width: 100%;height: 100%;zoom:".concat(_vm.zoomrAtio, ";transform:scale(").concat(_vm.zoom, ");transform-origin:0px 0px;"),
    attrs: {
      id: "zgll"
    }
  })])])])]), _vm._v(" "), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "day-header day-left",
    staticStyle: {
      width: "100%",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "checkAll"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.handlePreview
    }
  }, [_vm._v("预览")])], 1), _vm._v(" "), _vm.$isBtnAuth("report:down") && _vm.userInfo.reportDownload == 0 ? _c("el-button", {
    staticClass: "download-box",
    attrs: {
      plain: "",
      size: "small"
    },
    on: {
      click: _vm.handlOneDown
    }
  }, [_vm._v("导出")]) : _vm._e()], 1)]), _vm._v(" "), _c("section", {
    staticClass: "right-main"
  }, [_c("div", {
    staticClass: "ifrma-box"
  }, [_c("div", {
    staticClass: "img-box"
  }), _vm._v(" "), _c("div", {
    staticClass: "main-box",
    staticStyle: {
      position: "relative"
    }
  }, [_c("div", {
    staticClass: "title-box",
    staticStyle: {
      position: "absolute",
      top: "-20px",
      color: "#333333"
    }
  }, [_vm._v("\n              《供冷日报》\n              "), _c("span", {
    staticStyle: {
      display: "inline-block",
      "margin-left": "20px",
      "font-size": "14px"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.timeDate) + "\n              ")])]), _vm._v(" "), _c("div", {
    staticClass: "date-box",
    staticStyle: {
      "padding-top": "14px"
    }
  }, [_vm._v("\n              温度: "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.temperature))]), _vm._v("°C,\n              相对湿度:"), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.relativeHumidity))]), _vm._v("%,\n              湿球温度:"), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.wetBulbTemperature))]), _vm._v("°C\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              今天您机房的平均COP:\n              "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.dailyAverageCop))]), _vm._v("\n              ,\n              "), _c("el-popover", {
    attrs: {
      placement: "bottom-start",
      title: "",
      width: "300",
      trigger: "click",
      content: ""
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("\n                  通过电价谷期耗电量以及谷期电价，对比每日8-18时平均电价，计算出谷期等效经济电量，将谷期耗电量折算为谷期等效经济电量计算当日平均折算COP。\n                ")]), _vm._v(" "), _c("span", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("\n                  折算COP\n                  "), _c("i", {
    staticClass: "el-icon-info",
    staticStyle: {
      color: "#39b149"
    }
  })])]), _vm._v("\n              是:  \n              "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.conversionCop))]), _vm._v("\n              ;\n            ")], 1), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              冷价"), _c("el-popover", {
    attrs: {
      placement: "bottom-start",
      title: "",
      width: "300",
      trigger: "click",
      content: ""
    }
  }, [_c("div", [_vm._v("\n                  使用电度电费计算的供冷单价,仅供参考。\n                ")]), _vm._v(" "), _c("span", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("i", {
    staticClass: "el-icon-info",
    staticStyle: {
      color: "#39b149"
    }
  })])]), _vm._v(":\n              "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.dailyColdPrice))]), _vm._v("\n              元/千瓦时 (平均电价\n              "), _c("span", {
    staticClass: "number"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.dayElectricityPrice) + "\n              ")]), _vm._v("\n              元/度电);\n            ")], 1), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              日蓄冷量:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.dailyColdStorageCapacity)) + "\n              ")]), _vm._v("\n              kWh; 蓄冷结束时间:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v(_vm._s(_vm.previewData.coldStorageendData))]), _vm._v("\n              ;\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              总供冷量:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.coolingCapacity)) + "\n              ")]), _vm._v("\n              kWh, 峰:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.coolingCapacitySummit)) + "\n              ")]), _vm._v("\n              kWh, 平:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.coolingCapacityLevel)) + "\n              ")]), _vm._v("\n              kWh, 谷:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.coolingCapacityLowEbb)) + "\n              ")]), _vm._v("\n              kWh;\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              蓄冷槽放冷量:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorage)) + "\n              ")]), _vm._v("\n              kWh; 占:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorageProportion)) + "\n              ")]), _vm._v("\n              %,\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              其中峰:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorageSummit)) + "\n              ")]), _vm._v("\n              kWh (削峰率:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStoragePeakCutting)) + "\n              ")]), _vm._v("\n              %), 平:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorageLevel)) + "\n              ")]), _vm._v("\n              kWh, 谷:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorageLowEbb)) + "\n              ")]), _vm._v("\n              kWh;\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              总耗电量:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.powerConsumption)) + "\n              ")]), _vm._v("\n              kWh; 峰:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.powerConsumptionSummit)) + "\n              ")]), _vm._v("\n              kWh, 平:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.powerConsumptionLevel)) + "\n              ")]), _vm._v("\n              kWh, 谷:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.powerConsumptionLowEbb)) + "\n              ")]), _vm._v("\n              kWh;\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              预估电费:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.estimatedTariff)) + "\n              ")]), _vm._v("\n              元, 预计节省电费:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm._f("numFilter")(_vm.previewData.savingElectricity)) + "\n              ")]), _vm._v("\n              元;\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              供冷开始时间:\n              "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v(_vm._s(_vm.previewData.coolingStartTime))]), _vm._v("\n              , 供冷结束时间:\n              "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v(_vm._s(_vm.previewData.coolingEndTime))]), _vm._v("\n              ,共\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.coolingDuration) + "\n              ")]), _vm._v("\n              小时;\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              今天的平均冷冻供水温度:\n              "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.waterSupplyTemperature) + "\n              ")]), _vm._v("\n              °C,平均供水温差:\n              "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.waterSupplyTemperatureDifference) + "\n              ")]), _vm._v("\n              °C;\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              今天蓄冷期间平均冷却回水温度:\n              "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.coldStorageReturnWaterTemperature) + "\n              ")]), _vm._v("\n              °C,平均逼近度:\n              "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.coldStorageApproximationDegree) + "\n              ")]), _vm._v("\n              °C;\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              今天主机供冷期间平均冷却回水温度:\n              "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.hostReturnWaterTemperature) + "\n              ")]), _vm._v("\n              °C,平均逼近度:\n              "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.hostApproximationDegree) + "\n              ")]), _vm._v("\n              °C;\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n              截至今日,本机房全年综合COP值:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.yearCop) + "\n              ")]), _vm._v("\n              ,冷价:\n              "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n                " + _vm._s(_vm.previewData.yearColdPrice) + "\n              ")]), _vm._v("\n              元/kWh,加油!\n            ")])]), _vm._v(" "), _c("div", {
    staticClass: "remark"
  })])])]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      width: "30%",
      "modal-append-to-body": false,
      "show-close": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "dialog-heard"
  }, [_vm.$isBtnAuth("report:down") && _vm.userInfo.reportDownload == 0 ? _c("el-button", {
    staticClass: "download-box",
    attrs: {
      plain: "",
      size: "small"
    },
    on: {
      click: _vm.handlOneDown
    }
  }, [_vm._v("下载")]) : _vm._e(), _vm._v(" "), _c("span", {
    staticStyle: {
      position: "absolute",
      top: "15px",
      right: "30px",
      "font-weight": "bold",
      color: "red",
      "font-size": "20px",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("\n          X\n        ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "ifrma-box active-box"
  }, [_vm.reportLogoVisible == 2 ? _c("div", {
    staticClass: "img-box"
  }) : _vm._e(), _vm._v(" "), _vm.reportLogoVisible == 1 ? _c("div", {
    staticClass: "logoimg-box"
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "main-box",
    staticStyle: {
      position: "relative"
    }
  }, [_c("div", {
    staticClass: "title-box",
    staticStyle: {
      position: "absolute",
      top: "-20px",
      color: "#333333"
    }
  }, [_vm._v("\n            《供冷日报》\n            "), _c("span", {
    staticStyle: {
      display: "inline-block",
      "margin-left": "20px",
      "font-size": "14px"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.timeDate) + "\n            ")])]), _vm._v(" "), _c("div", {
    staticClass: "date-box",
    staticStyle: {
      "padding-top": "14px"
    }
  }, [_vm._v("\n            温度: "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.temperature))]), _vm._v("°C,\n            相对湿度:"), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.relativeHumidity))]), _vm._v("%,\n            湿球温度:"), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.wetBulbTemperature))]), _vm._v("°C\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            今天您机房的平均COP:\n            "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.dailyAverageCop))]), _vm._v("\n            ,\n            "), _c("el-popover", {
    attrs: {
      placement: "bottom-start",
      title: "",
      width: "300",
      trigger: "click",
      content: ""
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("\n                通过电价谷期耗电量以及谷期电价，对比每日8-18时平均电价，计算出谷期等效经济电量，将谷期耗电量折算为谷期等效经济电量计算当日平均折算COP。\n              ")]), _vm._v(" "), _c("span", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("\n                折算COP\n                "), _c("i", {
    staticClass: "el-icon-info",
    staticStyle: {
      color: "#39b149"
    }
  })])]), _vm._v("\n            是:  \n            "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.conversionCop))]), _vm._v("\n            ;\n          ")], 1), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            冷价:\n            "), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.previewData.dailyColdPrice))]), _vm._v("\n            元/千瓦时 (平均电价\n            "), _c("span", {
    staticClass: "number"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.dayElectricityPrice) + "\n            ")]), _vm._v("\n            元/度电);\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            日蓄冷量:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.dailyColdStorageCapacity)) + "\n            ")]), _vm._v("\n            kWh; 蓄冷结束时间:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v(_vm._s(_vm.previewData.coldStorageendData))]), _vm._v("\n            ;\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            总供冷量:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.coolingCapacity)) + "\n            ")]), _vm._v("\n            kWh, 峰:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.coolingCapacitySummit)) + "\n            ")]), _vm._v("\n            kWh, 平:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.coolingCapacityLevel)) + "\n            ")]), _vm._v("\n            kWh, 谷:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.coolingCapacityLowEbb)) + "\n            ")]), _vm._v("\n            kWh;\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            蓄冷槽放冷量:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorage)) + "\n            ")]), _vm._v("\n            kWh; 占:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorageProportion)) + "\n            ")]), _vm._v("\n            %,\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            其中峰:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorageSummit)) + "\n            ")]), _vm._v("\n            kWh (削峰率:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStoragePeakCutting)) + "\n            ")]), _vm._v("\n            %), 平:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorageLevel)) + "\n            ")]), _vm._v("\n            kWh, 谷:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.capacityColdStorageLowEbb)) + "\n            ")]), _vm._v("\n            kWh;\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            总耗电量:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.powerConsumption)) + "\n            ")]), _vm._v("\n            kWh; 峰:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.powerConsumptionSummit)) + "\n            ")]), _vm._v("\n            kWh, 平:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.powerConsumptionLevel)) + "\n            ")]), _vm._v("\n            kWh, 谷:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.powerConsumptionLowEbb)) + "\n            ")]), _vm._v("\n            kWh;\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            预估电费:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.estimatedTariff)) + "\n            ")]), _vm._v("\n            元, 预计节省电费:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm._f("numFilter")(_vm.previewData.savingElectricity)) + "\n            ")]), _vm._v("\n            元;\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            供冷开始时间:\n            "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v(_vm._s(_vm.previewData.coolingStartTime))]), _vm._v("\n            , 供冷结束时间:\n            "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v(_vm._s(_vm.previewData.coolingEndTime))]), _vm._v("\n            ,共\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.coolingDuration) + "\n            ")]), _vm._v("\n            小时;\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            今天的平均冷冻供水温度:\n            "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.waterSupplyTemperature) + "\n            ")]), _vm._v("\n            °C,平均供水温差:\n            "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.waterSupplyTemperatureDifference) + "\n            ")]), _vm._v("\n            °C;\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            今天蓄冷期间平均冷却回水温度:\n            "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.coldStorageReturnWaterTemperature) + "\n            ")]), _vm._v("\n            °C,平均逼近度:\n            "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.coldStorageApproximationDegree) + "\n            ")]), _vm._v("\n            °C;\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            今天主机供冷期间平均冷却回水温度:\n            "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.hostReturnWaterTemperature) + "\n            ")]), _vm._v("\n            °C,平均逼近度:\n            "), _c("span", {
    staticClass: "num-box"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.hostApproximationDegree) + "\n            ")]), _vm._v("\n            °C;\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "date-box"
  }, [_vm._v("\n            截至今日,本机房全年综合COP值:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.yearCop) + "\n            ")]), _vm._v("\n            ,冷价:\n            "), _c("span", {
    staticClass: "numbox"
  }, [_vm._v("\n              " + _vm._s(_vm.previewData.yearColdPrice) + "\n            ")]), _vm._v("\n            元/kWh,加油!\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "remark"
  })])])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "week-box"
  }, [_c("div", {
    staticClass: "item"
  }, [_vm._v("周一")]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("周二")]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("周三")]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("周四")]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("周五")]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("周六")]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("周日")])]);
}];
render._withStripped = true;