import { render, staticRenderFns } from "./historical.vue?vue&type=template&id=5e9f0ad9&scoped=true"
import script from "./historical.vue?vue&type=script&lang=js"
export * from "./historical.vue?vue&type=script&lang=js"
import style0 from "./historical.vue?vue&type=style&index=0&id=5e9f0ad9&lang=scss"
import style1 from "./historical.vue?vue&type=style&index=1&id=5e9f0ad9&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e9f0ad9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\yl-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e9f0ad9')) {
      api.createRecord('5e9f0ad9', component.options)
    } else {
      api.reload('5e9f0ad9', component.options)
    }
    module.hot.accept("./historical.vue?vue&type=template&id=5e9f0ad9&scoped=true", function () {
      api.rerender('5e9f0ad9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/data-query/components-cool/historical.vue"
export default component.exports