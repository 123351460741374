"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'bigBorder',
  components: {},
  props: {
    borderSize: {
      type: Boolean,
      default: true
    }
  },
  //数据集合
  data: function data() {
    return {};
  },
  //方法集合
  methods: {}
};