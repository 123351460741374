"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  props: {
    energyData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      projectInfo: {},
      number: "",
      status: 0,
      color: ""
    };
  },
  watch: {
    energyData: function energyData(val) {
      var _this = this;
      val.map(function (e, index) {
        for (var key in val[0]) {
          _this.number = val[0][key][0];
        }
      });
      this.status = val[1]["status"];

      // const color = val[1]['energyColor'][0]
      // switch (color) {
      //   case 0 :
      //     this.color = '#00000066'
      //     break
      //   case 1 :
      //     this.color = '#ff7e84'
      //     break
      //   case 2 :
      //     this.color = '#ffe4b0'
      //     break
      //   case 3 :
      //     this.color = '#d7ffe2'
      //     break
      //   case 4 :
      //     this.color = '#c3f3ff'
      //     break
      // }
    }
  },
  created: function created() {
    if (JSON.parse(sessionStorage.getItem("projectInfo"))) {
      this.projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));
    }
  }
};