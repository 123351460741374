"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProject = getProject;
exports.getProjectWorkOrderListByReviewerId = getProjectWorkOrderListByReviewerId;
exports.getProjectWorkOrderListBySubmitterId = getProjectWorkOrderListBySubmitterId;
exports.getProjectWorkOrderSendUsersBySendUserId = getProjectWorkOrderSendUsersBySendUserId;
exports.getprojectInfoByWorkOrderIdentList = getprojectInfoByWorkOrderIdentList;
exports.getuserInfoByProjectId = getuserInfoByProjectId;
exports.postInsertProjectWordOrder = postInsertProjectWordOrder;
exports.postOrderAdd = postOrderAdd;
exports.postOrderClose = postOrderClose;
exports.postOrderDetailEdit = postOrderDetailEdit;
exports.postOrderEdit = postOrderEdit;
exports.postReceivedOrderHandler = postReceivedOrderHandler;
exports.postUnOrderReceivingReassignment = postUnOrderReceivingReassignment;
exports.postUploadImage = postUploadImage;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /*
 * @Author: mxz
 * @Date: 2021-11-26 09:38:12
 * @LastEditTime: 2021-11-29 10:56:57
 * @LastEditors: mxz
 * @Description: 订单请求接口
 * @FilePath: \yilong\src\api\order.js
 */
/**
 * @description: 根据工单id工单处理信息
 * @param {type}
 * @return {type}
 */
// 获取项目列表
function getProject() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
/**
 * @description: 用户作为提交人id查询工单信息
 * @param {type}
 * @return {type}
 */
function getProjectWorkOrderListBySubmitterId(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderListBySubmitterId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

/**
 * @description: 用户作为处理人查询工单信息
 * @param {type}
 * @return {type}
 */
function getProjectWorkOrderListByReviewerId(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderListByReviewerId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

/**
 * @description: 用户作为抄送人查询工单信息
 * @param {type}
 * @return {type}
 */
function getProjectWorkOrderSendUsersBySendUserId(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderSendUsersBySendUserId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 获取用户关联的有工单的项目信息
function getprojectInfoByWorkOrderIdentList() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoByWorkOrderIdentList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

// 获取关联项目的用户id
function getuserInfoByProjectId(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/userInfoByProjectId",
    method: 'get',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}
function postUploadImage(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/uploadFile",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

// 创建工单
function postInsertProjectWordOrder(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/insertProjectWordOrder",
    method: 'post',
    params: _objectSpread(_objectSpread({}, params), {}, {
      encryptType: encryptType
    })
  });
}

// 修改工单
function postOrderEdit(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/updateProjectWordOrder",
    method: 'post',
    params: _objectSpread(_objectSpread({}, data), {}, {
      encryptType: encryptType,
      dataType: 'form'
    })
  });
}

// 修改工单处理
function postOrderDetailEdit(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderHandlerUpdate",
    method: 'post',
    params: _objectSpread(_objectSpread({}, data), {}, {
      encryptType: encryptType,
      dataType: 'form'
    })
  });
}

// 接单
function postOrderAdd(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/orderReceiving",
    method: 'post',
    params: _objectSpread(_objectSpread({}, data), {}, {
      encryptType: encryptType
    })
  });
}

// 关闭工单
function postOrderClose(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/closeWorkOrder",
    method: 'post',
    params: _objectSpread(_objectSpread({}, data), {}, {
      encryptType: encryptType
    })
  });
}

// 不接单直接转派下一个人
function postUnOrderReceivingReassignment(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/unOrderReceivingReassignment",
    method: 'post',
    params: _objectSpread(_objectSpread({}, data), {}, {
      encryptType: encryptType
    })
  });
}

// 接单后处理 处理完成关闭工单/处理完成转派
function postReceivedOrderHandler(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/receivedOrderHandler",
    method: 'post',
    params: _objectSpread(_objectSpread({}, data), {}, {
      encryptType: encryptType,
      dataType: 'form'
    })
  });
}