"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var THREE = _interopRequireWildcard(require("three"));
var _gsap = _interopRequireDefault(require("gsap"));
var _scene = _interopRequireDefault(require("@/three/scene"));
var _camera = _interopRequireDefault(require("@/three/camera"));
var _renderer = _interopRequireDefault(require("@/three/renderer"));
var _axesHelper = _interopRequireDefault(require("@/three/axesHelper"));
var _controls = _interopRequireDefault(require("@/three/controls"));
var _animate = _interopRequireDefault(require("@/three/animate"));
var _gui = _interopRequireDefault(require("@/three/gui"));
require("@/three/init");
var _createMesh = _interopRequireDefault(require("@/three/createMesh"));
var _eventHub = _interopRequireDefault(require("@/utils/eventHub"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// 导入场景
// 导入相机
// 导入渲染器
// 导入坐标轴
// 导入控制器
// 导入动画函数
// 导入gui
// 导入初始化事件
// 创建物体
// 引入事件总线
var _default = exports.default = {
  data: function data() {
    return {
      ws: null,
      wsIp: process.env.VUE_APP_BASE_API,
      loading: true
    };
  },
  created: function created() {},
  watch: {
    loading: function loading() {
      if (!this.loading) {
        this.initWebSocket();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    // 场景添加相机
    _scene.default.add(_camera.default.activeCamera);
    // 加入辅助轴，查看3维坐标轴
    _scene.default.add(_axesHelper.default);
    // 创建物体
    (0, _createMesh.default)(function () {
      _this.loading = false;
    });

    // 将渲染器添加到容器
    this.$refs.sceneRef.appendChild(_renderer.default.renderer.domElement);
    (0, _animate.default)();
  },
  methods: {
    initWebSocket: function initWebSocket() {
      if (this.ws) {
        this.ws.close();
      }
      if ("WebSocket" in window) {
        var i = this.wsIp.indexOf("//");
        var url = this.wsIp.slice(i);
        this.ws = new WebSocket("".concat(process.env.VUE_APP_WSS_STATUS == "true" ? "wss" : "ws", ":").concat(url, "/websocketServer/scene/").concat(this.$store.state.project.projectInfo.id));
        this.ws.onopen = function () {
          console.log('WebSocket连接已开启');
        };
        this.ws.onmessage = function (message) {
          var data = JSON.parse(message.data);
          _eventHub.default.emit('update-tag', data);
        };
        this.ws.onclose = function () {
          console.log('WebSocket连接已关闭');
        };
        this.ws.onerror = function (error) {
          console.error('WebSocket发生错误', error);
        };
      }
    }
  }
};