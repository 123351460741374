"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "orderResult"
  }, [_c("el-form", {
    ref: "form",
    staticClass: "filter-inline",
    attrs: {
      model: _vm.resultModel
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.resultTitle
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%",
      "font-size": "16px"
    },
    attrs: {
      type: "textarea",
      maxlength: "200",
      "show-word-limit": "",
      clearable: "",
      rows: "5",
      placeholder: "请输入",
      resize: "none"
    },
    model: {
      value: _vm.resultModel.desc,
      callback: function callback($$v) {
        _vm.$set(_vm.resultModel, "desc", $$v);
      },
      expression: "resultModel.desc"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "imgTitle"
  }, [_vm._v("\n      上传图片\n      "), _c("div", {
    staticClass: "imgWarn"
  }, [_vm._v("单张不得大于2M")])]), _vm._v(" "), _c("el-form-item", [_c("el-upload", {
    ref: "elUpload",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      action: "",
      "list-type": "picture-card",
      multiple: "",
      accept: ".jpg,.jpeg,.png,.JPG,.JPEG",
      "auto-upload": false,
      "before-upload": _vm.beforeAvatarUpload,
      limit: 4,
      "on-exceed": _vm.handleExceed,
      "http-request": _vm.imageUpload,
      "on-preview": _vm.handlePictureCardPreview
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;