"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _dataQuery = require("@/api/dataQuery");
var _report = require("@/api/report");
var _default = exports.default = {
  data: function data() {
    return {
      resetReportLoading: false,
      homeDataPie: {
        coolingCapacityByHourMapList: {
          list1: [],
          list2: []
        }
      },
      switchChart: false,
      value: (0, _dayjs.default)().format("YYYY-MM"),
      isShow: true,
      show: false,
      checked: true,
      calendarData: [],
      haveData: [],
      monthList: [],
      listData: [],
      reportData: [],
      previewSrc: "",
      previewSrcOne: "",
      previewData: {},
      dialogVisible: false,
      reportLogoVisible: 1,
      nowMonth: (0, _dayjs.default)().format("YYYY-MM"),
      dayActive: (0, _dayjs.default)().format("YYYY-MM-DD"),
      currentDay: (0, _dayjs.default)().format("YYYY-MM-DD"),
      monthData: (0, _dayjs.default)().format("YYYY-MM"),
      userInfo: {},
      firstChart: {
        xdata: [],
        gll: [],
        hdl: [],
        rdf: []
      },
      secondSbnh: [],
      secondFpg: {
        psd: "",
        dgsd: "",
        gfsd: ""
      },
      nxglObj: {
        dataTime: [],
        lj: [],
        nx: []
      },
      thirdGlsc: {
        data: [],
        xdata: []
      },
      fourthChart: {
        copData: [],
        ColdPrice: [],
        xdata: []
      },
      magnifyChart: {
        first: null,
        thirdly: null,
        fourth: null
      },
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      historicalData: {
        xdata: [],
        psd: [],
        gfd: [],
        dgd: [],
        xlgll: [],
        zjgll: []
      },
      loading: false,
      colorType: "",
      echartfc: "",
      // 图表颜色
      echartLine: "",
      // 图表线颜色
      echartYLine: "",
      powerType: [{
        name: "耗电量"
      }, {
        name: "供冷量"
      }, {
        name: "蓄冷放冷量"
      }],
      activeIndexNhfb: 0,
      powerType1: [{
        name: "耗电量"
      }, {
        name: "供冷量"
      }, {
        name: "供冷分布"
      }],
      activeIndexGlfb: 0,
      nhfbTotal: "",
      zoom: '',
      zoomrAtio: '',
      yl_zoom: '',
      yl_zoomrAtio: ''
    };
  },
  watch: {
    value: function value(val) {
      this.monthData = (0, _dayjs.default)(val).format("YYYY");
    },
    monthData: function monthData(val) {
      this.dayActive = val;
      this.calendarData = [];
      this.getMonthWordYearListData();
    },
    nowMonth: function nowMonth() {
      this.getData();
      this.getMonthWordYearListData();
      this.getMonthWordPreviewData((0, _dayjs.default)(this.nowMonth).format("YYYY-MM"));
      this.getDataQueryData();
    },
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;
      if (!this.colorType) {
        this.echartfc = "#333";
        this.echartLine = "#333";
        this.echartYLine = "#ddd";
      } else {
        this.echartfc = "#fff";
        this.echartLine = "#29292d";
        this.echartYLine = "#333";
      }
      this.getFirstChart();
      this.getSecondChartSbnh();
      this.getFourthChart();
      this.getLineCharts();
    }
  },
  computed: {
    installedCapacity: function installedCapacity() {
      return this.$store.state.project.projectInfo.installedCapacity;
    },
    isCurrentMonth: function isCurrentMonth() {
      if (this.nowMonth == (0, _dayjs.default)().format("YYYY-MM")) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function mounted() {
    this.colorType = this.$store.state.project.colorType;
    if (!this.colorType) {
      this.echartfc = "#000";
      this.echartLine = "#333333";
      this.echartYLine = "#ddd";
    } else {
      this.echartfc = "#000";
      this.echartLine = "#333333";
      this.echartYLine = "#ddd";
    }
    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
    this.calendarData = [];
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    if (month == 1) {
      this.monthData = (parseInt(year) - 1).toString();
    } else {
      this.monthData = (0, _dayjs.default)().format("YYYY");
    }
    this.getData();
    this.getMonthWordYearListData();
    this.getDataQueryData();
    this.getMonthWordPreviewData((0, _dayjs.default)(this.nowMonth).format("YYYY-MM"));
  },
  methods: {
    // 能耗分布按钮切换
    handelPowerType: function handelPowerType(value) {
      this.activeIndexNhfb = value;
      this.getSecond();
    },
    // 能数据统计按钮切换
    handelPowerType1: function handelPowerType1(value) {
      this.activeIndexGlfb = value;
      this.getDataQueryData();
    },
    monthWordPreview: function monthWordPreview() {},
    handleClose: function handleClose() {
      var _this = this;
      var opens = document.getElementById("magnifyChart1");
      opens.style.display = "none";
      this.$nextTick(function () {
        _this.yl_zoom = '';
        _this.yl_zoomrAtio = '';
      });
    },
    initChart: function initChart(index) {
      var _this2 = this;
      var opens = document.getElementById("magnifyChart1");
      opens.style.display = "block";
      var lineCharts = this.$echarts.init(document.getElementById("magnifyChartData"));
      lineCharts.clear();
      if (index == 1) {
        lineCharts.setOption(this.magnifyChart.first);
      } else if (index == 3) {
        lineCharts.setOption(this.magnifyChart.thirdly);
      } else if (index == 4) {
        lineCharts.setOption(this.magnifyChart.fourth);
      }
      this.$nextTick(function () {
        _this2.yl_zoom = document.body.style.zoom;
        _this2.yl_zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    },
    // 蓄冷获取月报表数据
    getMonthWordPreviewData: function getMonthWordPreviewData(date) {
      var _this3 = this;
      this.show = true;
      var params = {
        dayTime: date,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _report.getMonthColdStorageReportByDateSelect)(params).then(function (res) {
        _this3.previewData = res;
        _this3.show = false;
      }).catch(function (err) {
        _this3.show = false;
        _this3.previewData = [];
      });
    },
    //第一板块数据 后台处理接口合并了 返回来能耗概览和能效概览
    getFirst: function getFirst() {
      var _this4 = this;
      var firstTimeParams = this.nowMonth;
      var params = {
        projectId: this.$store.state.project.projectInfo.id,
        reportType: 0,
        time: firstTimeParams + "-01 00:00:00"
      };
      (0, _report.getCoolNhglAndNxgl)(params).then(function (res) {
        // 能耗概览部分
        _this4.firstChart.xdata = res.map(function (e) {
          return e.time;
        });
        _this4.firstChart.gll = res.map(function (e) {
          return e.coolingCapacity;
        });
        _this4.firstChart.hdl = res.map(function (e) {
          return e.powerConsumption;
        });
        _this4.firstChart.rdf = res.map(function (e) {
          return e.estimatedTariff;
        });
        _this4.getFirstChart();
        // 能效概览部分
        _this4.nxglObj.dataTime = res.map(function (e) {
          return e.time;
        });
        _this4.nxglObj.lj = res.map(function (e) {
          return e.dailyColdPrice;
        });
        _this4.nxglObj.nx = res.map(function (e) {
          return e.dailyAverageCop;
        });
        _this4.getFourthChart();
      });
    },
    //第二板块数据
    getSecond: function getSecond() {
      var _this5 = this;
      var secondTimeParams = this.nowMonth;
      // 这里参数没加全
      var secondParams = {
        reportType: 0,
        type: this.activeIndexNhfb,
        projectId: this.$store.state.project.projectInfo.id,
        time: secondTimeParams + "-01 00:00:00"
      };
      (0, _dataQuery.getCoolNhfb)(secondParams).then(function (res) {
        if (res && res.length !== 0 && Object.values(res).length !== 0) {
          _this5.secondSbnh = [];
          _this5.secondSbnh.push({
            value: res.normalInterval,
            name: "平时段"
          });
          _this5.secondSbnh.push({
            value: res.peakPeriod,
            name: "高峰时段"
          });
          _this5.secondSbnh.push({
            value: res.troughPeriod,
            name: "低谷时段"
          });
        }
        _this5.nhfbTotal = res.normalInterval * 1 + res.peakPeriod * 1 + res.troughPeriod * 1 + "kWh";
        _this5.getSecondChartSbnh();
      });
    },
    //板块三 供冷时长
    // 他这里包括了上面两组数据要进行查分
    getData: function getData() {
      this.getFirst();
      this.getSecond();
    },
    // 点击预览 查看供冷月报
    handlePreview: function handlePreview() {
      this.dialogVisible = true;
    },
    handleTime: function handleTime(val) {
      this.value = val;
    },
    formatterTime: function formatterTime(row) {
      return (0, _dayjs.default)(row.time).format("YYYY-MM-DD");
    },
    getMonthWordYearListData: function getMonthWordYearListData() {
      var _this6 = this;
      this.haveData = [];
      var params = {
        projectId: this.$store.state.project.projectInfo.id,
        dateTime: this.monthData
      };
      (0, _report.getMonthWordYearList)(params).then(function (res) {
        _this6.listData = res;
        _this6.haveData = res.map(function (e) {
          return {
            value: (0, _dayjs.default)(e.reportTime).format("YYYY-MM"),
            key: e.id
          };
        });
        res.forEach(function (data) {
          if ((0, _dayjs.default)(data.reportTime).format("YYYY-MM") == _this6.nowMonth) {
            _this6.previewSrc = data.reportFilePath;
          }
        });
      }).catch(function (error) {
        //console.log("处理逻辑出错:" + error);
      });
    },
    handlOneDown: function handlOneDown() {
      var _this7 = this;
      this.loading = true;
      if (this.previewSrc) {
        var link = document.createElement("a");
        link.href = this.previewSrc;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setTimeout(function () {
        _this7.loading = false;
      }, 1000);
    },
    handleBatchDown: function handleBatchDown() {
      if (this.calendarData.length !== 0) {
        this.getMonthReportData();
      } else {
        this.$message.error("未选择下载月份");
      }
    },
    getMonthReportData: function getMonthReportData() {
      var _this8 = this;
      this.loading = true;
      var params = {
        monthWordIds: this.calendarData,
        projectId: this.$store.state.project.projectInfo.id
      };
      setTimeout(function () {
        _this8.loading = false;
      }, 2000);
      (0, _report.getMonthReport)(params).then(function (res) {
        var link = document.createElement("a");
        link.href = res;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    handleCheckAll: function handleCheckAll() {
      this.isShow = !this.isShow;
      if (!this.isShow) {
        this.calendarData = this.haveData;
      } else {
        this.calendarData = [];
      }
    },
    //绘制板块一柱状图
    getFirstChart: function getFirstChart() {
      var lineCharts = this.$echarts.init(document.getElementById("first"));
      lineCharts.clear();
      var option = {
        grid: {
          left: "12%",
          right: "4%",
          top: "22%",
          bottom: "20%" // 也可设置left和right设置距离来控制图表的大小
        },
        color: ["#5570c6", "#91cc76", "#fac858"],
        tooltip: {
          trigger: "axis",
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          formatter: function formatter(param) {
            //  //console.log("param---" + JSON.stringify(param));
            var str = "";
            var d = "";
            param.forEach(function (data) {
              d = data.axisValue + "</br>";
              if (data.seriesName == "日电费") {
                str += "".concat(data.marker).concat(data.seriesName, ":").concat(data.data, "\u5143");
              } else {
                str += "".concat(data.marker).concat(data.seriesName, ":").concat(data.data, "kWh </br>");
              }
            });
            return d + str;
          }
        },
        title: {
          right: 10,
          top: 10,
          textStyle: {
            fontSize: 16,
            color: this.echartfc,
            fontWeight: 400
          }
        },
        dataZoom: {
          // 放大和缩放
          type: "inside"
        },
        textStyle: {
          fontSize: 14
        },
        legend: {
          type: "plain",
          orient: "horizontal",
          icon: "circle",
          show: true,
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          },
          formatter: function formatter(param) {
            if (param == "日电费") {
              return "".concat(param, "-\u5143");
            }
            return "".concat(param, "-kWh");
          }
        },
        xAxis: {
          axisPointer: {
            type: "shadow"
          },
          type: "category",
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,
            //使x轴文字显示全
            rotate: 90
          },
          data: this.firstChart.xdata
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: this.echartYLine
              //type:'dashed'虚线
            }
          }
        },
        series: [{
          name: "供冷量",
          data: this.firstChart.gll,
          type: "bar",
          lineStyle: {
            color: this.echartLine
          }
        }, {
          name: "耗电量",
          data: this.firstChart.hdl,
          type: "bar",
          lineStyle: {
            color: this.echartLine
          }
        }, {
          name: "日电费",
          data: this.firstChart.rdf,
          type: "bar",
          lineStyle: {
            color: this.echartLine
          }
        }]
      };
      this.magnifyChart.first = option;
      lineCharts.setOption(option);
    },
    //绘制第二板块
    getSecondChartSbnh: function getSecondChartSbnh() {
      var lineCharts = this.$echarts.init(document.getElementById("second"));
      lineCharts.clear();
      var option = {
        tooltip: {
          trigger: "item",
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          formatter: "{b} : {c}kWh  占比:{d}%"
        },
        textStyle: {
          fontSize: 14
        },
        legend: {
          // type: "plain",
          bottom: 6,
          left: "center",
          textStyle: {
            fontSize: 14,
            color: this.echartfc,
            lineHeight: 30
          }
        },
        series: [{
          bottom: "15%",
          type: "pie",
          radius: "66%",
          data: this.secondSbnh,
          color: ["#fac858", "#ed6666", "#5570c6", "#91cc76"],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          },
          label: {
            color: this.echartfc,
            formatter: function formatter(params) {
              return "".concat(params.name);
            }
          },
          itemStyle: {
            label: {
              show: true,
              formatter: function formatter(param) {
                return "".concat(param.name, ": ").concat(param.value, "%  ");
              }
            },
            labelLine: {
              show: true
            }
          }
        }]
      };
      lineCharts.setOption(option);
    },
    // 能效概览 图
    getFourthChart: function getFourthChart() {
      var lineCharts = this.$echarts.init(document.getElementById("fourth"));
      lineCharts.clear();
      var option = {
        tooltip: {
          trigger: "axis",
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          padding: 15
        },
        grid: {
          bottom: 70,
          left: 50
        },
        dataZoom: {
          // 放大和缩放
          type: "inside"
        },
        textStyle: {
          fontSize: 14
        },
        legend: {
          data: ["冷价", "能效"],
          // top: 10,
          icon: "circle",
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          }
        },
        xAxis: {
          axisPointer: {
            type: "shadow"
          },
          position: {
            bottom: 0
          },
          type: "category",
          axisTick: {
            show: false
          },
          boundaryGap: true,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 90,
            textStyle: {
              color: "#999999" // 文字颜色
            }
          },
          data: this.nxglObj.dataTime
        },
        yAxis: [{
          min: 0,
          type: "value",
          position: "left",
          name: "冷价",
          splitLine: {
            show: false
          }
        }, {
          type: "value",
          position: "right",
          name: "能效",
          min: 0,
          splitLine: {
            show: false
          }
          // axisLabel: {
          //   formatter: "{value}%",
          // },
        }],
        series: [{
          name: "冷价",
          type: "bar",
          barWidth: "10",
          itemStyle: {
            color: "#34aa44",
            /// 折线点的颜色
            lineStyle: {
              color: "#34aa44" ///　折线的颜色
            }
          },
          data: this.nxglObj.lj
        }, {
          name: "能效",
          type: "line",
          yAxisIndex: 1,
          symbol: "circle",
          symbolSize: 10,
          //折线点的大小
          itemStyle: {
            color: "#1665d8",
            /// 折线点的颜色
            lineStyle: {
              color: "#1665d8" ///　折线的颜色
            }
          },
          data: this.nxglObj.nx
        }]
      };
      this.magnifyChart.fourth = option;
      lineCharts.setOption(option);
    },
    // 第三模块
    getDataQueryData: function getDataQueryData() {
      var _this9 = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        reportType: 0,
        time: this.nowMonth + "-01 00:00:00",
        type: this.activeIndexGlfb
      };
      (0, _dataQuery.getCoolGlfb)(par).then(function (res) {
        _this9.historicalData.xdata = res.map(function (e) {
          return e.time;
        });
        _this9.historicalData.psd = res.map(function (e) {
          return e.normalInterval;
        });
        _this9.historicalData.gfd = res.map(function (e) {
          return e.peakPeriod;
        });
        _this9.historicalData.dgd = res.map(function (e) {
          return e.troughPeriod;
        });
        _this9.historicalData.xlgll = res.map(function (e) {
          return e.xlgll;
        });
        _this9.historicalData.zjgll = res.map(function (e) {
          return e.zjgll;
        });
        setTimeout(function () {
          _this9.getLineCharts();
        }, 200);
      });
    },
    getLineCharts: function getLineCharts() {
      var _this10 = this;
      var lineCharts = this.$echarts.init(document.getElementById("line"));
      lineCharts.clear();
      // 耗电量 供冷量用同一类型的柱状图  通过this.activeIndexGlfb == 2 进行区分
      // 定义耗电量供冷量数据
      var gdl_hdl_series = [{
        name: "低谷时段",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.historicalData.dgd,
        data: this.historicalData.dgd.length > 0 ? this.historicalData.dgd : [0, 0, 0, 0, 0, 0, 0]
      }, {
        name: "平时段",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.historicalData.psd,
        data: this.historicalData.psd.length > 0 ? this.historicalData.psd : [0, 0, 0, 0, 0, 0, 0]
      }, {
        name: "高峰时段",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.historicalData.gfd,
        data: this.historicalData.gfd.length > 0 ? this.historicalData.gfd : [0, 0, 0, 0, 0, 0, 0]
      }];
      // 定义供冷分布数据
      var glfb_series = [{
        name: "主机供冷量",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.historicalData.zjgll,
        data: this.historicalData.zjgll.length > 0 ? this.historicalData.zjgll : [0, 0, 0, 0, 0, 0, 0]
      }, {
        name: "蓄冷供冷量",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.historicalData.xlgll,
        data: this.historicalData.xlgll.length > 0 ? this.historicalData.xlgll : [0, 0, 0, 0, 0, 0, 0]
      }];
      var option = {
        grid: {
          left: "5%",
          right: "4%",
          top: "22%",
          bottom: "8%",
          containLabel: true
        },
        legend: {
          data: this.activeIndexGlfb == 2 ? ["主机供冷量", "蓄冷供冷量"] : ["低谷时段", "平时段", "高峰时段"],
          top: "3%",
          textStyle: {
            color: this.echartfc
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: function formatter(params) {
            var result = params[0].name + "<br>";
            // 初始化总值
            var allCount = 0;
            params.forEach(function (item) {
              // 处理value为null的情况
              if (!item.value) {
                item.value = 0;
              }
              allCount += item.value * 1;
            });
            params.forEach(function (item) {
              if (item.value) {
                result += item.marker + " " + item.seriesName + " : " + item.value + "kWh" + ' 占比' + (item.value / allCount * 100).toFixed(2) + "%</br>";
              } else {
                result += item.marker + " " + item.seriesName + " : - </br>";
              }
            });
            return result;
          }
        },
        xAxis: [{
          type: "category",
          data: this.historicalData.xdata.length > 0 ? this.historicalData.xdata : ["", "", "", "", "", "", "", "", "", "", "", ""]
        }],
        yAxis: [{
          type: "value",
          splitLine: {
            lineStyle: {
              color: this.echartYLine
            }
          }
        }],
        // 允许放大缩小
        dataZoom: {
          type: "inside"
        },
        series: this.activeIndexGlfb == 2 ? glfb_series : gdl_hdl_series
      };
      this.magnifyChart.thirdly = option;
      lineCharts.setOption(option);
      this.$nextTick(function () {
        _this10.zoom = document.body.style.zoom;
        _this10.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    }
  }
};