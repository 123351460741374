"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "theme-box"
  }, [_c("div", {
    staticClass: "content-box"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "tit"
  }, [_c("span", [_vm._v(_vm._s(_vm.projectInfo.entryName))]), _vm._v(" "), _vm.projectList !== undefined && _vm.projectList != null && _vm.projectList.length > 1 ? _c("Select") : _vm._e()], 1), _vm._v(" "), !_vm.notification.total == 0 ? _c("el-popover", {
    attrs: {
      placement: "top",
      width: "600",
      trigger: "hover"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    class: {
      webTherapyAuditList: _vm.notification.total > 10
    },
    on: {
      scroll: function scroll($event) {
        return _vm.handleScroll($event);
      }
    }
  }, _vm._l(_vm.notification.rows, function (item) {
    return _c("div", {
      staticClass: "cont"
    }, [_c("span", {
      staticClass: "box"
    }, [_vm._v(_vm._s(item.content))]), _vm._v(" "), item.type == 1 ? _c("span", [_vm._v("离线报警")]) : _vm._e(), _vm._v(" "), item.type == 2 ? _c("span", [_vm._v("数据报警")]) : _vm._e(), _vm._v(" "), _c("span", [_vm._v(_vm._s(item.alarmTime))]), _vm._v(" "), _c("span", [_vm._v(_vm._s(item.createTime))]), _vm._v(" "), _c("span", {
      staticClass: "qren",
      on: {
        click: function click($event) {
          return _vm.batchUpdateProjectAlarmState(item.id);
        }
      }
    }, [_vm._v("确认")])]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "btn"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "text"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("取消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.batchUpdateProjectAlarmState("");
      }
    }
  }, [_vm._v("一键确认")])], 1), _vm._v(" "), _c("el-badge", {
    staticClass: "item",
    attrs: {
      slot: "reference",
      value: _vm.notification.total
    },
    slot: "reference"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/svg/tishi.svg"),
      alt: ""
    },
    on: {
      click: function click($event) {
        return _vm.alarm();
      }
    }
  })])], 1) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.time))]), _vm._v(" "), _c("div", {
    staticStyle: {
      "line-height": "24px",
      "font-size": "14px"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.projectInfo.projectIntroduction) + "\n    ")]), _vm._v(" "), _c("div", {
    staticClass: "layer_box"
  }, [_c("div", {
    staticClass: "img-box"
  }, [_c("img", {
    staticClass: "img",
    attrs: {
      src: _vm.projectInfo.projectPicture,
      alt: ""
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "icon-box"
  }, [_c("div", {
    staticClass: "icon"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/svg/syicon_1.svg"),
      alt: ""
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "name"
  }, [_c("div", [_vm._v("室外温度")]), _vm._v(" "), _c("div", {
    staticClass: "temperature"
  }, [_vm._v(_vm._s(_vm.temperature[0]) + " °C")])])]), _vm._v(" "), _c("div", {
    staticClass: "icon"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/svg/syicon_2.svg"),
      alt: ""
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "name"
  }, [_c("div", [_vm._v("相对湿度")]), _vm._v(" "), _c("div", {
    staticClass: "temperature"
  }, [_vm._v(_vm._s(_vm.temperature[1]) + "%")])])]), _vm._v(" "), _c("div", {
    staticClass: "icon"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/svg/syicon_3.svg"),
      alt: ""
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "name"
  }, [_c("div", [_vm._v("湿球温度")]), _vm._v(" "), _c("div", {
    staticClass: "temperature"
  }, [_vm._v(_vm._s(_vm.temperature[2]) + "°C")])])])])])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;