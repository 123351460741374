"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _report = require("@/api/report");
var _auth = require("@/utils/auth");
var _default = exports.default = {
  data: function data() {
    return {
      params: {
        dataTime: '',
        //	开始时间	query	false	
        endTime: '',
        //	结束时间	query	false	
        projectId: this.$store.state.project.projectInfo.id
      },
      dataTime: '',
      fileData: [],
      api: process.env.VUE_APP_BASE_API,
      userInfo: {},
      dialogVisible: false,
      reportLogoVisible: 1,
      yearReport: {
        dataTime: 2022,
        //		时间 yyyy-MM	string(date-time)	
        gll: '',
        //		供冷量	number(double)	
        hdl: '',
        //		耗电量	number(double)	
        ljglsc: '',
        //		累计供冷时长	number(double)	
        ljglts: '',
        //		累计供冷天数	number(double)	
        pjgldj: '',
        //		平均供冷单价	number(double)	
        pjnx: '',
        //		平均能效	number(double)	
        rjglsc: '',
        //		日均供冷时长	number(double)	
        ygdf: '' //		预估电费
      },
      year: '',
      magnifyChart: {
        first: null,
        thirdly: null,
        fourth: null
      },
      secondSbnh: [],
      nhglObj: {
        dataTime: [],
        gllData: [],
        hdlData: [],
        rdfDate: []
      },
      glscObj: {
        dataTime: [],
        rjglsc: [],
        yglsc: [],
        yglts: []
      },
      nxglObj: {
        dataTime: [],
        lj: [],
        nx: []
      },
      previewSrc: "",
      uploud: {
        projectId: this.$store.state.project.projectInfo.id,
        yearTime: ''
      },
      visible: false,
      isConfirm: true,
      headers: {
        token: ''
      },
      expireTimeOPtion: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的 
        }
      },
      loading: false,
      colorType: '',
      echartfc: '',
      // 图表颜色
      echartYLine: "",
      loadingReport: false,
      zoom: '',
      zoomrAtio: ''
    };
  },
  watch: {
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;
      if (this.colorType == 'theme_jyh') {
        this.echartfc = '#fff';
        this.echartYLine = '#333';
      } else if (this.colorType == 'theme_ytl') {
        this.echartfc = '#333';
        this.echartYLine = '#ddd';
      } else {
        this.echartfc = '#333';
        this.echartYLine = '#ddd';
      }
      this.getFirstChart();
      this.getSecondChartSbnh();
      this.getThirdChart();
      this.getFourthChart();
    }
  },
  created: function created() {
    this.colorType = this.$store.state.project.colorType;
    if (this.colorType == 'theme_jyh') {
      this.echartfc = '#fff';
      this.echartYLine = '#333';
    } else if (this.colorType == 'theme_ytl') {
      this.echartfc = '#333';
      this.echartYLine = '#ddd';
    } else {
      this.echartfc = '#333';
      this.echartYLine = '#ddd';
    }
    this.headers.token = (0, _auth.getToken)();
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    if (month == 1) {
      this.year = (parseInt(year) - 1).toString();
    } else {
      this.year = (0, _dayjs.default)().format("YYYY");
    }
    this.uploud.yearTime = year;
    this.getNxgl();
    this.getNhzb();
    this.getNhgl();
    this.getNdbg();
    this.getGl();
    this.getYearReportList();
  },
  methods: {
    // 上传文件前提示
    upLoadFile: function upLoadFile() {
      var _this = this;
      // 若未选择需求，则提示选择
      this.$confirm('上传会覆盖之前的，是否继续上传', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.toSelectFile();
      }).catch(function (error) {
        return error;
      });
    },
    // toSelectFile方法可以是自己需要做的操作，然后继续选择文件上传
    toSelectFile: function toSelectFile() {
      // 通过ref 触发上传控件
      this.$refs['upload'].$refs['upload-inner'].handleClick();
    },
    yearChange: function yearChange() {
      this.uploud.yearTime = this.year;
      this.getNxgl();
      this.getNhzb();
      this.getNhgl();
      this.getNdbg();
      this.getGl();
      this.getYearReportList();
    },
    // 
    initChart: function initChart(index) {
      var opens = document.getElementById("magnifyChart1");
      opens.style.display = "block";
      var lineCharts = this.$echarts.init(document.getElementById("magnifyChartData"));
      lineCharts.clear();
      if (index == 1) {
        lineCharts.setOption(this.magnifyChart.first);
      } else if (index == 3) {
        lineCharts.setOption(this.magnifyChart.thirdly);
      } else if (index == 4) {
        lineCharts.setOption(this.magnifyChart.fourth);
      }
    },
    // 上传
    uploadSuccess: function uploadSuccess(response, file) {
      if (response.code === 200) {
        this.visible = true;
        this.$message('上传成功');
        this.getYearReportList();
      } else {
        this.$message.error('上传失败' + response.msg);
      }
    },
    getYearReportList: function getYearReportList() {
      var _this2 = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        year: this.uploud.yearTime
      };
      (0, _report.getYearReportList)(par).then(function (res) {
        if (res.length > 0) {
          var n = res.length - 1;
          _this2.previewSrc = res[n].reportFilePath;
        } else {
          _this2.previewSrc = false;
        }
      });
    },
    getClass: function getClass() {
      if (this.previewSrc) {
        return ["download-box"];
      } else {
        return ["download-box-ban"];
      }
    },
    // 下载
    handlOneDown: function handlOneDown() {
      var _this3 = this;
      this.loading = true;
      if (this.previewSrc) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', this.previewSrc, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          if (xhr.status === 200) {
            var blob = xhr.response;
            var url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            var fileName = _this3.previewSrc.split('/').pop();
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
          }
        };
        xhr.send();
      }
      setTimeout(function () {
        _this3.loading = false;
      }, 1000);
    },
    handlePreview: function handlePreview() {
      if (this.previewSrc) {
        window.open(this.previewSrc, "_blank");
      } else {
        this.$message("暂无年度报告");
      }
    },
    // 弹窗 关闭
    handleClose: function handleClose() {
      var opens = document.getElementById("magnifyChart1");
      opens.style.display = "none";
    },
    //年度报告
    getNdbg: function getNdbg() {
      //
      var ts = this;
      ts.loadingReport = true;
      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        year: ts.year
      };
      (0, _report.getNdbg)(par).then(function (res) {
        if (res) {
          ts.yearReport = res;
          ts.yearReport.gll = res.gll ? Math.floor(res.gll * 100) / 100 : 0; //		供冷量	number(double)	
          ts.yearReport.hdl = res.hdl ? Math.floor(res.hdl * 100) / 100 : 0; //		耗电量	number(double)	
          ts.yearReport.ljglsc = res.ljglsc ? Math.floor(res.ljglsc * 100) / 100 : 0; //		累计供冷时长	number(double)	
          ts.yearReport.ljglts = res.ljglts ? Math.floor(res.ljglts * 100) / 100 : 0; //		累计供冷天数	number(double)	
          ts.yearReport.pjgldj = res.pjgldj ? Math.floor(res.pjgldj * 100) / 100 : 0; //		平均供冷单价	number(double)	
          ts.yearReport.pjnx = res.pjnx ? Math.floor(res.pjnx * 100) / 100 : 0; //		平均能效	number(double)	
          ts.yearReport.rjglsc = res.rjglsc ? Math.floor(res.rjglsc * 100) / 100 : 0; //		日均供冷时长	number(double)	
          ts.yearReport.ygdf = res.ygdf ? Math.floor(res.ygdf * 100) / 100 : 0; //		预估电费
          ts.yearReport.dataTime = ts.yearReport.dataTime.substring(0, 4); //		预估电费
          ts.loadingReport = false;
        }
      });
    },
    //能耗概览
    getNhgl: function getNhgl() {
      var _this4 = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        year: this.year
      };
      this.nhglObj.dataTime = [];
      this.nhglObj.gllData = [];
      this.nhglObj.hdlData = [];
      this.nhglObj.rdfDate = [];
      (0, _report.getNhgl)(par).then(function (res) {
        //
        var list = res;
        if (list.length > 0) {
          for (var i = 0; i < list.length; i++) {
            _this4.nhglObj.dataTime.push((0, _dayjs.default)(list[i].dataTime).format("YYYY-MM"));
            _this4.nhglObj.gllData.push(list[i].gllData);
            _this4.nhglObj.hdlData.push(list[i].hdlData);
            _this4.nhglObj.rdfDate.push(list[i].rdfDate);
          }
        }
        _this4.getFirstChart();
      });
    },
    //能耗占比
    getNhzb: function getNhzb() {
      var _this5 = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        year: this.year
      };
      (0, _report.getNhzb)(par).then(function (res) {
        _this5.secondSbnh = [];
        // 使用了echarts自带的百分比
        // let num = Math.floor((res.zj + res.ldsb + res.lqsb + res.lqt) * 100) / 100
        // zb: (res.zj / num * 100).toFixed(2),
        if (res) {
          _this5.secondSbnh.push({
            value: res.ldsb,
            name: "冷冻水泵",
            msg: res.ldsb
          });
          _this5.secondSbnh.push({
            value: res.lqsb,
            name: "冷却水泵",
            msg: res.lqsb
          });
          _this5.secondSbnh.push({
            value: res.lqt,
            name: "冷却泵",
            msg: res.lqt
          });
          _this5.secondSbnh.push({
            value: res.zj,
            name: "主机",
            msg: res.zj
          });
          _this5.getSecondChartSbnh();
        }
      });
    },
    //供冷时长
    getGl: function getGl() {
      var _this6 = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        year: this.year
      };
      this.glscObj.dataTime = [];
      this.glscObj.rjglsc = [];
      this.glscObj.yglsc = [];
      this.glscObj.yglts = [];
      (0, _report.getGl)(par).then(function (res) {
        var list = res;
        if (list.length > 0) {
          for (var i = 0; i < list.length; i++) {
            _this6.glscObj.dataTime.push(list[i].dataTime ? (0, _dayjs.default)(list[i].dataTime).format("YYYY-MM") : 0);
            _this6.glscObj.rjglsc.push(list[i].rjglsc ? list[i].rjglsc : 0);
            _this6.glscObj.yglsc.push(list[i].yglsc ? list[i].yglsc : 0);
            _this6.glscObj.yglts.push(list[i].yglts ? list[i].yglts : 0);
          }
        }
        _this6.getThirdChart();
      });
    },
    //能效概览
    getNxgl: function getNxgl() {
      var _this7 = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        year: this.year
      };
      this.nxglObj.dataTime = [];
      this.nxglObj.lj = [];
      this.nxglObj.nx = [];
      (0, _report.getNxgl)(par).then(function (res) {
        var list = res;
        if (list.length > 0) {
          for (var i = 0; i < list.length; i++) {
            _this7.nxglObj.dataTime.push((0, _dayjs.default)(list[i].dataTime).format("YYYY-MM"));
            ;
            _this7.nxglObj.lj.push(list[i].lj);
            _this7.nxglObj.nx.push(list[i].nx);
          }
        }
        _this7.getFourthChart();
      });
    },
    // 能耗概览 图
    getFirstChart: function getFirstChart() {
      var lineCharts = this.$echarts.init(document.getElementById("first"));
      lineCharts.clear();
      var option = {
        grid: {
          left: "14%",
          right: "6%",
          top: "20%",
          bottom: "20%" // 也可设置left和right设置距离来控制图表的大小
        },
        color: ["#5570c6", "#91cc76", "#fac858"],
        tooltip: {
          trigger: "axis",
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          formatter: function formatter(param) {
            //  //console.log("param---" + JSON.stringify(param));
            var str = "";
            var d = '';
            param.forEach(function (data) {
              d = data.axisValue + '</br>';
              if (data.seriesName == "月电费") {
                str += "".concat(data.marker).concat(data.seriesName, ":").concat(data.data, "\u5143");
              } else {
                str += "".concat(data.marker).concat(data.seriesName, ":").concat(data.data, "kWh </br>");
              }
            });
            return d + str;
          }
        },
        title: {
          right: 10,
          top: 10,
          textStyle: {
            fontSize: 16,
            // color: "#333",
            fontWeight: 400,
            color: this.echartfc
          }
        },
        dataZoom: {
          // 放大和缩放
          type: "inside"
        },
        textStyle: {
          fontSize: 14
        },
        legend: {
          type: "plain",
          orient: "horizontal",
          icon: "circle",
          show: true,
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          },
          formatter: function formatter(param) {
            if (param == "月电费") {
              return "".concat(param, "-\u5143");
            }
            return "".concat(param, "-kWh");
          }
        },
        xAxis: {
          axisPointer: {
            type: "shadow"
          },
          type: "category",
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,
            //使x轴文字显示全
            rotate: 90
          },
          data: this.nhglObj.dataTime
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: this.echartYLine
              //type:'dashed'虚线
            }
          }
        },
        series: [{
          name: "供冷量",
          data: this.nhglObj.gllData,
          type: "bar"
        }, {
          name: "耗电量",
          data: this.nhglObj.hdlData,
          type: "bar"
        }, {
          name: "月电费",
          data: this.nhglObj.rdfDate,
          type: "bar"
        }]
      };
      this.magnifyChart.first = option;
      lineCharts.setOption(option);
    },
    // 能耗占比 图
    getSecondChartSbnh: function getSecondChartSbnh() {
      var lineCharts = this.$echarts.init(document.getElementById("second"));
      lineCharts.clear();
      var option = {
        tooltip: {
          trigger: "item",
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          formatter: "{b} : {c}kWh  占比:{d}%"
        },
        textStyle: {
          fontSize: 14
        },
        legend: {
          // type: "plain",
          bottom: 6,
          left: "center",
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          }
        },
        series: [{
          bottom: "15%",
          type: "pie",
          radius: "66%",
          data: this.secondSbnh,
          color: ["#fac858", "#ed6666", "#5570c6", "#91cc76"],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          },
          label: {
            color: this.echartfc,
            formatter: "{b} {d}%"
          },
          itemStyle: {
            label: {
              show: true,
              formatter: "{b} {d}%",
              color: this.echartfc
            },
            labelLine: {
              show: true
            }
          }
        }]
      };
      lineCharts.setOption(option);
    },
    // 供冷时长 图
    getThirdChart: function getThirdChart() {
      var _this8 = this;
      var lineCharts = this.$echarts.init(document.getElementById("third"));
      lineCharts.clear();
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          bottom: 70,
          left: 70
        },
        legend: {
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          },
          data: ['月供冷时长', '日均供冷时长', '月供冷天数']
        },
        xAxis: [{
          type: 'category',
          data: this.glscObj.dataTime,
          axisPointer: {
            type: "shadow"
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,
            //使x轴文字显示全
            rotate: 90
          }
        }],
        yAxis: [{
          type: 'value',
          min: 0,
          // max: 250,
          interval: 100,
          axisLabel: {
            formatter: '{value} h'
          },
          splitLine: {
            lineStyle: {
              color: this.echartYLine
              //type:'dashed'虚线
            }
          }
        }, {
          type: 'value',
          min: 0,
          // max: 25,
          interval: 5,
          splitLine: {
            show: false
          },
          axisLabel: {
            formatter: '{value} d'
          }
        }],
        series: [{
          name: '月供冷时长',
          type: 'bar',
          tooltip: {
            valueFormatter: function valueFormatter(value) {
              return value + ' 小时';
            }
          },
          data: this.glscObj.yglsc
        }, {
          name: '日均供冷时长',
          type: 'bar',
          tooltip: {
            valueFormatter: function valueFormatter(value) {
              return value + ' 小时';
            }
          },
          data: this.glscObj.rjglsc
        }, {
          name: '月供冷天数',
          type: 'line',
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function valueFormatter(value) {
              return value + ' 天';
            }
          },
          data: this.glscObj.yglts
        }]
      };
      this.magnifyChart.thirdly = option;
      lineCharts.setOption(option);
      this.$nextTick(function () {
        _this8.zoom = document.body.style.zoom;
        _this8.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    },
    // // 供冷时长 图
    // getThirdChart() {
    //   const lineCharts = this.$echarts.init(document.getElementById("third"));
    //   lineCharts.clear();
    //   const option = {
    //     tooltip: {
    //       trigger: "axis",
    //       borderWidth: 0,
    //       textStyle: {
    //         fontSize: 14,
    //         lineHeight: 30,
    //       },
    //       padding: 15,
    //       formatter: "{a0}:{c0}h",
    //     },
    //     dataZoom: {
    //       // 放大和缩放
    //       type: "inside",
    //     },
    //     legend: {
    //       type: "plain",
    //       orient: "horizontal",
    //       icon: "circle",
    //       show: true,
    //       textStyle: {
    //         fontSize: 14,
    //         lineHeight: 30,
    //       },
    //     },
    //     xAxis: {
    //       axisPointer: {
    //         type: "shadow",
    //       },
    //       type: "category",
    //       axisLabel: {
    //         //x轴文字的配置
    //         show: true,
    //         interval: 0, //使x轴文字显示全
    //         rotate: 90,
    //       },
    //       data: this.glscObj.dataTime,
    //     },
    //     yAxis: {
    //       // name: "单位h",
    //       type: "value",
    //       axisLabel: {
    //         formatter: "{value} h",
    //       },
    //     },
    //     color: "#34aa44",
    //     series: [
    //       {
    //         name: "日供冷时长",
    //         data: this.glscObj.rjglsc,
    //         type: "line",
    //       },
    //     ],
    //   };
    //   this.magnifyChart.thirdly = option;
    //   lineCharts.setOption(option);
    // },
    // 能效概览 图
    getFourthChart: function getFourthChart() {
      var lineCharts = this.$echarts.init(document.getElementById("fourth"));
      lineCharts.clear();
      var option = {
        tooltip: {
          trigger: "axis",
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          padding: 15
        },
        grid: {
          bottom: 70,
          left: 50
        },
        dataZoom: {
          // 放大和缩放
          type: "inside"
        },
        textStyle: {
          fontSize: 14
        },
        legend: {
          data: ["冷价", "能效"],
          // top: 10,
          icon: "circle",
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          }
        },
        xAxis: {
          axisPointer: {
            type: "shadow"
          },
          position: {
            bottom: 0
          },
          type: "category",
          axisTick: {
            show: false
          },
          boundaryGap: true,
          data: this.nxglObj.dataTime
        },
        yAxis: [{
          min: 0,
          type: "value",
          position: "left",
          name: "冷价",
          splitLine: {
            show: false
          }
        }, {
          type: "value",
          position: "right",
          name: "能效",
          min: 0,
          splitLine: {
            show: false
          }
          // axisLabel: {
          //   formatter: "{value}%",
          // },
        }],
        series: [{
          name: "冷价",
          type: "bar",
          barWidth: "10",
          itemStyle: {
            color: "#34aa44",
            /// 折线点的颜色
            lineStyle: {
              color: "#34aa44" ///　折线的颜色
            }
          },
          data: this.nxglObj.lj
        }, {
          name: "能效",
          type: "line",
          yAxisIndex: 1,
          symbol: "circle",
          symbolSize: 10,
          //折线点的大小
          itemStyle: {
            color: "#1665d8",
            /// 折线点的颜色
            lineStyle: {
              color: "#1665d8" ///　折线的颜色
            }
          },
          data: this.nxglObj.nx
        }]
      };
      this.magnifyChart.fourth = option;
      lineCharts.setOption(option);
    }
  }
};