"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  props: {
    energyData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      projectInfo: {},
      typeClass: ["iconP", "iconG", "iconF"],
      typeDesc: ["平", "谷", "峰"],
      type: null,
      number: "",
      color: "",
      status: 0
    };
  },
  watch: {
    energyData: function energyData(val) {
      var _this = this;
      val.map(function (e, index) {
        for (var key in val[0]) {
          _this.number = val[0][key][0];
          if (_this.projectInfo.projectType) {
            _this.type = val[0][key][1] - 1;
          }
        }
      });
      this.status = val[1]["status"];
    }
  },
  created: function created() {
    if (JSON.parse(sessionStorage.getItem("projectInfo"))) {
      this.projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));
    }
  }
};