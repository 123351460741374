"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "home"
  }, [_c("Scene", {
    attrs: {
      eventList: _vm.eventList
    }
  }), _vm._v(" "), _c("BigScreen", {
    attrs: {
      dataInfo: _vm.dataInfo,
      eventList: _vm.eventList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;