"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "theme-box"
  }, [_c("div", {
    staticClass: "content-box"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      width: "300px !important"
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "top-start",
      title: "",
      width: "300",
      trigger: "click",
      content: ""
    }
  }, [_c("div", [_vm._v("\n          系统此刻瞬时运行能效值，当前总供冷负荷/制冷机房设备耗电功率之和，能效值越高，机房运行状况越好。\n        ")]), _vm._v(" "), _c("div", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("\n          系统实时能效\n          "), _c("i", {
    staticClass: "el-icon-info",
    staticStyle: {
      color: "#1665D8",
      "margin-left": "5px"
    }
  })])])], 1), _vm._v(" "), _vm.status == -1 ? _c("div", {
    staticClass: "offline",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("img", {
    staticClass: "img",
    attrs: {
      src: require("../../../assets/offline.png"),
      alt: "",
      height: "100",
      width: "100"
    }
  })]) : _c("div", {
    staticClass: "number"
  }, [_vm._v("\n      " + _vm._s(_vm.number) + "\n    ")]), _vm._v(" "), _c("div", {
    staticClass: "cont"
  }, [_vm._v("\n      " + _vm._s(_vm.projectInfo.energyEfficiency) + "\n    ")])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;