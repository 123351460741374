"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Scene = _interopRequireDefault(require("@/components/Scene/Scene.vue"));
var _BigScene = _interopRequireDefault(require("@/components/Scene/BigScene.vue"));
// @ is an alias to /src
var _default = exports.default = {
  data: function data() {
    return {
      dataInfo: {},
      eventList: {}
    };
  },
  components: {
    Scene: _Scene.default,
    BigScreen: _BigScene.default
  }
};