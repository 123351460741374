"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "contain"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "formbody"
  }, [_c("el-form", {
    ref: "dialogForm",
    staticClass: "filter-inline",
    attrs: {
      inline: true,
      model: _vm.orderModel,
      "label-position": "top",
      rules: _vm.dialogRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "选择项目",
      prop: "projectid"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择项目"
    },
    on: {
      change: _vm.selectProjectHaveChange
    },
    model: {
      value: _vm.orderModel.projectid,
      callback: function callback($$v) {
        _vm.$set(_vm.orderModel, "projectid", $$v);
      },
      expression: "orderModel.projectid"
    }
  }, _vm._l(_vm.projectList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        value: item.id,
        label: item.entryName
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      width: "45%"
    },
    attrs: {
      label: "工单类型",
      prop: "orderType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择类型"
    },
    model: {
      value: _vm.orderModel.orderType,
      callback: function callback($$v) {
        _vm.$set(_vm.orderModel, "orderType", $$v);
      },
      expression: "orderModel.orderType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "设备故障",
      value: "1"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "其他",
      value: "2"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "选择处理人",
      prop: "dealUser",
      "label-width": "500'px'"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择处理人"
    },
    model: {
      value: _vm.orderModel.dealUser,
      callback: function callback($$v) {
        _vm.$set(_vm.orderModel, "dealUser", $$v);
      },
      expression: "orderModel.dealUser"
    }
  }, _vm._l(_vm.projectUserList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        value: item.id,
        label: item.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "选择抄送人",
      prop: "sendUsers",
      "label-width": "500'px'"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择抄送人",
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.orderModel.sendUsers,
      callback: function callback($$v) {
        _vm.$set(_vm.orderModel, "sendUsers", $$v);
      },
      expression: "orderModel.sendUsers"
    }
  }, _vm._l(_vm.projectUserList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        value: item.id,
        label: item.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "事件描述"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%",
      "font-size": "18px"
    },
    attrs: {
      type: "textarea",
      maxlength: "200",
      "show-word-limit": "",
      clearable: "",
      rows: "5",
      placeholder: "请输入描述",
      resize: "none"
    },
    model: {
      value: _vm.orderModel.desc,
      callback: function callback($$v) {
        _vm.$set(_vm.orderModel, "desc", $$v);
      },
      expression: "orderModel.desc"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "现场照片"
    }
  }, [_c("el-upload", {
    ref: "elUpload",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      action: "",
      "list-type": "picture-card",
      multiple: "",
      accept: ".jpg,.jpeg,.png,.JPG,.JPEG",
      "auto-upload": false,
      "before-upload": _vm.beforeAvatarUpload,
      limit: 10,
      "on-exceed": _vm.handleExceed,
      "http-request": _vm.imageUpload,
      "on-preview": _vm.handlePictureCardPreview
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrl,
      alt: ""
    }
  })])], 1)], 1), _vm._v(" "), _c("el-button", {
    staticClass: "newOrder",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.createOrderAction
    }
  }, [_vm._v("\n      提交工单\n    ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header-box"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("工单管理")])]);
}];
render._withStripped = true;