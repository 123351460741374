"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "orderDetails"
  }, [_c("div", {
    staticClass: "container"
  }, [_vm.showMain ? _c("div", {
    staticClass: "content"
  }, [_c("el-steps", {
    staticClass: "steps",
    attrs: {
      "finish-status": "success",
      direction: "vertical"
    }
  }, [_c("el-step", [_c("template", {
    slot: "icon"
  }, [_c("div")]), _vm._v(" "), _c("template", {
    slot: "description"
  }, [_c("div", {
    staticClass: "steps-title"
  }, [_c("div", {
    staticClass: "steps-modify"
  }, [_c("div", [_vm._v("工单描述")])]), _vm._v(" "), _c("div", [_vm._v("\n                " + _vm._s(_vm.dayjs(_vm.orderInfo.createTime).format("YYYY-MM-DD HH:mm")) + "\n              ")])]), _vm._v(" "), _c("div", {
    staticClass: "steps-description"
  }, [_c("div", [_vm._v("项目名称：" + _vm._s(_vm.orderInfo.entryName))]), _vm._v(" "), _c("div", [_vm._v("工单编号：" + _vm._s(_vm.orderInfo.workOrderNumber))]), _vm._v(" "), _c("div", [_vm._v("\n                工单类型：" + _vm._s(_vm.handleDict(_vm.orderInfo.workOrderType, "workOrderType")) + "\n              ")]), _vm._v(" "), _c("div", [_vm._v("提交人员：" + _vm._s(_vm.orderInfo.submitterName))]), _vm._v(" "), _c("div", [_vm._v("事件描述：" + _vm._s(_vm.orderInfo.eventDescription))]), _vm._v(" "), _c("div", [_vm._v("处理人员：" + _vm._s(_vm.orderInfo.reviewerName))]), _vm._v(" "), _c("div", [_vm._v("\n                抄送人员：\n                "), _vm._l(_vm.orderInfo.projectWorkOrderSendUsersSelectDTOList, function (item, key) {
    return _c("span", {
      key: key
    }, [_vm._v("\n                  " + _vm._s(item.sendUserName) + "\n                  " + _vm._s(key < _vm.orderInfo.projectWorkOrderSendUsersSelectDTOList.length - 1 ? " , " : "") + "\n                ")]);
  })], 2), _vm._v(" "), _vm.orderInfo.projectWorkOrderImageSelectDTOList && _vm.orderInfo.projectWorkOrderImageSelectDTOList.length > 0 ? [_c("div", [_vm._v("现场图片：")]), _vm._v(" "), _c("div", _vm._l(_vm.orderInfo.projectWorkOrderImageSelectDTOList, function (item, key) {
    return _c("el-image", {
      key: key,
      staticStyle: {
        width: "140px"
      },
      attrs: {
        fit: "contain",
        "preview-src-list": _vm.handleImageList(_vm.orderInfo.projectWorkOrderImageSelectDTOList, "workOrderImagePath"),
        src: item.workOrderImagePath
      }
    });
  }), 1)] : _vm._e()], 2)])], 2), _vm._v(" "), _vm._l(_vm.detailInfo, function (detail, index) {
    return _c("el-step", {
      key: index
    }, [_c("template", {
      slot: "icon"
    }, [_c("div")]), _vm._v(" "), _c("template", {
      slot: "description"
    }, [[_c("div", {
      staticClass: "steps-title"
    }, [_c("div", {
      staticClass: "steps-modify"
    }, [_c("div", [_vm._v("处理结果")]), _vm._v(" "), _vm.userId == detail.handlerId && _vm.orderInfo.workOrderStatus != 2 && detail.handlerType == 3 ? _c("div", {
      staticClass: "edit",
      on: {
        click: function click($event) {
          return _vm.handleEdit(detail);
        }
      }
    }) : _vm._e()]), _vm._v(" "), _c("div", [_vm._v("\n                    " + _vm._s(_vm.dayjs(_vm.lastOrderData.createTime).format("YYYY-MM-DD HH:mm")) + "\n                  ")])]), _vm._v(" "), index == _vm.detailInfo.length - 1 && _vm.orderInfo.workOrderStatus != 2 ? _c("div", {
      staticClass: "steps-description"
    }, [_c("div", [_vm._v(_vm._s(detail.handlerName))]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.dict.handlerType[detail.handlerType]))])]) : _c("div", {
      staticClass: "steps-description"
    }, [_c("div", [_vm._v(_vm._s(detail.handlerName))]), _vm._v(" "), _c("div", [_vm._v("处理：" + _vm._s(detail.handlerEventDescription))]), _vm._v(" "), detail.projectWordOrderHandlerImageSelectDTOList && detail.projectWordOrderHandlerImageSelectDTOList.length > 0 ? [_c("div", _vm._l(detail.projectWordOrderHandlerImageSelectDTOList, function (item, key) {
      return _c("el-image", {
        key: key,
        staticStyle: {
          width: "140px"
        },
        attrs: {
          fit: "contain",
          "preview-src-list": _vm.handleImageList(detail.projectWordOrderHandlerImageSelectDTOList, "workOrderImageHandlerPath"),
          src: item.workOrderImageHandlerPath
        }
      });
    }), 1)] : _vm._e(), _vm._v(" "), _vm.orderInfo.workOrderStatus != 2 || index != _vm.detailInfo.length - 1 ? _c("div", [_vm._v("\n                    已分配处理人：" + _vm._s(detail.sonHandlerName) + "\n                  ")]) : _vm._e(), _vm._v(" "), _c("div", [_vm._v("\n                    抄送人：\n                    "), _vm._l(detail.projectWorkOrderSendUsersSelectDTOList, function (item, key) {
      return _c("span", {
        key: key
      }, [_vm._v("\n                      " + _vm._s(item.sendUserName) + "\n                      " + _vm._s(key < detail.projectWorkOrderSendUsersSelectDTOList.length - 1 ? " , " : "") + "\n                    ")]);
    })], 2), _vm._v(" "), _vm.orderInfo.workOrderStatus == 2 && index == _vm.detailInfo.length - 1 ? _c("div", [_vm._v("\n                    处理完成，工单结束\n                  ")]) : _vm._e()], 2)]], 2)], 2);
  })], 2)], 1) : _vm._e()])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;