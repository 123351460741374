"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var THREE = _interopRequireWildcard(require("three"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// 初始化场景
var scene = new THREE.Scene();

// 场景天空盒
var textureCubeLoader = new THREE.CubeTextureLoader().setPath("./textures/");
var textureCube = textureCubeLoader.load(["posx.jpg",
// 正面X轴
"negx.jpg",
// 负面X轴
"posy.jpg",
// 正面Y轴
"negy.jpg",
// 负面Y轴
"posz.jpg",
// 正面Z轴
"negz.jpg" // 负面Z轴
]);
scene.background = textureCube;
scene.environment = textureCube;

// 添加平行光
var light = new THREE.DirectionalLight(0xffffff, 3);
light.position.set(1000, 1000, 1000);
scene.add(light);
// 添加环境光（可选，柔化阴影）
var ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
scene.add(ambientLight);
var _default = exports.default = scene;