"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _eventHub = _interopRequireDefault(require("@/utils/eventHub"));
var _default = exports.default = {
  props: {
    dataInfo: {},
    eventList: {}
  },
  data: function data() {
    return {
      ws: null,
      wsIp: process.env.VUE_APP_BASE_API,
      currentTime: (0, _dayjs.default)().format('YYYY-MM-DD HH:mm:ss'),
      rsbyxzt: "OFF",
      config: {
        header: ['时间', '主机', '模式', '温设', '功率'],
        data: [],
        align: ['center'],
        rowNum: 5,
        headerBGC: '#1981f6',
        headerHeight: 45,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      },
      sfconfig: {
        data: [{
          name: '风柜1电机频率',
          value: 0
        }, {
          name: '风柜1送风温度',
          value: 0
        }, {
          name: '风柜1回风温度',
          value: 0
        }, {
          name: '风柜2电机频率',
          value: 0
        }, {
          name: '风柜2送风温度',
          value: 0
        }, {
          name: '风柜2回风温度',
          value: 0
        }],
        showValue: true
      },
      configWd: {
        data: [0],
        shape: 'round',
        formatter: '{value}℃'
      },
      configRsxWd: {
        data: [0],
        formatter: '{value}℃',
        colors: ['#FF9999', '#FF6666']
      }
    };
  },
  methods: {
    stop: function stop() {
      console.log("stop");
      _eventHub.default.emit('stop-scene');
    },
    start: function start() {
      console.log("start");
      _eventHub.default.emit('start-scene');
    },
    initWebSocket: function initWebSocket() {
      var _this = this;
      console.log("bigobject");
      if (this.ws) {
        this.ws.close();
      }
      if ("WebSocket" in window) {
        var i = this.wsIp.indexOf("//");
        var url = this.wsIp.slice(i);
        this.ws = new WebSocket("".concat(process.env.VUE_APP_WSS_STATUS == "true" ? "wss" : "ws", ":").concat(url, "/websocketServer/bigScene/").concat(this.$store.state.project.projectInfo.id));
        this.ws.onmessage = function (message) {
          var data = JSON.parse(message.data);
          _this.config = {
            header: ['时间', '主机', '模式', '温设', '功率'],
            data: data.zjdata,
            align: ['center'],
            rowNum: 5,
            headerBGC: '#1981f6',
            headerHeight: 45,
            oddRowBGC: 'rgba(0, 44, 81, 0.8)',
            evenRowBGC: 'rgba(10, 29, 50, 0.8)'
          };
          _this.configWd = {
            data: [data.snpjwd],
            shape: 'round',
            formatter: '{value}℃'
          };
          _this.configRsxWd = {
            data: [data.sxwd],
            formatter: '{value}℃',
            colors: ['#FF9999', '#FF6666']
          };
          _this.sfconfig = {
            data: [{
              name: '风柜1电机频率',
              value: data.fg1sfpl
            }, {
              name: '风柜1送风温度',
              value: data.fg1sfwd
            }, {
              name: '风柜1回风温度',
              value: data.fg1xfwd
            }, {
              name: '风柜2电机频率',
              value: data.fg2sfpl
            }, {
              name: '风柜2送风温度',
              value: data.fg2sfwd
            }, {
              name: '风柜2回风温度',
              value: data.fg2xfwd
            }],
            showValue: true
          };
          _this.rsbyxzt = _this.covertState(data.rsbyxzt);
        };
        this.ws.onclose = function () {
          console.log('WebSocket连接已关闭');
        };
        this.ws.onerror = function (error) {
          console.error('WebSocket发生错误', error);
        };
      }
    },
    covertState: function covertState(state) {
      switch (state) {
        case 0:
          return "OFF";
        case 1:
          return "ON";
        default:
          return "";
      }
    },
    updateTime: function updateTime() {
      this.currentTime = (0, _dayjs.default)().format('YYYY-MM-DD HH:mm:ss');
    },
    goToMananger: function goToMananger() {
      this.removeCssrender();
      // 返回上一层
      window.history.go(-1);
    },
    removeCssrender: function removeCssrender() {
      var elementToRemove = document.querySelector('.cssrender');
      console.log("xiezai{}", elementToRemove);
      // 检查元素是否存在
      if (elementToRemove) {
        // 清空元素的内容
        elementToRemove.innerHTML = '';
      }
    }
  },
  created: function created() {
    setInterval(this.updateTime, 1000);
    this.initWebSocket();
  },
  unmounted: function unmounted() {
    this.removeCssrender();
  }
};