"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border"
  }, [_vm.borderSize ? _c("img", {
    attrs: {
      src: require("@/assets/big_border1.png"),
      alt: "Description"
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/big_border2.png"),
      alt: "Description"
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;